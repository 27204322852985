import { IPaymentOptionResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentOptionResponse';
import { IPaymentOption } from 'app/module/payment/domain/entity/PaymentOption';
import { Amount } from 'app/common/domain/valueObject/Amount';
import { Discount } from 'app/common/domain/valueObject/Discount';

export class PaymentOptionDataMapper {

    public static fromResponse(response: IPaymentOptionResponse): IPaymentOption {
        const centsToEuros = 100;

        return {
            id: response.id,
            icon: response.icon,
            title: response.title,
            subtitle: response.subtitle,
            advantages: response.advantages.map((item) => item),
            compatibleIds: response.compatibleIds.map((item) => item),
            values: response.values.map((item) => {
                return {
                    id: item.id,
                    title: item.title,
                    price: new Amount(item.price / centsToEuros),
                    discount: item.discount ? new Discount(item.discount) : undefined,
                };
            }),
        };
    }

    public static fromArrayResponse(response: IPaymentOptionResponse[]): IPaymentOption[] {
        const paymentOptions: IPaymentOption[] = [];

        response.forEach((paymentOptionResponse) => {
            paymentOptions.push(this.fromResponse(paymentOptionResponse));
        });

        return paymentOptions;
    }
}
