import { useForm,UseFormReturn } from 'react-hook-form';
import { useContext,useEffect } from 'react';
import {
    ECookiesPreferencesFormDataName,
    ICookiesPreferencesFormData
} from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/CookiesPreferencesFormData';
import { useCookiesConsentConsentReduxState } from 'app/common/presentation/redux/cookiesConsent/hook/useCookiesConsentConsentReduxState';
import setCookiesConsentUseCase from 'app/common/domain/useCase/SetCookiesConsentUseCase';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { useDispatch } from 'react-redux';
import { ShowCookiesPreferencesActions } from 'app/common/presentation/redux/cookiesPreferences/CookiesPreferencesSlice';

interface ICookiesPreferencesControllerResponse {
    saveCookies: () => void
    denyCookies: () => void
    acceptCookies: () => void
    UseCookiesPreferencesModalForm: UseFormReturn<ICookiesPreferencesFormData, any>
}

export const useCookiesPreferencesModalController = (): ICookiesPreferencesControllerResponse => {
    const dispatch = useDispatch();
    const dic = useContext(DependencyInjectionContext);
    const cookies = useCookiesConsentConsentReduxState();

    const UseCookiesPreferencesModalForm = useForm<ICookiesPreferencesFormData>({
        defaultValues: {
            technical: cookies.preferences.technical,
            analytics: cookies.preferences.analytics
        }
    });

    const closePreferencesModal = (): void => {
        dispatch(ShowCookiesPreferencesActions.handleHideCookiesPreferencesModal());
    };

    const saveCookies = async (): Promise<void> => {
        await UseCookiesPreferencesModalForm.handleSubmit(async (data) => {
            await setCookiesConsentUseCase({
                cookiesPreferencesFormData: data,
                storageRepository: dic.storageRepository
            }).then(() => {
                closePreferencesModal();
            });
        })();
    };

    const acceptCookies = async (): Promise<void> => {
        await setCookiesConsentUseCase({
            cookiesPreferencesFormData: {
                technical: true,
                analytics: true,
            },
            storageRepository: dic.storageRepository
        }).then(()=> {
            closePreferencesModal();
        });
    };

    const denyCookies = async (): Promise<void> => {
        await setCookiesConsentUseCase({
            cookiesPreferencesFormData: {
                technical: true,
                analytics: false
            },
            storageRepository: dic.storageRepository
        }).then(()=> {
            closePreferencesModal();
        });
    };

    useEffect(() => {
        UseCookiesPreferencesModalForm.setValue(ECookiesPreferencesFormDataName.TECHNICAL, cookies.preferences.technical);
        UseCookiesPreferencesModalForm.setValue(ECookiesPreferencesFormDataName.ANALYTICS, cookies.preferences.analytics);
    },[cookies]);

    return {
        saveCookies,
        denyCookies,
        acceptCookies,
        UseCookiesPreferencesModalForm,
    };
};
