import { createSlice } from '@reduxjs/toolkit';
import { EGender,IGender } from 'app/module/profile/domain/valueObject/Gender';

const initialState = {
    genders: [
        { value: EGender.WOMAN },
        { value: EGender.MAN },
        { value: EGender.COUPLE },
        { value: EGender.TRANSGENDER },
    ] as IGender[]
};

const slice = createSlice({
    name: 'genders',
    initialState,
    reducers: {},
});

export const GendersReducer = slice.reducer;
export const GendersActions = slice.actions;
