import i18next from 'i18next';
import { COUPLE_LABEL,MAN_LABEL,TRANSGENDER_LABEL,WOMAN_LABEL } from 'translations/tr';

export interface IGender {
    value: EGender
}

export enum EGender {
    WOMAN= 'WOMAN',
    MAN = 'MAN',
    COUPLE = 'COUPLE',
    TRANSGENDER = 'TRANSGENDER'
}

export const translateGender = (value: EGender): string => {
    switch( value ){
        case EGender.WOMAN:
            return i18next.t(WOMAN_LABEL);
        case EGender.MAN:
            return i18next.t(MAN_LABEL);
        case EGender.COUPLE:
            return i18next.t(COUPLE_LABEL);
        case EGender.TRANSGENDER:
            return i18next.t(TRANSGENDER_LABEL);
        default:
            return i18next.t(TRANSGENDER_LABEL);
    }
};
