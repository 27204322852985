import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { EProfileType,IProfile } from 'app/module/profile/domain/entity/Profile';
import { EGender } from 'app/module/profile/domain/valueObject/Gender';
import { ESexualOrientation } from 'app/module/profile/domain/valueObject/SexualOrientation';

const initialState = {
    profile: {
        id: '',
        age: 0,
        phone: 0,
        name: '',
        image: '',
        slogan: '',
        price: null,
        services: [],
        height: null,
        userName: '',
        schedule: null,
        imageSmall: '',
        hairColor: null,
        description: '',
        availability: [],
        isEnabled: false,
        email: undefined,
        nationality: null,
        paymentMethods: [],
        hasTelegram: false,
        hasWhatsapp: false,
        externalLink: null,
        telegramUsername: '',
        birthDate: new Date(),
        canEmailContact: false,
        canPhoneContact: false,
        commercialConsent: false,
        category: [],
        sexualOrientation: {
            value: ESexualOrientation.HETEROSEXUAL
        },
        gender: {
            value: EGender.MAN
        },
        province: {
            name: '',
            id: ''
        },
        city: {
            name: '',
            id: ''
        },
        type: EProfileType.USER,
    } as IProfile
};

const slice = createSlice({
    name: 'currentProfile',
    initialState,
    reducers: {
        setCurrentProfile: (state, action: PayloadAction<IProfile>) => {
            state.profile = action.payload;
        },
        reset: (state) => {
            state.profile = initialState.profile;
        }
    },
});

export const CurrentProfileReducer = slice.reducer;
export const CurrentProfileActions = slice.actions;
