import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ILanguage } from 'app/common/domain/valueObject/Language';

interface IGetLanguageUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: IGetLanguageUseCaseProps): Promise<ILanguage> => {
    try {
        return await props.storageRepository.getLanguage();
    } catch (error) {
        return Promise.reject(error);
    }
};
