import { ISuggestion } from 'app/module/suggestion/domain/valueObject/Suggestion';
import ISuggestionRepository from 'app/module/suggestion/domain/interface/SuggestionRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';

export class ApiSuggestionRepository implements ISuggestionRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ){}

    public async add(
        auth: IAuth,
        suggestion: ISuggestion,
    ): Promise<void> {
        try {
            return await this._sexonApiClient.sendSuggestions(
                {
                    payload: {
                        description: suggestion.description,
                    },
                    token: auth.token.value,
                },
            );
        } catch (error){
            return Promise.reject(error);
        }
    };
}
