import { createSlice } from '@reduxjs/toolkit';
import { EService,IService,translateServiceDescription } from 'app/module/profile/domain/valueObject/Service';

const initialState = {
    services: [
        { value: EService.CONTENT_SALE, description: translateServiceDescription(EService.CONTENT_SALE) },
        { value: EService.SEXTING, description: translateServiceDescription(EService.SEXTING) },
        { value: EService.VIDEO_CALLS, description: translateServiceDescription(EService.VIDEO_CALLS) },
        { value: EService.ADULT_ACTOR_ACTRESS, description: translateServiceDescription(EService.ADULT_ACTOR_ACTRESS) },
        { value: EService.STRIPTEASE, description: translateServiceDescription(EService.STRIPTEASE) },
        { value: EService.DICK_RATE, description: translateServiceDescription(EService.DICK_RATE) },
        { value: EService.VOYEURISM, description: translateServiceDescription(EService.VOYEURISM) },
        { value: EService.VIRTUAL_PARTNER, description: translateServiceDescription(EService.VIRTUAL_PARTNER) },
        { value: EService.DOMINATION, description: translateServiceDescription(EService.DOMINATION) },
        { value: EService.FINANCIAL_DOMINATION, description: translateServiceDescription(EService.FINANCIAL_DOMINATION) },
        { value: EService.VIRTUAL_DOMINATRIX, description: translateServiceDescription(EService.VIRTUAL_DOMINATRIX) },
        { value: EService.FETISHES_GENERAL, description: translateServiceDescription(EService.FETISHES_GENERAL) },
        { value: EService.FOOT_FETISH, description: translateServiceDescription(EService.FOOT_FETISH) },
        { value: EService.CONTENT_EDITING_PRODUCTION,
            description: translateServiceDescription(EService.CONTENT_EDITING_PRODUCTION) },
        { value: EService.MARKETING_PROMOTION, description: translateServiceDescription(EService.MARKETING_PROMOTION) },
        { value: EService.SEXUAL_HEALTH_WELLNESS, description: translateServiceDescription(EService.SEXUAL_HEALTH_WELLNESS) },
        { value: EService.EROTIC_MODEL, description: translateServiceDescription(EService.EROTIC_MODEL) },
        { value: EService.SEXUAL_EVENTS_ORGANIZER, description: translateServiceDescription(EService.SEXUAL_EVENTS_ORGANIZER) },
        { value: EService.SEXUAL_WORKSHOP_ORGANIZER,
            description: translateServiceDescription(EService.SEXUAL_WORKSHOP_ORGANIZER) },
        { value: EService.COUPLES_THERAPY_SEXUALITY,
            description: translateServiceDescription(EService.COUPLES_THERAPY_SEXUALITY) },
        { value: EService.SEXUAL_EDUCATOR, description: translateServiceDescription(EService.SEXUAL_EDUCATOR) },
        { value: EService.EROTIC_PHOTOGRAPHER, description: translateServiceDescription(EService.EROTIC_PHOTOGRAPHER) },
    ] as IService[]

};

const slice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
});

export const ServicesReducer = slice.reducer;
export const ServicesActions = slice.actions;
