import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showHelpSectionModal',
    initialState,
    reducers: {
        handleShowHelpSectionModal: (state) => {
            state.showModal = true;
        },
        handleHideHelpSectionModal: (state) => {
            state.showModal = false;
        },
    },
});

export const ShowHelpSectionModalReducer = slice.reducer;
export const ShowHelpSectionModalActions = slice.actions;
