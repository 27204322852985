import IAnalyticsRepository from 'app/common/domain/interface/AnalyticsRepository';

interface ITrackPageViewUseCaseProps {
    page: string
    analyticsRepository: IAnalyticsRepository
}

export default async (props: ITrackPageViewUseCaseProps): Promise<void> => {
    try {
        return props.analyticsRepository.trackPageView(props.page);
    } catch (error) {
        return Promise.reject(error);
    }
};
