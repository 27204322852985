import { EApiSexualOrientation } from 'app/common/data/remote/sexonApi/dto/ApiSexualOrientation';
import { ESexualOrientation,ISexualOrientation } from 'app/module/profile/domain/valueObject/SexualOrientation';

export class SexualOrientationDataMapper {

    public static fromDomain(orientation: ESexualOrientation): EApiSexualOrientation {
        switch( orientation ){
            case ESexualOrientation.HETEROSEXUAL:
                return EApiSexualOrientation.HETEROSEXUAL;
            case ESexualOrientation.HOMOSEXUAL:
                return EApiSexualOrientation.HOMOSEXUAL;
            case ESexualOrientation.BISEXUAL:
            default:
                return EApiSexualOrientation.BISEXUAL;
        }
    }

    public static fromResponse(orientation: EApiSexualOrientation): ISexualOrientation {
        switch( orientation ){
            case EApiSexualOrientation.HETEROSEXUAL:
                return {
                    value: ESexualOrientation.HETEROSEXUAL
                };
            case EApiSexualOrientation.HOMOSEXUAL:
                return {
                    value: ESexualOrientation.HOMOSEXUAL
                };
            case EApiSexualOrientation.BISEXUAL:
            default:
                return {
                    value: ESexualOrientation.BISEXUAL
                };
        }
    }
}
