import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ICity from 'app/module/profile/domain/entity/City';

const initialState = {
    cities: [] as ICity[]
};

const slice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        setCities: (state, action: PayloadAction<ICity[]>) => {
            state.cities = action.payload;
        },
    },
});

export const CityReducer = slice.reducer;
export const CityActions = slice.actions;
