import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import INationality from 'app/module/profile/domain/entity/Nationality';
import INationalityRepository from 'app/module/profile/domain/interface/NationalityRepository';
import { GetNationalitiesDataMapper } from 'app/module/profile/data/repository/api/nationality/dataMapper/GetNationalitiesDataMapper';


export class ApiNationalityRepository implements INationalityRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async findAll(): Promise<INationality[]> {
        try {
            const nationalitiesData = await this._sexonApiClient.getNationalities();
            return GetNationalitiesDataMapper.fromResponse(nationalitiesData);
        } catch (error){
            return Promise.reject(error);
        }
    }
}
