import { createContext,useMemo } from 'react';
import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { CookiesStorageRepository } from 'app/common/data/repository/storage/CookiesStorageRepository';
import { AxiosSexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { ApiAuthRepository } from 'app/module/auth/data/repository/api/ApiAuthRepository';
import IAuthRepository from 'app/module/auth/domain/interface/AuthRepository';
import IPaymentOptionsRepository from 'app/module/payment/domain/interface/PaymentOptionsRepository';
import { ApiPaymentOptionsRepository } from 'app/module/payment/data/repository/api/ApiPaymentOptionsRepository';
import IPaymentSummaryRepository from 'app/module/payment/domain/interface/PaymentSummaryRepository';
import { ApiPaymentSummaryRepository } from 'app/module/payment/data/repository/api/ApiPaymentSummaryRepository';
import ISuggestionRepository from 'app/module/suggestion/domain/interface/SuggestionRepository';
import { ApiSuggestionRepository } from 'app/module/suggestion/data/repository/ApiSuggestionRepository';
import ICityRepository from 'app/module/profile/domain/interface/CityRepository';
import { ApiCityRepository } from 'app/module/profile/data/repository/api/city/ApiCityRepository';
import IFeedRepository from 'app/module/profile/domain/interface/FeedRepository';
import { ApiFeedRepository } from 'app/module/profile/data/repository/api/feed/ApiFeedRepository';
import INationalityRepository from 'app/module/profile/domain/interface/NationalityRepository';
import { ApiNationalityRepository } from 'app/module/profile/data/repository/api/nationality/ApiNationalityRepository';
import IProvinceRepository from 'app/module/profile/domain/interface/ProvinceRepository';
import { ApiProvinceRepository } from 'app/module/profile/data/repository/api/province/ApiProvinceRepository';
import IUploadRepository from 'app/module/profile/domain/interface/UploadRepository';
import { ApiUploadRepository } from 'app/module/profile/data/repository/api/upload/ApiUploadRepository';
import IPostRepository from 'app/module/profile/domain/interface/PostRepository';
import { ApiPostRepository } from 'app/module/profile/data/repository/api/post/ApiPostRepository';
import IProfileRepository from 'app/module/profile/domain/interface/ProfileRepository';
import { ApiProfileRepository } from 'app/module/profile/data/repository/api/profile/ApiProfileRepository';
import IAgeConsentRepository from 'app/common/domain/interface/AgeConsentRepository';
import { ApiAgeConsentRepository } from 'app/common/data/repository/storage/ApiAgeConsentRepository';
import IAnalyticsRepository from 'app/common/domain/interface/AnalyticsRepository';
import { GoogleAnalyticsRepository } from 'app/common/data/repository/storage/GoogleAnalyticsRepository';

export interface IDependencyInjection {
    feedRepository: IFeedRepository
    cityRepository: ICityRepository
    authRepository: IAuthRepository
    postRepository: IPostRepository
    uploadRepository: IUploadRepository
    storageRepository: IStorageRepository
    profileRepository: IProfileRepository
    provinceRepository: IProvinceRepository
    analyticsRepository: IAnalyticsRepository
    suggestionRepository: ISuggestionRepository
    ageConsentRepository: IAgeConsentRepository
    nationalityRepository: INationalityRepository
    paymentOptionsRepository: IPaymentOptionsRepository
    paymentSummaryRepository: IPaymentSummaryRepository
}

export const DependencyInjectionContext = createContext<IDependencyInjection>(
    {} as IDependencyInjection
);

type ProviderProps = {
    children: JSX.Element
};

export const DependencyInjectionProvider = ({ children }: ProviderProps): JSX.Element => {
    const stateValues = useMemo(() => {
        const storageRepository = new CookiesStorageRepository();
        const analyticsRepository = new GoogleAnalyticsRepository();

        const sexonApiClient = new AxiosSexonApiClient(storageRepository);

        const authRepository = new ApiAuthRepository(sexonApiClient);
        const cityRepository = new ApiCityRepository(sexonApiClient);
        const feedRepository = new ApiFeedRepository(sexonApiClient);
        const postRepository = new ApiPostRepository(sexonApiClient);
        const uploadRepository = new ApiUploadRepository(sexonApiClient);
        const profileRepository = new ApiProfileRepository(sexonApiClient);
        const provinceRepository = new ApiProvinceRepository(sexonApiClient);
        const suggestionRepository = new ApiSuggestionRepository(sexonApiClient);
        const ageConsentRepository = new ApiAgeConsentRepository(sexonApiClient);
        const nationalityRepository = new ApiNationalityRepository(sexonApiClient);
        const paymentOptionsRepository = new ApiPaymentOptionsRepository(sexonApiClient);
        const paymentSummaryRepository = new ApiPaymentSummaryRepository(sexonApiClient);

        return {
            authRepository,
            cityRepository,
            feedRepository,
            postRepository,
            uploadRepository,
            storageRepository,
            profileRepository,
            provinceRepository,
            analyticsRepository,
            suggestionRepository,
            ageConsentRepository,
            nationalityRepository,
            paymentOptionsRepository,
            paymentSummaryRepository,
        };
    }, []);

    return (
        <DependencyInjectionContext.Provider value={stateValues}>
            {children}
        </DependencyInjectionContext.Provider>
    );
};
