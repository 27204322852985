import IAnalyticsRepository from 'app/common/domain/interface/AnalyticsRepository';
import ReactGA from 'react-ga4';

export class GoogleAnalyticsRepository implements IAnalyticsRepository {
    public constructor() {}

    public trackPageView(page: string): void {
        ReactGA.send({ hitType: 'pageview', page });
    }

    public trackEvent(category: string, action: string, label?: string): void {
        ReactGA.event({
            category,
            action,
            label,
        });
    }
}
