import IProvinceResponse from 'app/common/data/remote/sexonApi/dto/response/ProvinceResponse';
import IProvince from 'app/module/profile/domain/entity/Province';

export class GetProvincesDataMapper {

    public static fromResponse(response: IProvinceResponse[]): IProvince[] {
        const provinces: IProvince[] = [];

        response.forEach((item) => {
            provinces.push({
                id: item.id,
                name: item.name,
            });
        });

        return provinces;
    }
}
