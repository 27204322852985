import { EApiGender } from 'app/common/data/remote/sexonApi/dto/ApiGender';
import { EGender,IGender } from 'app/module/profile/domain/valueObject/Gender';

export class GenderDataMapper {

    public static fromDomain(gender: EGender): EApiGender {
        switch( gender ){
            case EGender.WOMAN:
                return EApiGender.WOMAN;
            case EGender.MAN:
                return EApiGender.MAN;
            case EGender.COUPLE:
                return EApiGender.COUPLE;
            case EGender.TRANSGENDER:
            default:
                return EApiGender.TRANSGENDER;
        }
    }

    public static fromResponse(gender: EApiGender): IGender {
        switch( gender ){
            case EApiGender.WOMAN:
                return {
                    value: EGender.WOMAN
                };
            case EApiGender.MAN:
                return {
                    value: EGender.MAN
                };
            case EApiGender.COUPLE:
                return {
                    value: EGender.COUPLE
                };
            case EApiGender.TRANSGENDER:
            default:
                return {
                    value: EGender.TRANSGENDER
                };
        }
    }
}
