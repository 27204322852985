import { EApiDayOfWeek } from 'app/common/data/remote/sexonApi/dto/ApiDayOfWeek';
import { EApiHairColor } from 'app/common/data/remote/sexonApi/dto/ApiHairColor';
import { EApiPaymentMethod } from 'app/common/data/remote/sexonApi/dto/ApiPaymentMethod';
import { EApiService } from 'app/common/data/remote/sexonApi/dto/ApiService';
import { EApiSchedule } from 'app/common/data/remote/sexonApi/dto/ApiSchedule';
import { EApiHeight } from 'app/common/data/remote/sexonApi/dto/ApiHeight';
import ICityResponse from 'app/common/data/remote/sexonApi/dto/response/CityResponse';
import { EApiGender } from 'app/common/data/remote/sexonApi/dto/ApiGender';
import { EApiSexualOrientation } from 'app/common/data/remote/sexonApi/dto/ApiSexualOrientation';
import { EPaymentOptionTypeResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentOptionResponse';
import { EApiCategory } from 'app/common/data/remote/sexonApi/dto/ApiCategory';

export interface IProfileResponse {
    id: string
    age: number
    name: string
    phone: string
    image: string
    email?: string
    city: ICityResponse
    category: EApiCategory[]
    province: string
    enabled: boolean
    username: string
    birthDate: string
    imageSmall: string
    gender: EApiGender
    description: string
    hasTelegram: boolean
    hasWhatsapp: boolean
    type: EProfileTypeResponse
    emailContact: boolean
    phoneContact: boolean
    services: EApiService[]
    height: EApiHeight | null
    slogan: string | undefined
    daysOfWeek: EApiDayOfWeek[]
    minPrice: number | undefined
    nationality: string | undefined
    externalLink: string | undefined
    paymentMethods: EApiPaymentMethod[]
    subscriptionId: string | undefined
    timeOfDay: EApiSchedule | undefined
    subscriptionUntil: string| undefined
    telegramUsername: string | undefined
    hairColor: EApiHairColor | undefined
    commercialConsent: boolean | undefined
    subscriptionOptionId: string | undefined
    sexualOrientation: EApiSexualOrientation
    subscriptionType?: EPaymentOptionTypeResponse
}

export enum EProfileTypeResponse {
    USER = 'USER',
    DEMO = 'DEMO',
    OFFICIAL = 'OFFICIAL',
}
