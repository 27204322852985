import { useContext } from 'react';
import setCookiesConsentUseCase from 'app/common/domain/useCase/SetCookiesConsentUseCase';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { useDispatch } from 'react-redux';
import { ShowCookiesPreferencesActions } from 'app/common/presentation/redux/cookiesPreferences/CookiesPreferencesSlice';

interface ICookiesConsentControllerResponse {
    denyCookies: () => void
    acceptCookies: () => void
    openPreferencesModal: () => void
}

export const useCookiesConsentModalController = (): ICookiesConsentControllerResponse => {
    const dic = useContext(DependencyInjectionContext);
    const dispatch = useDispatch();

    const openPreferencesModal = (): void => {
        dispatch(ShowCookiesPreferencesActions.handleShowCookiesPreferencesModal());
    };

    const acceptCookies = (): void => {
        setCookiesConsentUseCase({
            cookiesPreferencesFormData: {
                technical: true,
                analytics: true,
            },
            storageRepository: dic.storageRepository
        });
    };

    const denyCookies = (): void => {
        setCookiesConsentUseCase({
            cookiesPreferencesFormData: {
                technical: true,
                analytics: false
            },
            storageRepository: dic.storageRepository
        });
    };

    return {
        denyCookies,
        acceptCookies,
        openPreferencesModal,
    };
};
