import { createSlice } from '@reduxjs/toolkit';
import { EReportReason,IReportReason } from 'app/module/profile/domain/valueObject/ReportReason';

const initialState = {
    reportReasons: [
        { value: EReportReason.SEXUAL_OFFENSES },
        { value: EReportReason.OTHER },
    ] as IReportReason[]
};

const slice = createSlice({
    name: 'reportReasons',
    initialState,
    reducers: {},
});

export const ReportReasonsReducer = slice.reducer;
export const ReportReasonsActions = slice.actions;
