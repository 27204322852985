import React,{ useContext } from 'react';
import {
    CREATE_PROFILE,
    DELETE_ACCOUNT,
    EDIT_ADDITIONAL_INFORMATION,
    EDIT_BASIC_INFORMATION,
    EDIT_EMAIL,
    EDIT_PHONE,
    HIDE_PROFILE,
    HIGHLIGHT_YOUR_PROFILE,
    LOGOUT,
    SETTING_MODAL_TITLE,
    SHOW_PROFILE,
    SHOW_PROFILE_SUCCESSFUL_MESSAGE
} from 'translations/tr';
import './SettingsModal.scss';
import { useTranslation } from 'react-i18next';
import {
    DeleteAccountIcon,
    EditBasicProfileIcon,
    EditEmailIcon,
    EditIcon,
    EditPhoneIcon,
    EnableProfileIcon,
    HideProfileIcon,
    HighlightProfileIcon,
    LogoutIcon,
    RegisterIcon
} from 'assets/icon/sexon/svg/icons';
import { useDispatch } from 'react-redux';
import { ShowSettingsModalActions } from 'app/common/presentation/redux/settingsModal/ShowSettingsModalSlice';
import { useNavigate } from 'react-router-dom';
import logOutUserCase from 'app/common/domain/useCase/LogOutUseCase';
import { EAppRoute } from 'app/common/presentation/routing/AppRoute';
import getCurrentProfileUserCase from 'app/module/profile/domain/useCase/profile/GetCurrentProfileUseCase';
import setToastSuccessfulUserCase from 'app/common/domain/useCase/SetToastSuccessfulUseCase';
import setFullScreenSpinnerLoadingUserCase from 'app/common/domain/useCase/SetFullScreenSpinnerLoadingUseCase';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { translateApiError } from 'app/common/domain/entity/Error';
import showProfileUserCase from 'app/module/profile/domain/useCase/profile/ShowProfileUseCase';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { IsAuthed } from '../../../HOC/isAuthed/IsAuthed';
import { SexonButton } from '../../button/SexonButton';
import { useAuthReduxState } from '../../../redux/auth/hook/useAuthReduxState';
import { useCurrentProfile } from '../../../hook/useCurrentProfile';
import { IsAuthedWithProfile } from '../../../HOC/isAuthedWithProfile/IsAuthedWithProfile';
import { IsAuthedWithoutProfile } from '../../../HOC/isAuthedWithoutProfile/IsAuthedWithoutProfile';
import { useShowSettingsModalReduxState } from '../../../redux/settingsModal/hook/useShowSettingsModalReduxState';
import { SexonModal } from '../../modal/SexonModal';

export const SettingsModal = (): JSX.Element => {
    const auth = useAuthReduxState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentProfile } = useCurrentProfile();
    const { t } = useTranslation();
    const dic = useContext(DependencyInjectionContext);
    const showModal = useShowSettingsModalReduxState();
    const UseCurrentProfile = useCurrentProfile();

    const goToProfile = (): void => {
        navigate(`/${currentProfile.userName}/`);
    };

    const showProfile = (): void=> {
        setFullScreenSpinnerLoadingUserCase(true);
        showProfileUserCase({
            auth,
            profile: currentProfile,
            profileRepository: dic.profileRepository
        }).then(()=> {
            getCurrentProfileUserCase({
                auth,
                profileRepository: dic.profileRepository
            }).then(
                (): void => {
                    goToProfile();
                    setToastSuccessfulUserCase(t(SHOW_PROFILE_SUCCESSFUL_MESSAGE));
                }).catch((): void => {
                setFullScreenSpinnerLoadingUserCase(false);
            }).finally(()=> {
                setFullScreenSpinnerLoadingUserCase(false);
            });
        }).catch((error) => {
            setToastErrorUserCase(translateApiError(error));
            setFullScreenSpinnerLoadingUserCase(false);
        });
    };

    return (
        <SexonModal
            show={ showModal }
            classname="component-settings-modal"
            onClose={ (): void => {
                dispatch(ShowSettingsModalActions.handleHideSettingModal());
            } }
            headerTitle={ t(SETTING_MODAL_TITLE) }
            body={ (
                <div className="settings-modal-body-container">
                    <IsAuthedWithProfile>
                        <SexonButton
                            title={ (
                                <>
                                    <EditBasicProfileIcon />
                                    <span>
                                        {t(EDIT_BASIC_INFORMATION).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.BORDER}
                            onClick={(): void => {
                                navigate(EAppRoute.EDIT_BASIC_PROFILE);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthedWithProfile>
                    <IsAuthedWithProfile>
                        <SexonButton
                            title={ (
                                <>
                                    <EditIcon />
                                    <span>
                                        {t(EDIT_ADDITIONAL_INFORMATION).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.BORDER}
                            onClick={(): void => {
                                navigate(EAppRoute.EDIT_ADDITIONAL_PROFILE);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthedWithProfile>
                    <IsAuthedWithProfile>
                        {
                            UseCurrentProfile.currentProfile.isEnabled ? (
                                <SexonButton
                                    title={ (
                                        <>
                                            <HideProfileIcon />
                                            <span>
                                                {t(HIDE_PROFILE).toUpperCase()}
                                            </span>
                                        </>
                                    ) }
                                    type="button"
                                    styling={ESexonButtonStyle.BORDER}
                                    onClick={(): void => {
                                        navigate(EAppRoute.HIDE_PROFILE);
                                        dispatch(ShowSettingsModalActions.reset());
                                    }}
                                />
                            ) : (
                                <SexonButton
                                    title={ (
                                        <>
                                            <EnableProfileIcon />
                                            <span>
                                                {t(SHOW_PROFILE).toUpperCase()}
                                            </span>
                                        </>
                                    ) }
                                    type="button"
                                    styling={ESexonButtonStyle.BORDER}
                                    onClick={(): void => {
                                        showProfile();
                                        dispatch(ShowSettingsModalActions.reset());
                                    }}
                                />
                            )
                        }
                    </IsAuthedWithProfile>
                    <IsAuthedWithoutProfile>
                        <SexonButton
                            title={ (
                                <>
                                    <RegisterIcon />
                                    <span>
                                        {t(CREATE_PROFILE).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.BORDER}
                            onClick={(): void => {
                                navigate(EAppRoute.CREATE_PROFILE);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthedWithoutProfile>
                    <IsAuthed>
                        <SexonButton
                            title={ (
                                <>
                                    <EditEmailIcon />
                                    <span>
                                        {t(EDIT_EMAIL).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.BORDER}
                            onClick={(): void => {
                                navigate(EAppRoute.EDIT_EMAIL);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthed>
                    <IsAuthed>
                        <SexonButton
                            title={ (
                                <>
                                    <EditPhoneIcon />
                                    <span>
                                        {t(EDIT_PHONE).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.BORDER}
                            onClick={(): void => {
                                navigate(EAppRoute.EDIT_PHONE);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthed>
                    <IsAuthedWithProfile>
                        <SexonButton
                            title={ (
                                <>
                                    <HighlightProfileIcon />
                                    <span>
                                        {t(HIGHLIGHT_YOUR_PROFILE).toUpperCase()}
                                    </span>
                                </>
                            ) }
                            type="button"
                            styling={ESexonButtonStyle.FILLED_SECONDARY}
                            onClick={(): void => {
                                navigate(EAppRoute.HIGHLIGHT_PROFILE);
                                dispatch(ShowSettingsModalActions.reset());
                            }}
                        />
                    </IsAuthedWithProfile>
                    <SexonButton
                        title={ (
                            <>
                                <LogoutIcon />
                                <span>
                                    {t(LOGOUT).toUpperCase()}
                                </span>
                            </>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.FILLED}
                        onClick={(): void => {
                            logOutUserCase({
                                storageRepository: dic.storageRepository
                            }).then(
                                ()=> {
                                    navigate(EAppRoute.SIGN_IN);
                                }
                            );
                        }}
                    />
                </div>
            ) }

            footer={(
                <div className="settings-modal-footer-container">
                    <SexonButton
                        title={ (
                            <>
                                <DeleteAccountIcon />
                                <span>
                                    {t(DELETE_ACCOUNT).toUpperCase()}
                                </span>
                            </>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        onClick={(): void => {
                            navigate(EAppRoute.DELETE_ACCOUNT);
                            dispatch(ShowSettingsModalActions.reset());
                        }}
                    />
                </div>
            )}
        />
    );
};
