import { EProfileTypeResponse,IProfileResponse } from 'app/common/data/remote/sexonApi/dto/response/ProfileResponse';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import INationality from 'app/module/profile/domain/entity/Nationality';
import IProvince from 'app/module/profile/domain/entity/Province';
import { Email } from 'app/common/domain/valueObject/Email';
import { EProfileType,IProfile } from 'app/module/profile/domain/entity/Profile';
import { DaysOfWeekDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/DayOfWeekDataMapper';
import { GenderDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/GenderDataMapper';
import { HairColorDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HairColorDataMapper';
import { HeightDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HeightDataMapper';
import { PaymentMethodDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/PaymentMethodDataMapper';
import { ScheduleDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ScheduleDataMapper';
import { ServiceDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ServiceDataMapper';
import { SexualOrientationDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/SexualOrientationDataMapper';
import { PaymentOptionTypeDataMapper } from 'app/module/payment/data/repository/api/dataMapper/PaymentOptionTypeDataMapper';
import { CategoryDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/CategoryDataMapper';

export class ProfileDataMapper {

    public static fromTypeResponse(type: EProfileTypeResponse): EProfileType {
        switch(type){
            case EProfileTypeResponse.DEMO:
                return EProfileType.DEMO;
            case EProfileTypeResponse.OFFICIAL:
                return EProfileType.OFFICIAL;
            case EProfileTypeResponse.USER:
                return EProfileType.USER;
            default:
                throw new Error('Invalid Profile Type');
        }
    }

    public static  fromResponse(profileResponse: IProfileResponse): IProfile {
        const reduxData = ReduxStore.getState();
        const nationality = profileResponse.nationality ? reduxData.nationalities.nationalities.find(
            (item) => item.id === profileResponse.nationality,
        ) as INationality : null;
        const province = reduxData.provinces.provinces.find(
            (item) => item.id === profileResponse.province,
        ) as IProvince;

        return {
            id: profileResponse.id,
            province,
            nationality,
            age: profileResponse.age,
            name: profileResponse.name,
            image: profileResponse.image,
            isEnabled: profileResponse.enabled,
            userName: profileResponse.username,
            phone: Number(profileResponse.phone),
            imageSmall: profileResponse.imageSmall,
            description: profileResponse.description,
            hasTelegram: profileResponse.hasTelegram,
            hasWhatsapp: profileResponse.hasWhatsapp,
            canPhoneContact: profileResponse.phoneContact,
            canEmailContact: profileResponse.emailContact,
            birthDate: new Date(profileResponse.birthDate),
            subscriptionId: profileResponse.subscriptionId,
            type: this.fromTypeResponse(profileResponse.type),
            category: CategoryDataMapper.arrayFromResponse(profileResponse.category),
            gender: GenderDataMapper.fromResponse(profileResponse.gender),
            commercialConsent: profileResponse.commercialConsent || false,
            slogan: profileResponse.slogan ? profileResponse.slogan : null,
            price: profileResponse.minPrice ? profileResponse.minPrice : null,
            services: ServiceDataMapper.arrayFromResponse(profileResponse.services),
            email: profileResponse.email ? new Email(profileResponse.email) : undefined,
            externalLink: profileResponse.externalLink ? profileResponse.externalLink : null,
            availability: DaysOfWeekDataMapper.arrayFromResponse(profileResponse.daysOfWeek),
            paymentMethods: PaymentMethodDataMapper.arrayFromResponse(profileResponse.paymentMethods),
            telegramUsername: profileResponse.telegramUsername ? profileResponse.telegramUsername : '',
            height: profileResponse.height ? HeightDataMapper.fromResponse(profileResponse.height) : null,
            sexualOrientation: SexualOrientationDataMapper.fromResponse(profileResponse.sexualOrientation),
            schedule: profileResponse.timeOfDay ? ScheduleDataMapper.fromResponse(profileResponse.timeOfDay) : null,
            hairColor: profileResponse.hairColor ? HairColorDataMapper.fromResponse(profileResponse.hairColor) : null,
            subscriptionOptionId: profileResponse.subscriptionOptionId ? profileResponse.subscriptionOptionId : undefined,
            subscriptionUntil: profileResponse.subscriptionUntil ? new Date(profileResponse.subscriptionUntil) : undefined,
            subscriptionType: profileResponse.subscriptionType ?
                PaymentOptionTypeDataMapper.fromResponse(profileResponse.subscriptionType) :
                undefined,
            city: {
                id: profileResponse.city.id,
                name: profileResponse.city.name,
            }
        };
    }
}
