import { IDayOfWeek } from 'app/module/profile/domain/valueObject/DayOfWeek';
import { IHairColor } from 'app/module/profile/domain/valueObject/HairColor';
import { IPaymentMethod } from 'app/module/profile/domain/valueObject/PaymentMethod';
import { IService } from 'app/module/profile/domain/valueObject/Service';
import { ISchedule } from 'app/module/profile/domain/valueObject/Schedule';
import { IHeight } from 'app/module/profile/domain/valueObject/Height';
import ICity from 'app/module/profile/domain/entity/City';
import INationality from 'app/module/profile/domain/entity/Nationality';
import IProvince from 'app/module/profile/domain/entity/Province';
import { ISexualOrientation } from 'app/module/profile/domain/valueObject/SexualOrientation';
import { IGender } from 'app/module/profile/domain/valueObject/Gender';
import { Email } from 'app/common/domain/valueObject/Email';
import { EPaymentOptionType } from 'app/module/payment/domain/valueObject/PaymentOptionType';
import { ICategory } from 'app/module/profile/domain/valueObject/Category';

export interface IProfile {
    id: string
    age: number
    city: ICity
    name: string
    email?: Email
    image: string
    phone: number
    gender: IGender
    birthDate: Date
    userName: string
    isEnabled: boolean
    imageSmall: string
    type: EProfileType
    province: IProvince
    description: string
    hasTelegram: boolean
    hasWhatsapp: boolean
    services: IService[]
    price: number | null
    category: ICategory[]
    slogan: string | null
    height: IHeight | null
    subscriptionId?: string
    subscriptionUntil?: Date
    canEmailContact: boolean
    canPhoneContact: boolean
    telegramUsername: string
    commercialConsent: boolean
    availability: IDayOfWeek[]
    schedule: ISchedule | null
    externalLink: string | null
    hairColor: IHairColor | null
    subscriptionOptionId?: string
    nationality: INationality | null
    paymentMethods: IPaymentMethod[]
    sexualOrientation: ISexualOrientation
    subscriptionType?: EPaymentOptionType
}

export enum EProfileType {
    USER = 'USER',
    DEMO = 'DEMO',
    OFFICIAL = 'OFFICIAL',
}

