import { EDayOfWeek,IDayOfWeek } from 'app/module/profile/domain/valueObject/DayOfWeek';
import { EApiDayOfWeek } from 'app/common/data/remote/sexonApi/dto/ApiDayOfWeek';

export class DaysOfWeekDataMapper {

    public static arrayFromDomain(days: IDayOfWeek[]): EApiDayOfWeek[] {
        const daysResponse: EApiDayOfWeek[] = [];

        days.forEach((day) => {
            daysResponse.push(this._fromDomain(day));
        });

        return daysResponse;
    }

    public static fromResponse(day: EApiDayOfWeek): IDayOfWeek {
        switch( day ){
            case EApiDayOfWeek.MONDAY:
                return {
                    value: EDayOfWeek.MONDAY
                };
            case EApiDayOfWeek.TUESDAY:
                return {
                    value: EDayOfWeek.TUESDAY
                };
            case EApiDayOfWeek.WEDNESDAY:
                return {
                    value: EDayOfWeek.WEDNESDAY
                };
            case EApiDayOfWeek.THURSDAY:
                return {
                    value: EDayOfWeek.THURSDAY
                };
            case EApiDayOfWeek.FRIDAY:
                return {
                    value: EDayOfWeek.FRIDAY
                };
            case EApiDayOfWeek.SATURDAY:
                return {
                    value: EDayOfWeek.SATURDAY
                };
            case EApiDayOfWeek.SUNDAY:
            default:
                return {
                    value: EDayOfWeek.SUNDAY
                };
        }
    }

    public static arrayFromResponse(daysReponse: EApiDayOfWeek[]): IDayOfWeek[] {
        const days: IDayOfWeek[] = [];

        daysReponse.forEach((day) => {
            days.push(this.fromResponse(day));
        });

        return days;
    }

    private static _fromDomain(dayOfWeek: IDayOfWeek): EApiDayOfWeek {
        switch( Number(dayOfWeek.value) ){
            case EDayOfWeek.MONDAY:
                return EApiDayOfWeek.MONDAY;
            case EDayOfWeek.TUESDAY:
                return EApiDayOfWeek.TUESDAY;
            case EDayOfWeek.WEDNESDAY:
                return EApiDayOfWeek.WEDNESDAY;
            case EDayOfWeek.THURSDAY:
                return EApiDayOfWeek.THURSDAY;
            case EDayOfWeek.FRIDAY:
                return EApiDayOfWeek.FRIDAY;
            case EDayOfWeek.SATURDAY:
                return EApiDayOfWeek.SATURDAY;
            case EDayOfWeek.SUNDAY:
            default:
                return EApiDayOfWeek.SUNDAY;
        }
    }
}
