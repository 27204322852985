import i18next from 'i18next';
import { BISEXUAL_LABEL,HETEROSEXUAL_LABEL,HOMOSEXUAL_LABEL } from 'translations/tr';

export interface ISexualOrientation {
    value: ESexualOrientation
}

export enum ESexualOrientation {
    HETEROSEXUAL = 'Heterosexual',
    HOMOSEXUAL = 'Homosexual',
    BISEXUAL = 'Bisexual',
}


export const translateSexualOrientation = (value: ESexualOrientation): string => {
    switch( value ){
        case ESexualOrientation.HETEROSEXUAL:
            return i18next.t(HETEROSEXUAL_LABEL);
        case ESexualOrientation.HOMOSEXUAL:
            return i18next.t(HOMOSEXUAL_LABEL);
        case ESexualOrientation.BISEXUAL:
        default:
            return i18next.t(BISEXUAL_LABEL);
    }
};
