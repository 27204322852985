import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

export const NoIndexHelmet = (): ReactElement => {
    return (
        <Helmet>
            <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
    );
};
