import Cookies from 'js-cookie';
import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ELanguage,ILanguage } from 'app/common/domain/valueObject/Language';
import ICookies from 'app/common/domain/entity/Cookies';

export class CookiesStorageRepository implements IStorageRepository {
    private _cookieOptions = { expires: 30, secure: true };

    public constructor(){}

    public async getAuth(): Promise<IAuth> {
        const userData = Cookies.get('SexonAuth');
        if(userData) {
            return JSON.parse(userData) as IAuth;
        }
        return Promise.reject();
    };

    public async setAuth(auth: IAuth): Promise<void> {
        Cookies.set('SexonAuth', JSON.stringify(auth), this._cookieOptions);
    };

    public async getLanguage(): Promise<ILanguage> {
        return {
            value: Cookies.get('lang') as ELanguage
        };
    };

    public async setLanguage(lang: ILanguage): Promise<void> {
        Cookies.set('lang', lang.value, this._cookieOptions);
    };

    public async logOut(): Promise<void> {
        Cookies.remove('SexonAuth');
    };

    public async getIsFirstTime(): Promise<boolean> {
        const isFirstTimeData = Cookies.get('SexonIsFirstTime');
        if(isFirstTimeData) {
            return JSON.parse(isFirstTimeData) as boolean;
        }

        return true;
    };

    public async setIsFirstTime(value: boolean): Promise<void> {
        Cookies.set('SexonIsFirstTime', JSON.stringify(value), this._cookieOptions);
    };

    public async getAgeConsent(): Promise<boolean> {
        const ageConsent = Cookies.get('SexonAgeConsent');
        if(ageConsent) {
            return JSON.parse(ageConsent) as boolean;
        }

        return false;
    };

    public async setAgeConsent(): Promise<void> {
        Cookies.set('SexonAgeConsent', JSON.stringify(true), { secure: true });
    };

    public async getCookiesConsent(): Promise<ICookies> {
        const cookiesConsent = Cookies.get('SexonCookiesConsent');
        if(cookiesConsent) {
            return JSON.parse(cookiesConsent) as ICookies;
        }

        return {
            cookiesAccepted: false,
            preferences: {
                technical: true,
                analytics: false
            }
        };
    };

    public async setCookiesConsent(cookies: ICookies): Promise<void> {
        Cookies.set('SexonCookiesConsent', JSON.stringify(cookies), this._cookieOptions);
    };
}
