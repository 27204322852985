import IPaymentOptionsRepository from 'app/module/payment/domain/interface/PaymentOptionsRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { IPaymentOption } from 'app/module/payment/domain/entity/PaymentOption';
import { PaymentOptionDataMapper } from 'app/module/payment/data/repository/api/dataMapper/PaymentOptionDataMapper';

export class ApiPaymentOptionsRepository implements IPaymentOptionsRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ){}

    public async findAll(
        auth: IAuth
    ): Promise<IPaymentOption[]> {
        try {
            const response = await this._sexonApiClient.getPaymentOptions({
                token: auth.token.value
            });

            return PaymentOptionDataMapper.fromArrayResponse(response);
        } catch (error){
            return Promise.reject(error);
        }
    };
}
