import INationalityResponse from 'app/common/data/remote/sexonApi/dto/response/NationalityResponse';

export class GetNationalitiesDataMapper {

    public static fromResponse(response: INationalityResponse[]): INationalityResponse[] {
        const nationalities: INationalityResponse[] = [];

        response.forEach((item) => {
            nationalities.push({
                id: item.id,
                name: item.name,
            });
        });
        return nationalities;
    }
}
