import React,{ useEffect,useState } from 'react';
import './SexonModal.scss';
import cx from 'classnames';
import { CloseIcon } from 'assets/icon/sexon/svg/icons';
import { createPortal } from 'react-dom';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { SexonButton } from '../button/SexonButton';

interface ISexonModalProps {
    show: boolean
    body: JSX.Element
    classname: string
    headerTitle: string
    onClose?: () => void
    footer?: JSX.Element
    banner?: JSX.Element
}
export const SexonModal = (props: ISexonModalProps): JSX.Element | null => {
    const [isClosing, setIsClosing] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const wrapperModalClassNames = cx({
        'modal-background': true,
        'back-drop-out': isClosing
    });

    const containerModalClassNames = cx({
        'modal-content': true,
        'modal-out': isClosing
    });

    useEffect((): void => {
        if(props.show){
            setOpenModal(true);
        }else {
            setIsClosing(true);
            setTimeout((): void => {
                setOpenModal(false);
                setIsClosing(false);
            }, 300);
        }
    }, [props.show]);

    return openModal ? (
        createPortal(
            <div className={`${props.classname} component-modal`}>
                <div
                    onClick={props.onClose}
                    className={wrapperModalClassNames}
                >{}</div>
                <div className={containerModalClassNames}>
                    {
                        props.banner ? (
                            <div className="modal-banner">
                                {props.banner}
                            </div>
                        ) : null
                    }
                    <div className="modal-header">
                        <span>
                            {props.headerTitle}
                        </span>
                        {props.onClose ? (
                            <SexonButton
                                type="button"
                                onClick={props.onClose}
                                className="modal-header-close-button"
                                styling={ESexonButtonStyle.BASIC_PRIMARY}
                                title={(
                                    <CloseIcon />
                                )}
                            />
                        ) : null}
                    </div>
                    <div className="modal-body">
                        {props.body}
                    </div>
                    {props.footer ? (
                        <div className="modal-footer">
                            {props.footer}
                        </div>
                    ) : null}
                </div>
            </div>,
            document.body
        )
    ) : null;
};
