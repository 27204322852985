import React from 'react';
import {
    CREATE_PROFILE,
    LOGIN,
    REGISTER_MODAL_CREATE_PROFILE_TEXT,
    REGISTER_MODAL_CREATE_PROFILE_TITLE,
    REGISTER_MODAL_REGISTRATION_TEXT,
    REGISTER_MODAL_REGISTRATION_TITLE,
    SIGN_UP_FREE,
} from 'translations/tr';
import './RegisterModal.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ShowRegisterModalActions } from 'app/common/presentation/redux/registerModal/ShowRegisterModalSlice';
import { EAppRoute } from 'app/common/presentation/routing/AppRoute';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { useShowRegisterModalReduxState } from '../../../redux/registerModal/hook/useShowRegisterModalReduxState';
import { SexonButton } from '../../button/SexonButton';
import { useAuth } from '../../../hook/useAuth';
import { useCurrentProfile } from '../../../hook/useCurrentProfile';
import { SexonModal } from '../../modal/SexonModal';

export const RegisterModal = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const showModal = useShowRegisterModalReduxState();
    const UseAuth = useAuth();
    const UseCurrentProfile = useCurrentProfile();

    const handleHeaderTitle = (): string => {
        if(!UseAuth.isAuthed) {
            return t(REGISTER_MODAL_REGISTRATION_TITLE);
        } if(!UseCurrentProfile.profileIsCreated){
            return t(REGISTER_MODAL_CREATE_PROFILE_TITLE);
        }

        return '';
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const BodyText = (): JSX.Element | null => {
        if(!UseAuth.isAuthed) {
            return (
                <p
                    dangerouslySetInnerHTML={ { __html: t(REGISTER_MODAL_REGISTRATION_TEXT).toString() } }
                />
            );
        } if(!UseCurrentProfile.profileIsCreated){
            return (
                <p
                    dangerouslySetInnerHTML={ { __html: t(REGISTER_MODAL_CREATE_PROFILE_TEXT).toString() } }
                />
            );
        }

        return null;
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const FooterButtons = (): JSX.Element | null => {
        if(!UseAuth.isAuthed) {
            return (
                <>
                    <SexonButton
                        type="button"
                        title={t(LOGIN).toUpperCase()}
                        styling={ESexonButtonStyle.FILLED}
                        onClick={(): void=> {
                            navigate(EAppRoute.SIGN_IN);
                            dispatch(ShowRegisterModalActions.reset());
                        }}
                    />
                    <SexonButton
                        type="button"
                        title={t(SIGN_UP_FREE).toUpperCase()}
                        styling={ESexonButtonStyle.FILLED_SECONDARY}
                        onClick={(): void=> {
                            navigate(EAppRoute.SIGN_UP);
                            dispatch(ShowRegisterModalActions.reset());
                        }}
                    />
                </>
            );
        } if(!UseCurrentProfile.profileIsCreated){
            return (
                <SexonButton
                    title={t(CREATE_PROFILE).toUpperCase()}
                    type="button"
                    styling={ESexonButtonStyle.FILLED}
                    onClick={(): void=> {
                        navigate(EAppRoute.CREATE_PROFILE);
                        dispatch(ShowRegisterModalActions.reset());
                    }}
                />
            );
        }

        return null;
    };

    return (
        <SexonModal
            show={showModal}
            headerTitle={ handleHeaderTitle() }
            classname="component-register-modal"
            onClose={ (): void => {
                dispatch(ShowRegisterModalActions.handleHideRegisterModal());
            } }
            body={ (
                <div className="register-modal-body-container">
                    <BodyText />
                </div>
            ) }
            footer={
                (
                    <div className="register-modal-footer-container">
                        <FooterButtons />
                    </div>
                )
            }
        />
    );
};
