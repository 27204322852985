import i18next from 'i18next';
import {
    ADULT_ACTOR_ACTRESS_DESCRIPTION,
    ADULT_ACTOR_ACTRESS_LABEL,CONTENT_EDITING_PRODUCTION_DESCRIPTION,
    CONTENT_EDITING_PRODUCTION_LABEL,CONTENT_SALE_DESCRIPTION,
    CONTENT_SALE_LABEL,COUPLES_THERAPY_SEXUALITY_DESCRIPTION,
    COUPLES_THERAPY_SEXUALITY_LABEL,DICK_RATE_DESCRIPTION,
    DICK_RATE_LABEL,DOMINATION_DESCRIPTION,
    DOMINATION_LABEL,EROTIC_MODEL_DESCRIPTION,
    EROTIC_MODEL_LABEL,EROTIC_PHOTOGRAPHER_DESCRIPTION,
    EROTIC_PHOTOGRAPHER_LABEL,FETISHES_GENERAL_DESCRIPTION,
    FETISHES_GENERAL_LABEL,FINANCIAL_DOMINATION_DESCRIPTION,
    FINANCIAL_DOMINATION_LABEL,FOOT_FETISH_DESCRIPTION,
    FOOT_FETISH_LABEL,MARKETING_PROMOTION_DESCRIPTION,
    MARKETING_PROMOTION_LABEL,SEXTING_DESCRIPTION,
    SEXTING_LABEL,SEXUAL_EDUCATOR_DESCRIPTION,
    SEXUAL_EDUCATOR_LABEL,SEXUAL_EVENTS_ORGANIZER_DESCRIPTION,
    SEXUAL_EVENTS_ORGANIZER_LABEL,SEXUAL_HEALTH_WELLNESS_DESCRIPTION,
    SEXUAL_HEALTH_WELLNESS_LABEL,SEXUAL_WORKSHOP_ORGANIZER_DESCRIPTION,
    SEXUAL_WORKSHOP_ORGANIZER_LABEL,STRIPTEASE_DESCRIPTION,
    STRIPTEASE_LABEL,VIDEO_CALLS_DESCRIPTION,
    VIDEO_CALLS_LABEL,VIRTUAL_DOMINATRIX_DESCRIPTION,
    VIRTUAL_DOMINATRIX_LABEL,VIRTUAL_PARTNER_DESCRIPTION,
    VIRTUAL_PARTNER_LABEL,VOYEURISM_DESCRIPTION,
    VOYEURISM_LABEL
} from 'translations/tr';

export interface IService {
    value: EService
    description: string
}

export enum EService {
    CONTENT_SALE,
    SEXTING,
    VIDEO_CALLS,
    ADULT_ACTOR_ACTRESS,
    STRIPTEASE,
    DICK_RATE,
    VOYEURISM,
    VIRTUAL_PARTNER,
    DOMINATION,
    FINANCIAL_DOMINATION,
    VIRTUAL_DOMINATRIX,
    FETISHES_GENERAL,
    FOOT_FETISH,
    CONTENT_EDITING_PRODUCTION,
    MARKETING_PROMOTION,
    SEXUAL_HEALTH_WELLNESS,
    EROTIC_MODEL,
    SEXUAL_EVENTS_ORGANIZER,
    SEXUAL_WORKSHOP_ORGANIZER,
    COUPLES_THERAPY_SEXUALITY,
    SEXUAL_EDUCATOR,
    EROTIC_PHOTOGRAPHER,
}

export const translateService = (value: EService): string => {
    switch( value ){
        case EService.CONTENT_SALE:
            return i18next.t(CONTENT_SALE_LABEL);
        case EService.SEXTING:
            return i18next.t(SEXTING_LABEL);
        case EService.VIDEO_CALLS:
            return i18next.t(VIDEO_CALLS_LABEL);
        case EService.ADULT_ACTOR_ACTRESS:
            return i18next.t(ADULT_ACTOR_ACTRESS_LABEL);
        case EService.STRIPTEASE:
            return i18next.t(STRIPTEASE_LABEL);
        case EService.DICK_RATE:
            return i18next.t(DICK_RATE_LABEL);
        case EService.VOYEURISM:
            return i18next.t(VOYEURISM_LABEL);
        case EService.VIRTUAL_PARTNER:
            return i18next.t(VIRTUAL_PARTNER_LABEL);
        case EService.DOMINATION:
            return i18next.t(DOMINATION_LABEL);
        case EService.FINANCIAL_DOMINATION:
            return i18next.t(FINANCIAL_DOMINATION_LABEL);
        case EService.VIRTUAL_DOMINATRIX:
            return i18next.t(VIRTUAL_DOMINATRIX_LABEL);
        case EService.FETISHES_GENERAL:
            return i18next.t(FETISHES_GENERAL_LABEL);
        case EService.FOOT_FETISH:
            return i18next.t(FOOT_FETISH_LABEL);
        case EService.CONTENT_EDITING_PRODUCTION:
            return i18next.t(CONTENT_EDITING_PRODUCTION_LABEL);
        case EService.MARKETING_PROMOTION:
            return i18next.t(MARKETING_PROMOTION_LABEL);
        case EService.SEXUAL_HEALTH_WELLNESS:
            return i18next.t(SEXUAL_HEALTH_WELLNESS_LABEL);
        case EService.EROTIC_MODEL:
            return i18next.t(EROTIC_MODEL_LABEL);
        case EService.SEXUAL_EVENTS_ORGANIZER:
            return i18next.t(SEXUAL_EVENTS_ORGANIZER_LABEL);
        case EService.SEXUAL_WORKSHOP_ORGANIZER:
            return i18next.t(SEXUAL_WORKSHOP_ORGANIZER_LABEL);
        case EService.COUPLES_THERAPY_SEXUALITY:
            return i18next.t(COUPLES_THERAPY_SEXUALITY_LABEL);
        case EService.SEXUAL_EDUCATOR:
            return i18next.t(SEXUAL_EDUCATOR_LABEL);
        case EService.EROTIC_PHOTOGRAPHER:
        default:
            return i18next.t(EROTIC_PHOTOGRAPHER_LABEL);
    }
};


export const translateServiceDescription = (value: EService): string => {
    switch( value ){
        case EService.CONTENT_SALE:
            return i18next.t(CONTENT_SALE_DESCRIPTION);
        case EService.SEXTING:
            return i18next.t(SEXTING_DESCRIPTION);
        case EService.VIDEO_CALLS:
            return i18next.t(VIDEO_CALLS_DESCRIPTION);
        case EService.ADULT_ACTOR_ACTRESS:
            return i18next.t(ADULT_ACTOR_ACTRESS_DESCRIPTION);
        case EService.STRIPTEASE:
            return i18next.t(STRIPTEASE_DESCRIPTION);
        case EService.DICK_RATE:
            return i18next.t(DICK_RATE_DESCRIPTION);
        case EService.VOYEURISM:
            return i18next.t(VOYEURISM_DESCRIPTION);
        case EService.VIRTUAL_PARTNER:
            return i18next.t(VIRTUAL_PARTNER_DESCRIPTION);
        case EService.DOMINATION:
            return i18next.t(DOMINATION_DESCRIPTION);
        case EService.FINANCIAL_DOMINATION:
            return i18next.t(FINANCIAL_DOMINATION_DESCRIPTION);
        case EService.VIRTUAL_DOMINATRIX:
            return i18next.t(VIRTUAL_DOMINATRIX_DESCRIPTION);
        case EService.FETISHES_GENERAL:
            return i18next.t(FETISHES_GENERAL_DESCRIPTION);
        case EService.FOOT_FETISH:
            return i18next.t(FOOT_FETISH_DESCRIPTION);
        case EService.CONTENT_EDITING_PRODUCTION:
            return i18next.t(CONTENT_EDITING_PRODUCTION_DESCRIPTION);
        case EService.MARKETING_PROMOTION:
            return i18next.t(MARKETING_PROMOTION_DESCRIPTION);
        case EService.SEXUAL_HEALTH_WELLNESS:
            return i18next.t(SEXUAL_HEALTH_WELLNESS_DESCRIPTION);
        case EService.EROTIC_MODEL:
            return i18next.t(EROTIC_MODEL_DESCRIPTION);
        case EService.SEXUAL_EVENTS_ORGANIZER:
            return i18next.t(SEXUAL_EVENTS_ORGANIZER_DESCRIPTION);
        case EService.SEXUAL_WORKSHOP_ORGANIZER:
            return i18next.t(SEXUAL_WORKSHOP_ORGANIZER_DESCRIPTION);
        case EService.COUPLES_THERAPY_SEXUALITY:
            return i18next.t(COUPLES_THERAPY_SEXUALITY_DESCRIPTION);
        case EService.SEXUAL_EDUCATOR:
            return i18next.t(SEXUAL_EDUCATOR_DESCRIPTION);
        case EService.EROTIC_PHOTOGRAPHER:
        default:
            return i18next.t(EROTIC_PHOTOGRAPHER_DESCRIPTION);
    }
};
