import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterFormData } from 'app/common/presentation/screen/feed/filterModal/filterForm/FilterFormData';

const initialState = {
    feedFilters: {
        city: [],
        height: [],
        services: [],
        gender: null,
        schedule: [],
        hairColor: [],
        province: null,
        canEmail: true,
        telegram: true,
        canPhone: true,
        whatsapp: true,
        nationality: [],
        availability: [],
        paymentMethods: [],
        ageRange: [18, 100],
        sexualOrientation: null,
        category: [],
    } as IFilterFormData
};

const slice = createSlice({
    name: 'feedFilters',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<IFilterFormData>) => {
            state.feedFilters = action.payload;
        },
        setFiltersToInitialState: (state) => {
            state.feedFilters = initialState.feedFilters;
        },
        reset: (state) => {
            state.feedFilters = initialState.feedFilters;
        }
    },
});

export const FeedFiltersReducer = slice.reducer;
export const FeedFiltersActions = slice.actions;
