import IAgeConsentRepository from 'app/common/domain/interface/AgeConsentRepository';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';

export class ApiAgeConsentRepository implements IAgeConsentRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ){}

    public async accept(): Promise<void> {
        try {
            return await this._sexonApiClient.acceptAgeConsent();
        } catch (error){
            return Promise.reject(error);
        }
    };
}
