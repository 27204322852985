import { createSlice,PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isVerified: true
};

const slice = createSlice({
    name: 'phoneIsVerified',
    initialState,
    reducers: {
        setPhoneIsVerified: (state, action: PayloadAction<boolean>) => {
            state.isVerified = action.payload;
        },
    },
});

export const PhoneIsVerifiedReducer = slice.reducer;
export const PhoneIsVerifiedActions = slice.actions;
