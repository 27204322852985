import { configureStore } from '@reduxjs/toolkit';
import { FeedReducer } from 'app/common/presentation/redux/feed/FeedSlice';
import { FeedFiltersReducer } from 'app/common/presentation/redux/feed/FeedFiltersSlice';
import { ProvinceReducer } from 'app/common/presentation/redux/province/ProvinceSlice';
import { CityReducer } from 'app/common/presentation/redux/city/CitySlice';
import { ToastErrorReducer } from 'app/common/presentation/redux/toast/ToastErrorSlice';
import { ToastSuccessfulReducer } from 'app/common/presentation/redux/toast/ToastSuccessfulSlice';
import { AuthReducer } from 'app/common/presentation/redux/auth/AuthSlice';
import { CurrentProfileReducer } from 'app/common/presentation/redux/currentProfile/CurrentProfileSlice';
import { HeightsReducer } from 'app/common/presentation/redux/height/HeightSlice';
import { DaysOfWeekReducer } from 'app/common/presentation/redux/daysOfWeek/DaysOfWeekSlice';
import { HairColorsReducer } from 'app/common/presentation/redux/hairColor/HairColorSlice';
import { SchedulesReducer } from 'app/common/presentation/redux/schedule/ScheduleSlice';
import { PaymentMethodsReducer } from 'app/common/presentation/redux/paymentMethod/PaymentMethodSlice';
import { FullScreenSpinnerLoadingReducer } from 'app/common/presentation/redux/fullScreenSpinner/FullScreenSpinnerLoadingSlice';
import { ServicesReducer } from 'app/common/presentation/redux/service/ServiceSlice';
import { NationalityReducer } from 'app/common/presentation/redux/nationality/NationalitySlice';
import { ToastInfoReducer } from 'app/common/presentation/redux/toast/ToastInfoSlice';
import { NavBarReducer } from 'app/common/presentation/redux/navBar/NavBarSlice';
import { FeedLastPostIndexReducer } from 'app/common/presentation/redux/feed/FeedLastPostIndexSlice';
import { IsFistTimeReducer } from 'app/common/presentation/redux/isFistTime/IsFirstTimeSlice';
import { GendersReducer } from 'app/common/presentation/redux/gender/GenderSlice';
import { OrientationsReducer } from 'app/common/presentation/redux/sexualOrientation/SexualOrientationSlice';
import { ShowSettingsModalReducer } from 'app/common/presentation/redux/settingsModal/ShowSettingsModalSlice';
import { ShowRegisterModalReducer } from 'app/common/presentation/redux/registerModal/ShowRegisterModalSlice';
import { AgeConsentReducer } from 'app/common/presentation/redux/ageConsent/AgeConsentSlice';
import { ReportModalReducer } from 'app/common/presentation/redux/reportModal/ReportModalSlice';
import { ReportReasonsReducer } from 'app/common/presentation/redux/reportModal/ReportReasonSlice';
import { CreatePostModalReducer } from 'app/common/presentation/redux/createPostModal/CreatePostModalSlice';
import { SuggestionsModalReducer } from 'app/common/presentation/redux/suggestionsModal/SuggestionsModalSlice';
import { ShowFiltersModalModalReducer } from 'app/common/presentation/redux/feed/ShowFiltersModalSlice';
import { PhoneIsVerifiedReducer } from 'app/common/presentation/redux/phoneIsVerified/PhoneIsVerifySlice';
import { CookiesConsentReducer } from 'app/common/presentation/redux/cookiesConsent/CookiesConsentSlice';
import { ShowCookiesPreferencesReducer } from 'app/common/presentation/redux/cookiesPreferences/CookiesPreferencesSlice';
import { ShowHelpSectionModalReducer } from 'app/common/presentation/redux/helpSectionModal/ShowHelpSectionModalSlice';
import { CategoriesReducer } from 'app/common/presentation/redux/category/CategorySlice';

export const ReduxStore = configureStore({
    reducer: {
        auth: AuthReducer,
        feed: FeedReducer,
        cities: CityReducer,
        navBar: NavBarReducer,
        heights: HeightsReducer,
        genders: GendersReducer,
        services: ServicesReducer,
        provinces: ProvinceReducer,
        schedules: SchedulesReducer,
        inputInfo: ToastInfoReducer,
        categories: CategoriesReducer,
        ageConsent: AgeConsentReducer,
        toastError: ToastErrorReducer,
        daysOfWeek: DaysOfWeekReducer,
        hairColors: HairColorsReducer,
        isFirstTime: IsFistTimeReducer,
        feedFilters: FeedFiltersReducer,
        reportModal: ReportModalReducer,
        orientations: OrientationsReducer,
        nationalities: NationalityReducer,
        reportReasons: ReportReasonsReducer,
        cookiesConsent: CookiesConsentReducer,
        currentProfile: CurrentProfileReducer,
        paymentMethods: PaymentMethodsReducer,
        phoneIsVerified: PhoneIsVerifiedReducer,
        toastSuccessful: ToastSuccessfulReducer,
        createPostModal: CreatePostModalReducer,
        suggestionsModal: SuggestionsModalReducer,
        showHelpSectionModal: ShowHelpSectionModalReducer,
        showSettingsModal: ShowSettingsModalReducer,
        showRegisterModal: ShowRegisterModalReducer,
        feedLastPostIndex: FeedLastPostIndexReducer,
        showFiltersModalModal: ShowFiltersModalModalReducer,
        fullScreenSpinnerLoading: FullScreenSpinnerLoadingReducer,
        showCookiesPreferencesModal: ShowCookiesPreferencesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof ReduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ReduxStore.dispatch;
