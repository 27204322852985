import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    toastSuccessfulMessage: ''
};

const slice = createSlice({
    name: 'toastSuccessfulMessage',
    initialState,
    reducers: {
        setToastSuccessfulMessage: (state, action: PayloadAction<string>) => {
            state.toastSuccessfulMessage = action.payload;
        },
    },
});

export const ToastSuccessfulReducer = slice.reducer;
export const ToastSuccessfulActions = slice.actions;
