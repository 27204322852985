import IAgeConsentRepository from 'app/common/domain/interface/AgeConsentRepository';

interface IAcceptAgeConsentUseCaseProps {
    ageConsentRepository: IAgeConsentRepository
}

export default async (props: IAcceptAgeConsentUseCaseProps): Promise<void> => {
    try {
        return  await props.ageConsentRepository.accept();
    } catch (error) {
        return Promise.reject(error);
    }
};
