import React from 'react';
import { ACCEPT,COOKIES_PREFERENCES_MODAL_TITLE,DENY,SAVE_PREFERENCES } from 'translations/tr';
import './CookiesPreferencesModal.scss';
import { useTranslation } from 'react-i18next';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { useShowCookiesPreferencesConsentReduxState } from 'app/common/presentation/redux/cookiesPreferences/hook/useShowCookiesPreferencesConsentReduxState';
import { CookiesPreferencesForm } from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/CookiesPreferencesForm';
import { useCookiesPreferencesModalController } from 'app/common/presentation/component/cookiesPreferencesModal/useCookiesPreferencesModalController';
import { SexonModal } from '../modal/SexonModal';
import { SexonButton } from '../button/SexonButton';

export const CookiesPreferencesModal = (): JSX.Element => {
    const { t } = useTranslation();
    const showModal = useShowCookiesPreferencesConsentReduxState();
    const controller = useCookiesPreferencesModalController();

    return (
        <SexonModal
            show={showModal}
            classname="component-cookies-preferences-modal"
            headerTitle={ t(COOKIES_PREFERENCES_MODAL_TITLE) }
            body={ (
                <div className="cookies-preferences-modal-body-container">
                    <CookiesPreferencesForm
                        UseCookiesPreferencesForm={controller.UseCookiesPreferencesModalForm}
                    />
                </div>
            ) }

            footer={(
                <div className="cookies-preferences-modal-footer-container">
                    <SexonButton
                        type="button"
                        onClick={controller.saveCookies}
                        title={ t(SAVE_PREFERENCES).toUpperCase() }
                        styling={ ESexonButtonStyle.BASIC_PRIMARY }
                        className="age-consent-modal-footer-save-button"
                    />
                    <div className="cookies-preferences-modal-footer-actions-buttons-container">
                        <SexonButton
                            type="button"
                            title={ t(DENY).toUpperCase()}
                            onClick={controller.denyCookies}
                            styling={ESexonButtonStyle.FILLED}
                            className="cookies-preferences-modal-footer-deny-button"
                        />
                        <SexonButton
                            type="button"
                            title={ t(ACCEPT).toUpperCase() }
                            onClick={controller.acceptCookies}
                            styling={ ESexonButtonStyle.FILLED }
                            className="age-consent-modal-footer-accept-button"
                        />
                    </div>
                </div>
            ) }
        />
    );
};
