import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { DependencyInjectionProvider } from 'app/common/presentation/context/DependecyInjectionContext';
import { App } from 'app/App';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={ReduxStore}>
        <DependencyInjectionProvider>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </DependencyInjectionProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
