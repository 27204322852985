import React from 'react';
import './FullScreenSpinner.scss';
import { SexonSpinner } from '../../spinner/SexonSpinner';
import { useFullScreenSpinnerIsLoadingReduxState } from '../../../redux/fullScreenSpinner/hook/useFullScreenSpinnerIsLoadingReduxState';

export const FullScreenSpinner = (): JSX.Element | null => {
    const isLoading = useFullScreenSpinnerIsLoadingReduxState();
    return isLoading ? (
        <div className="component-full-screen-spinner">
            <div className="full-screen-spinner-background">{}</div>
            <SexonSpinner />
        </div>
    ) : null;
};
