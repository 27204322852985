import IProfileRepository from 'app/module/profile/domain/interface/ProfileRepository';
import { IProfile } from 'app/module/profile/domain/entity/Profile';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import ICity from 'app/module/profile/domain/entity/City';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { ProfileDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ProfileDataMapper';
import { CreateProfileDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/CreateProfileDataMapper';
import { EditProfileDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/EditProfileDataMapper';
import { ReportProfileDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ReportProfileDataMapper';
import { Report } from 'app/module/profile/domain/valueObject/Report';

export class ApiProfileRepository implements IProfileRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async findCurrentProfile(
        auth: IAuth
    ): Promise<IProfile> {
        try {
            const profileData = await this._sexonApiClient.getCurrentProfile({
                token: auth.token.value
            });

            return ProfileDataMapper.fromResponse(profileData);
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async add(
        profile: IProfile, auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.createProfile(
                CreateProfileDataMapper.fromDomain(profile, auth),
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async edit(
        profile: IProfile,
        auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.editProfile(
                EditProfileDataMapper.fromDomain(profile, auth),
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async hideProfile(
        profile: IProfile,
        auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.hideProfile({
                profileId: profile.id,
                token: auth.token.value,
            });
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async showProfile(
        profile: IProfile,
        auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.showProfile({
                profileId: profile.id,
                token: auth.token.value,
            });
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async findByUserName(
        userName: string,
        auth: IAuth
    ): Promise<IProfile> {
        try {
            const profileData = await this._sexonApiClient.getProfile({
                userName,
                token: auth.token.value
            });
            return ProfileDataMapper.fromResponse(profileData);
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async checkUserName(
        profileId: string,
        username: string
    ): Promise<void> {
        try {
            return await this._sexonApiClient.checkUserName(
                {
                    id: profileId,
                    username
                }
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async reportProfile(report: Report): Promise<void> {
        try {
            return await this._sexonApiClient.reportProfile(
                ReportProfileDataMapper.fromDomain(report)
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async addProfileCity(
        profileId: string,
        cities: ICity[],
        auth: IAuth
    ): Promise<void> {
        try {
            return  await this._sexonApiClient.addProfileCities(
                {
                    payload: {
                        citiesId: cities.map((city)=> city.id),
                    },
                    profileId,
                    token: auth.token.value
                },
            );
        } catch (error){
            return Promise.reject(error);
        }
    }


}
