import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { ToastSuccessfulActions } from 'app/common/presentation/redux/toast/ToastSuccessfulSlice';
import { ToastErrorActions } from 'app/common/presentation/redux/toast/ToastErrorSlice';
import { ToastInfoActions } from 'app/common/presentation/redux/toast/ToastInfoSlice';

export default async (
    successfulMessage: string
): Promise<void> => {
    ReduxStore.dispatch(ToastErrorActions.setToastErrorMessage(''));
    ReduxStore.dispatch(ToastInfoActions.setInfo(''));
    ReduxStore.dispatch(ToastSuccessfulActions.setToastSuccessfulMessage(''));
    setTimeout(()=> {
        ReduxStore.dispatch(ToastSuccessfulActions.setToastSuccessfulMessage(successfulMessage));
    }, 350);
};
