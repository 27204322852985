import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import IFeed from 'app/module/profile/domain/entity/Feed';

const initialState = {
    feed: {
        seed: '',
        actualPage: 0,
        limit: 0,
        hasMore: false,
        items: [],
    } as IFeed
};

const slice = createSlice({
    name: 'feedItems',
    initialState,
    reducers: {
        setInitialFeed: (state, action: PayloadAction<IFeed>) => {
            state.feed.seed = action.payload.seed;
            state.feed.items = [...action.payload.items];
            state.feed.limit = action.payload.limit;
            state.feed.actualPage = action.payload.actualPage;
            state.feed.hasMore = action.payload.hasMore;
        },
        setMoreFeed: (state, action: PayloadAction<IFeed>) => {
            state.feed.seed = action.payload.seed;
            state.feed.items = [
                ...state.feed.items,
                ...action.payload.items];
            state.feed.limit = action.payload.limit;
            state.feed.actualPage = action.payload.actualPage;
            state.feed.hasMore = action.payload.hasMore;
        },
        reset: (state) => {
            state.feed = initialState.feed;
        }
    },
});

export const FeedReducer = slice.reducer;
export const FeedActions = slice.actions;
