import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from 'app/module/profile/domain/entity/Profile';

const initialState: {
    profileToReport?: IProfile
} = {
    profileToReport: undefined
};

const slice = createSlice({
    name: 'reportModal',
    initialState,
    reducers: {
        handleShowReportModal: (state, action: PayloadAction<IProfile>) => {
            state.profileToReport = action.payload;
        },
        handleHideReportModal: (state) => {
            state.profileToReport = undefined;
        },
        reset: (state) => {
            state.profileToReport = undefined;
        }
    },
});

export const ReportModalReducer = slice.reducer;
export const ReportModalActions = slice.actions;
