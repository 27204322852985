import i18next from 'i18next';
import { BETWEEN_160_AND_170_LABEL,LESS_THAN_160_LABEL,MORE_THAN_170_LABEL } from 'translations/tr';

export interface IHeight {
    value: EHeight
}

export enum EHeight {
    LESS_THAN_160 = 'LESS_THAN_160',
    BETWEEN_160_AND_170 = 'BETWEEN_160_AND_170',
    MORE_THAN_170 = 'MORE_THAN_170',
}

export const translateHeight = (value: EHeight): string => {
    switch( value ){
        case EHeight.LESS_THAN_160:
            return i18next.t(LESS_THAN_160_LABEL);
        case EHeight.BETWEEN_160_AND_170:
            return i18next.t(BETWEEN_160_AND_170_LABEL);
        case EHeight.MORE_THAN_170:
        default:
            return i18next.t(MORE_THAN_170_LABEL);
    }
};
