import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showRegisterModal',
    initialState,
    reducers: {
        handleShowRegisterModal: (state) => {
            state.showModal = true;
        },
        handleHideRegisterModal: (state) => {
            state.showModal = false;
        },
        reset: (state) => {
            state.showModal = false;
        }
    },
});

export const ShowRegisterModalReducer = slice.reducer;
export const ShowRegisterModalActions = slice.actions;
