const RegexResource = {
    MOBILE: /^[67]\d{8}$/,
    USERNAME: /^\s*[a-zA-Z0-9._]+\s*$/,
    EXTERNAL_LINK: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{1,}(\.[a-zA-Z]{2,})+([\\/?#][^\s]*)?$/,
    TELEGRAM_USERNAME: /^\s*@?[A-Za-z][A-Za-z0-9_]*\s*$/,
    PROFILE_NAME: /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ._\s]+$/,
    EMAIL: /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!¡?"¿@#$%^&*()_.'+-,/:;<>=+,¨€-])[^\s]{8,}$/,
    CURRENCY_SYMBOLS: /^[^$€£¥₽₹₩₪₫₭₦₲₵₡₣₺₨₳₯₤₧₱]*$/
};

export default RegexResource;
