import { createSlice,PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    show: true
};

const slice = createSlice({
    name: 'navBar',
    initialState,
    reducers: {
        setShow: (state, action: PayloadAction<boolean>) => {
            // eslint-disable-next-line no-param-reassign
            state.show = action.payload;
        },
    },
});

export const NavBarReducer = slice.reducer;
export const NavBarActions = slice.actions;
