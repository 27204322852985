import React from 'react';
import './LazyFallBackSpinner.scss';
import { SexonSpinner } from '../SexonSpinner';


export const LazyFallbackSpinner = (): JSX.Element => {
    return (
        <div className="component-lazy-fallback-spinner">
            <div className="lazy-fallback-spinner-background">{}</div>
            <SexonSpinner />
        </div>
    );
};
