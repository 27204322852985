import ICityResponse from 'app/common/data/remote/sexonApi/dto/response/CityResponse';
import ICity from 'app/module/profile/domain/entity/City';

export class GetCitiesDataMapper {

    public static fromResponse(response: ICityResponse[]): ICity[] {
        const cities: ICity[] = [];

        response.forEach((item) => {
            cities.push({
                id: item.id,
                name: item.name,
            });
        });

        return cities;
    }
}
