import { useTranslation } from 'react-i18next';
import './CreatePostModal.scss';
import { CREATE_POST,CREATE_POST_MODAL_INSTRUCTIONS,CREATE_POST_MODAL_TITLE,LATER } from 'translations/tr';
import { useNavigate } from 'react-router-dom';
import { EAppRoute } from 'app/common/presentation/routing/AppRoute';
import { useDispatch } from 'react-redux';
import { CreatePostModalActions } from 'app/common/presentation/redux/createPostModal/CreatePostModalSlice';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { SexonModal } from 'app/common/presentation/component/modal/SexonModal';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { useCreatePostModalReduxState } from '../../../redux/createPostModal/hook/useCreatePostModalReduxState';
import { useCurrentProfile } from '../../../hook/useCurrentProfile';

export const CreatePostModal = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentProfile } = useCurrentProfile();
    const showModal = useCreatePostModalReduxState();
    const { t } = useTranslation();
    const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_URL;
    const goToProfile = (): void => {
        navigate(`/${currentProfile.userName}/`);
    };

    return (
        <SexonModal
            show={ showModal }
            classname="component-create-post-modal"
            headerTitle={ t(CREATE_POST_MODAL_TITLE) }
            body={ (
                <div className="create-post-modal-body-container">
                    <p
                        dangerouslySetInnerHTML={ { __html: t(CREATE_POST_MODAL_INSTRUCTIONS , { helpCenterUrl }).toString() } }
                    />
                </div>
            ) }

            footer={(
                <div className="create-post-modal-footer-container">
                    <SexonButton
                        title={ t(LATER).toUpperCase()}
                        type="button"
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        onClick={(): void=> {
                            goToProfile();
                            dispatch(CreatePostModalActions.handleHideCreatePostModal());
                        }}
                    />
                    <SexonButton
                        title={ t(CREATE_POST).toUpperCase()}
                        type="button"
                        styling={ESexonButtonStyle.FILLED}
                        onClick={(): void=> {
                            navigate(EAppRoute.CREATE_POST);
                            dispatch(CreatePostModalActions.handleHideCreatePostModal());
                        }}
                    />
                </div>
            )}
        />
    );
};
