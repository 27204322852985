export class Amount {
    private _value: number;

    private _currency: string;

    constructor(value: number) {
        this._currency = 'EUR';
        this._value = value;
    }

    public format(locale: string = 'es-ES'): string {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: this._currency }).format(
            this._value,
        );
    }

    get value(): number {
        return this._value;
    }
}
