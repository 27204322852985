import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE } from 'translations/tr';
import { SexonCheckbox } from 'app/common/presentation/component/checkbox/SexonChexbox';

interface ILegalNoticeAndPrivacyAcceptanceFormFieldProps {
    showError: boolean
    errorMessage: string
    register: UseFormRegisterReturn
}

export const LegalNoticeAndPrivacyAcceptanceFormField = (props: ILegalNoticeAndPrivacyAcceptanceFormFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const legalNoticeUrl = process.env.REACT_APP_LEGAL_NOTICE_URL;
    const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;

    return (
        <SexonCheckbox
            isRequired
            register={props.register}
            showError={props.showError}
            errorMessage={props.errorMessage}
            id="legalNoticeAndPrivacyAcceptanceFormField"
            label={t(LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE, { legalNoticeUrl, privacyPolicyUrl }).toString()}
        />
    );
};
