import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import ICookies from 'app/common/domain/entity/Cookies';

const initialState: ICookies = {
    cookiesAccepted: false,
    preferences: {
        technical: true,
        analytics: false
    }
};

const slice = createSlice({
    name: 'cookiesConsent',
    initialState,
    reducers: {
        setConsent: (state,action: PayloadAction<ICookies>) => {
            state.cookiesAccepted = action.payload.cookiesAccepted;
            state.preferences = action.payload.preferences;
        },
    },
});

export const CookiesConsentReducer = slice.reducer;
export const CookiesConsentActions = slice.actions;
