import { Amount } from 'app/common/domain/valueObject/Amount';

export class Discount {
    private _value: number;

    constructor(value: number) {
        this._ensureIsValid(value);
        this._value = value;
    }

    get value(): number {
        return this._value;
    }

    public format(): string {
        return `${this.value}%`;
    }

    public calculateOriginalAmount(discountedAmount: Amount): Amount {
        if (discountedAmount.value < 0) {
            throw new Error('Discounted amount must be a positive number');
        }
        if (this.value === 100) {
            throw new Error('Discount value cannot be 100% as it leads to division by zero');
        }

        let originalAmount = discountedAmount.value / (1 - this.value / 100);

        originalAmount = Math.round(originalAmount * 100) / 100;

        return new Amount(originalAmount);
    }

    private _ensureIsValid(value: number): void {
        if (value < 0 || value > 100) {
            throw new Error('Invalid discount value');
        }
    }
}
