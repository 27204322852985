import { EPaymentOptionTypeResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentOptionResponse';
import { EPaymentOptionType } from 'app/module/payment/domain/valueObject/PaymentOptionType';

export class PaymentOptionTypeDataMapper {

    public static fromResponse(response: EPaymentOptionTypeResponse): EPaymentOptionType {
        switch( response ){
            case EPaymentOptionTypeResponse.GLOBAL:
                return EPaymentOptionType.GLOBAL;
            case EPaymentOptionTypeResponse.LOCAL:
                return EPaymentOptionType.LOCAL;
            default:
                throw new Error('Invalid payment option type');
        }
    }
}
