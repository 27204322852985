import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCookiesPreferencesFormController } from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/useCookiesPreferencesFormController';
import { ICookiesPreferencesFormData } from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/CookiesPreferencesFormData';
import { SexonToggleSwitchButton } from 'app/common/presentation/component/toggleSwitchButton/SexonToggleSwitchButton';
import { ANALYTICS_COOKIES_DESCRIPTION,TECHNICAL_COOKIES_DESCRIPTION } from 'translations/tr';
import './CookiesPreferencesForm.scss';

interface ICookiesPreferencesProps {
    UseCookiesPreferencesForm: UseFormReturn<ICookiesPreferencesFormData>
}

export const CookiesPreferencesForm = (props: ICookiesPreferencesProps): JSX.Element => {
    const { t } = useTranslation();
    const UseCookiesPreferencesFormController = useCookiesPreferencesFormController(props.UseCookiesPreferencesForm);

    return (
        <form className="component-cookies-preferences-form">
            <SexonToggleSwitchButton
                disabled
                title={t(TECHNICAL_COOKIES_DESCRIPTION)}
                register={UseCookiesPreferencesFormController.technicalRegister}
            />
            <SexonToggleSwitchButton
                title={t(ANALYTICS_COOKIES_DESCRIPTION)}
                register={UseCookiesPreferencesFormController.analyticsRegister}
            />
        </form>
    );
};
