import { IFeedResponse } from 'app/common/data/remote/sexonApi/dto/response/FeedResponse';
import IFeed,{ IFeedItem } from 'app/module/profile/domain/entity/Feed';
import { IGetFeedRequest } from 'app/common/data/remote/sexonApi/dto/request/GetFeedRequest';
import { IFeedFilters } from 'app/module/profile/domain/valueObject/FeedFilters';
import { PostDataMapper } from 'app/module/profile/data/repository/api/post/dataMapper/PostDataMapper';
import { ProfileDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ProfileDataMapper';
import { DaysOfWeekDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/DayOfWeekDataMapper';
import { GenderDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/GenderDataMapper';
import { HairColorDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HairColorDataMapper';
import { HeightDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HeightDataMapper';
import { PaymentMethodDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/PaymentMethodDataMapper';
import { ScheduleDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ScheduleDataMapper';
import { ServiceDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ServiceDataMapper';
import { SexualOrientationDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/SexualOrientationDataMapper';
import { CategoryDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/CategoryDataMapper';

export class GetFeedDataMapper {

    public static fromDomain(
        feedFilters: IFeedFilters,
        page: number,
        limit: number,
        seed?: string
    ): IGetFeedRequest {
        return {
            page,
            limit,
            seed,
            minAge: feedFilters.ageRange[0],
            maxAge: feedFilters.ageRange[1],
            hasWhatsapp: feedFilters.hasWhatsapp,
            hasTelegram: feedFilters.hasTelegram,
            phoneContact: feedFilters.canPhoneContact,
            emailContact: feedFilters.canEmailContact,
            category: CategoryDataMapper.arrayFromDomain(feedFilters.category),
            provinceId: feedFilters.province ? feedFilters.province.id : undefined,
            daysOfWeek: DaysOfWeekDataMapper.arrayFromDomain(feedFilters.availability),
            cityIds: feedFilters.cities ? feedFilters.cities.map(city => city.id) : [],
            paymentMethods: PaymentMethodDataMapper.arrayFromDomain(feedFilters.paymentMethods),
            height: feedFilters.heights ? HeightDataMapper.arrayFromDomain(feedFilters.heights) : [],
            services: feedFilters.services ? ServiceDataMapper.arrayFromDomain(feedFilters.services) : [],
            gender: feedFilters.gender ? GenderDataMapper.fromDomain(feedFilters.gender.value) : undefined,
            timeOfDay: feedFilters.schedules ? ScheduleDataMapper.arrayFromDomain(feedFilters.schedules) : [],
            hairColor: feedFilters.hairColors ? HairColorDataMapper.arrayFromDomain(feedFilters.hairColors) : [],
            nationality: feedFilters.nationalities ? feedFilters.nationalities.map(nationality => nationality.id) : [],
            sexualOrientation: feedFilters.sexualOrientation ?
                SexualOrientationDataMapper.fromDomain(feedFilters.sexualOrientation.value) :
                undefined,
        };
    }

    public static fromResponse(
        response: IFeedResponse,
    ): IFeed {
        const feedItems: IFeedItem[] = [];

        response.feedItems.forEach((item) => {
            feedItems.push({
                profile: ProfileDataMapper.fromResponse(
                    item.profile,
                ),
                posts: PostDataMapper.fromArrayResponse(item.posts),
            });
        });

        return {
            seed: response.seed,
            actualPage: Number(response.page),
            limit: Number(response.limit),
            hasMore: response.feedItems.length >= Number(response.limit),
            items: feedItems
        };
    }
}
