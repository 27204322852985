import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showCookiesPreferencesModal',
    initialState,
    reducers: {
        handleShowCookiesPreferencesModal: (state) => {
            state.showModal = true;
        },
        handleHideCookiesPreferencesModal: (state) => {
            state.showModal = false;
        },
    },
});

export const ShowCookiesPreferencesReducer = slice.reducer;
export const ShowCookiesPreferencesActions = slice.actions;
