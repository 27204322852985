import IProvinceRepository from 'app/module/profile/domain/interface/ProvinceRepository';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import INationality from 'app/module/profile/domain/entity/Nationality';
import { GetProvincesDataMapper } from 'app/module/profile/data/repository/api/province/dataMapper/GetProvincesDataMapper';

export class ApiProvinceRepository implements IProvinceRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async findAll(): Promise<INationality[]> {
        try {
            const provincesData = await this._sexonApiClient.getProvinces();
            return GetProvincesDataMapper.fromResponse(provincesData);
        } catch (error){
            return Promise.reject(error);
        }
    }

}
