import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { AgeConsentActions } from 'app/common/presentation/redux/ageConsent/AgeConsentSlice';

interface ISetAgeConsentUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: ISetAgeConsentUseCaseProps): Promise<void> => {
    try {
        return  await props.storageRepository.setAgeConsent().then((): void =>{
            ReduxStore.dispatch(AgeConsentActions.setHasConsent(true));
        }
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
