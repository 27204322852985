import React from 'react';
import { SexonSpinner } from '../SexonSpinner';
import './InitialLoadingSpinner.scss';

export const InitialLoadingSpinner = (): JSX.Element => {
    return (
        <div className="component-initial-loading-spinner">
            <SexonSpinner />
        </div>
    );
};
