import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { REPORT_DECLARATION_ACCEPTANCE } from 'translations/tr';
import { SexonCheckbox } from 'app/common/presentation/component/checkbox/SexonChexbox';

interface ISuggestionDeclarationAcceptanceFormFieldProps {
    showError: boolean
    errorMessage: string
    register: UseFormRegisterReturn
}

export const ReportDeclarationAcceptanceFormField = (props: ISuggestionDeclarationAcceptanceFormFieldProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <SexonCheckbox
            isRequired
            register={props.register}
            showError={props.showError}
            errorMessage={props.errorMessage}
            label={t(REPORT_DECLARATION_ACCEPTANCE)}
            id="suggestionDeclarationAcceptanceFormField"
        />
    );
};
