import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IProvince from 'app/module/profile/domain/entity/Province';

const initialState = {
    provinces: [] as IProvince[]
};

const slice = createSlice({
    name: 'provinces',
    initialState,
    reducers: {
        setProvinces: (state, action: PayloadAction<IProvince[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.provinces = action.payload;
        },
    },
});

export const ProvinceReducer = slice.reducer;
export const ProvinceActions = slice.actions;
