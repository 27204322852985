import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth } from 'app/module/auth/domain/entity/Auth';

const initialState = {
    auth: {
        token: {
            value: '',
            expirationDate: 0,
            emittedDate: 0,
            id: '',
        },
        refreshToken: '',
    } as IAuth
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<IAuth>) => {
            state.auth = action.payload;
        },
        reset: (state) => {
            state.auth = initialState.auth;
        }
    },
});

export const AuthReducer = slice.reducer;
export const AuthActions = slice.actions;
