import { createSlice } from '@reduxjs/toolkit';
import { EDayOfWeek,IDayOfWeek } from 'app/module/profile/domain/valueObject/DayOfWeek';

const initialState = {
    daysOfWeek: [
        { value: EDayOfWeek.MONDAY },
        { value: EDayOfWeek.TUESDAY },
        { value: EDayOfWeek.WEDNESDAY },
        { value: EDayOfWeek.THURSDAY },
        { value: EDayOfWeek.FRIDAY },
        { value: EDayOfWeek.SATURDAY },
        { value: EDayOfWeek.SUNDAY }
    ] as IDayOfWeek[]
};

const slice = createSlice({
    name: 'daysOfWeek',
    initialState,
    reducers: {},
});

export const DaysOfWeekReducer = slice.reducer;
export const DaysOfWeekActions = slice.actions;
