export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FILTER_SEARCH = 'FILTER_SEARCH';
export const FILTER_MODAL_TITLE = 'FILTER_MODAL_TITLE';
export const FILTER_LABEL = 'FILTER_LABEL';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SELECT_PROVINCE = 'SELECT_PROVINCE';
export const SELECT_CITY = 'SELECT_CITY';
export const NO_RESULTS_TITLE = 'NO_RESULTS_TITLE';
export const NO_RESULTS_MESSAGE = 'NO_RESULTS_MESSAGE';
export const FORGOTTEN_PASSWORD_QUESTION = 'FORGOTTEN_PASSWORD_QUESTION';
export const SIGN_UP_FREE = 'SIGN_UP_FREE';
export const EMAIL_LABEL = 'EMAIL_LABEL';
export const PASSWORD_LABEL = 'PASSWORD_LABEL';
export const NEW_PASSWORD_LABEL = 'NEW_PASSWORD_LABEL';
export const THIS_FIELD_IS_REQUIRED_ERROR = 'THIS_FIELD_IS_REQUIRED_ERROR';
export const ENTER_A_VALID_EMAIL_ERROR = 'ENTER_A_VALID_EMAIL_ERROR';
export const HOME = 'HOME';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_TEXT = 'SIGN_UP_TEXT';
export const SIGN_UP_SUCCESSFUL_MESSAGE = 'SIGN_UP_SUCCESSFUL_MESSAGE';
export const PROFILE = 'PROFILE';
export const SUGGESTIONS = 'SUGGESTIONS';
export const YEARS = 'YEARS';
export const SPANISH_LABEL = 'SPANISH_LABEL';
export const ENGLISH_LABEL = 'ENGLISH_LABEL';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const POST = 'POST';
export const CREATE_PROFILE_INFO_TEXT = 'CREATE_PROFILE_INFO_TEXT';
export const CANCEL = 'CANCEL';
export const NEXT = 'NEXT';
export const NAME = 'NAME';
export const USER_NAME = 'USER_NAME';
export const BIRTHDATE = 'BIRTHDATE';
export const NATIONALITY = 'NATIONALITY';
export const DESCRIPTION = 'DESCRIPTION';
export const NAUGHTY_PHRASE = 'NAUGHTY_PHRASE';
export const PROVINCE = 'PROVINCE';
export const CITY = 'CITY';
export const BACK = 'BACK';
export const ACCEPT = 'ACCEPT';
export const SERVICES = 'SERVICES';
export const SCHEDULE = 'SCHEDULE';
export const PROFILE_PRICE = 'PROFILE_PRICE';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const CONTACT_EMAIL = 'CONTACT_EMAIL';
export const CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER';
export const SEND = 'SEND';
export const PROFILE_AVAILABILITY = 'PROFILE_AVAILABILITY';
export const ADD_NAUGHTY_PHRASE = 'ADD_NAUGHTY_PHRASE';
export const DESCRIBE_YOURSELF = 'DESCRIBE_YOURSELF';
export const DESCRIBE_YOURSELF_INFO_INPUT = 'DESCRIBE_YOURSELF_INFO_INPUT';
export const PROFILE_SERVICES = 'PROFILE_SERVICES';
export const WHATSHAPP_AVAILABLE_QUESTION = 'WHATSHAPP_AVAILABLE_QUESTION';
export const TELEGRAM_AVAILABLE_QUESTION = 'TELEGRAM_AVAILABLE_QUESTION';
export const PHONE_AVAILABLE_QUESTION = 'PHONE_AVAILABLE_QUESTION';
export const EMAIL_AVAILABLE_QUESTION = 'EMAIL_AVAILABLE_QUESTION';
export const HEIGHT = 'HEIGHT';
export const HAIR_COLOR = 'HAIR_COLOR';
export const ADD_PROFILE_IMAGE = 'ADD_PROFILE_IMAGE';
export const MONDAY_LABEL = 'MONDAY_LABEL';
export const TUESDAY_LABEL = 'TUESDAY_LABEL';
export const WEDNESDAY_LABEL = 'WEDNESDAY_LABEL';
export const THURSDAY_LABEL = 'THURSDAY_LABEL';
export const FRIDAY_LABEL = 'FRIDAY_LABEL';
export const SATURDAY_LABEL = 'SATURDAY_LABEL';
export const SUNDAY_LABEL = 'SUNDAY_LABEL';
export const FULL_WEEK_LABEL = 'FULL_WEEK_LABEL';
export const LESS_THAN_160_LABEL = 'LESS_THAN_160_LABEL';
export const BETWEEN_160_AND_170_LABEL = 'BETWEEN_160_AND_170_LABEL';
export const MORE_THAN_170_LABEL = 'MORE_THAN_170_LABEL';
export const BRUNETTE_LABEL = 'BRUNETTE_LABEL';
export const BROWN_HAIRED_LABEL = 'BROWN_HAIRED_LABEL';
export const BLONDE_LABEL = 'BLONDE_LABEL';
export const REDHEAD_LABEL = 'REDHEAD_LABEL';
export const FROM = 'FROM';
export const UNTIL = 'UNTIL';
export const CASH_LABEL = 'CASH_LABEL';
export const BIZUM_LABEL = 'BIZUM_LABEL';
export const CARD_LABEL = 'CARD_LABEL';
export const PAYPAL_LABEL = 'PAYPAL_LABEL';
export const ERROR_PROFILE_USERNAME_EXIST = 'ERROR_PROFILE_USERNAME_EXIST';
export const AGE_RESTRICTION = 'AGE_RESTRICTION';
export const FULL_DAY_LABEL = 'FULL_DAY_LABEL';
export const MORNING_AND_AFTERNOON_LABEL = 'MORNING_AND_AFTERNOON_LABEL';
export const AFTERNOON_AND_EVENING_LABEL = 'AFTERNOON_AND_EVENING_LABEL';
export const ONLY_MORNING_LABEL = 'ONLY_MORNING_LABEL';
export const ONLY_AFTERNOON_LABEL = 'ONLY_AFTERNOON_LABEL';
export const ONLY_EVENING_LABEL = 'ONLY_EVENING_LABEL';
export const ERROR_PROFILE_MINIMUM_FEE_INVALID = 'ERROR_PROFILE_MINIMUM_FEE_INVALID';
export const ERROR_PROFILE_MINIMUM_FEE_GREATER_THAN_0 = 'ERROR_PROFILE_MINIMUM_FEE_GREATER_THAN_0';
export const CRYPTOCURRENCIES_LABEL = 'CRYPTOCURRENCIES_LABEL';
export const IMAGE_TYPE_ERROR = 'IMAGE_TYPE_ERROR';
export const SELECT_NATIONALITY = 'SELECT_NATIONALITY';
export const USER_NAME_INFO_INPUT = 'USER_NAME_INFO_INPUT';
export const NICKNAME_INFO_INPUT = 'NICKNAME_INFO_INPUT';
export const NAUGHTY_PHRASE_INFO_INPUT = 'NAUGHTY_PHRASE_INFO_INPUT';
export const AGE_INFO_INPUT = 'AGE_INFO_INPUT';
export const MINIMUM_RATE_INFO_INPUT = 'MINIMUM_RATE_INFO_INPUT';
export const PAYMENT_METHODS_INFO_INPUT = 'PAYMENT_METHODS_INFO_INPUT';
export const CONTACT_PHONE_INFO_INPUT = 'CONTACT_PHONE_INFO_INPUT';
export const INFORMATION_LABEL = 'INFORMATION_LABEL';
export const ERROR_LABEL = 'ERROR_LABEL';
export const SUCCESS_LABEL = 'SUCCESS_LABEL';
export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS';
export const REFRESH_TOKEN_NOT_FOUND = 'REFRESH_TOKEN_NOT_FOUND';
export const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
export const AUTH_INVALID_CREDENTIALS = 'AUTH_INVALID_CREDENTIALS';
export const EMPTY_PASSWORD_NOT_ALLOWED = 'EMPTY_PASSWORD_NOT_ALLOWED';
export const AT_LEAST_ONE_POST_REQUIRED = 'AT_LEAST_ONE_POST_REQUIRED';
export const FILE_TOO_LARGE = 'FILE_TOO_LARGE';
export const UNSUPPORTED_MIME_TYPE = 'UNSUPPORTED_MIME_TYPE';
export const POST_NOT_FOUND = 'POST_NOT_FOUND';
export const IMAGE_TOO_SMALL = 'IMAGE_TOO_SMALL';
export const POST_DOESNT_BELONG_TO_PROFILE = 'POST_DOESNT_BELONG_TO_PROFILE';
export const POST_ALREADY_EXISTS = 'POST_ALREADY_EXISTS';
export const UNEXPECTED_EMPTY_VALUE = 'UNEXPECTED_EMPTY_VALUE';
export const PROFILE_ALREADY_EXISTS = 'PROFILE_ALREADY_EXISTS';
export const PROFILE_DISABLED = 'PROFILE_DISABLED';
export const PROFILE_DOESNT_BELONG_TO_USER = 'PROFILE_DOESNT_BELONG_TO_USER';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const PROFILE_USERNAME_TAKEN = 'PROFILE_USERNAME_TAKEN';
export const INVALID_PHONE = 'INVALID_PHONE';
export const INVALID_ULID = 'INVALID_ULID';
export const SUBSCRIPTION_OPTION_NOT_FOUND = 'SUBSCRIPTION_OPTION_NOT_FOUND';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const AUTH_VALIDATE_EMAIL = 'AUTH_VALIDATE_EMAIL';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const GENERIC_API_ERROR = 'GENERIC_API_ERROR';
export const FEED_VISIT_MY_PROFILE = 'FEED_VISIT_MY_PROFILE';
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const CREATE_POST = 'CREATE_POST';
export const DESCRIBE_POST = 'DESCRIBE_POST';
export const DESCRIPTION_FORM_FIELD_PLACEHOLDER = 'DESCRIPTION_FORM_FIELD_PLACEHOLDER';
export const PUBLISH = 'PUBLISH';
export const CREATE_POST_SUCCESSFUL_MESSAGE = 'CREATE_POST_SUCCESSFUL_MESSAGE';
export const POST_PRICE = 'POST_PRICE';
export const DELETE = 'DELETE';
export const REPORT = 'REPORT';
export const CONFIRM = 'CONFIRM';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_INFO = 'DELETE_POST_INFO';
export const ERROR_CREATE_POST_IMAGE_REQUIRED = 'ERROR_CREATE_POST_IMAGE_REQUIRED';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const FORGOTTEN_PASSWORD_TITLE = 'FORGOTTEN_PASSWORD_TITLE';
export const FORGOTTEN_PASSWORD_INSTRUCTIONS = 'FORGOTTEN_PASSWORD_INSTRUCTIONS';
export const FORGOTTEN_PASSWORD_INVALID_CREDENTIALS = 'FORGOTTEN_PASSWORD_INVALID_CREDENTIALS';
export const FORGOTTEN_PASSWORD_SUCCESSFUL_MESSAGE = 'FORGOTTEN_PASSWORD_SUCCESSFUL_MESSAGE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESSFUL_MESSAGE = 'RESET_PASSWORD_SUCCESSFUL_MESSAGE';
export const PASSWORDS_DO_NOT_MATCH_ERROR = 'PASSWORDS_DO_NOT_MATCH_ERROR';
export const REPEAT_PASSWORD = 'REPEAT_PASSWORD';
export const VERIFY_ACCOUNT_INFO_TITLE = 'VERIFY_ACCOUNT_INFO_TITLE';
export const VERIFY_ACCOUNT_INFO_P1 = 'VERIFY_ACCOUNT_INFO_P1';
export const VERIFY_ACCOUNT_INFO_P2_TITLE = 'VERIFY_ACCOUNT_INFO_P2_TITLE';
export const VERIFY_ACCOUNT_INFO_P2 = 'VERIFY_ACCOUNT_INFO_P2';
export const VERIFY_ACCOUNT_INFO_P3_TITLE = 'VERIFY_ACCOUNT_INFO_P3_TITLE';
export const VERIFY_ACCOUNT_INFO_P3 = 'VERIFY_ACCOUNT_INFO_P3';
export const VERIFY_ACCOUNT_INFO_P4 = 'VERIFY_ACCOUNT_INFO_P4';
export const VERIFY_ACCOUNT_INFO_P5 = 'VERIFY_ACCOUNT_INFO_P5';
export const CONTINUE_AS_A_GUEST = 'CONTINUE_AS_A_GUEST';
export const POSTS_PROFILE = 'POSTS_PROFILE';
export const PROFILE_GALLERY = 'PROFILE_GALLERY';
export const PROFILE_ABOUT_ME = 'PROFILE_ABOUT_ME';
export const AVAILABILITY = 'AVAILABILITY';
export const AGE = 'AGE';
export const CONTACT = 'CONTACT';
export const GENDER_FIELD_TITLE = 'GENDER_FIELD_TITLE';
export const WOMAN_LABEL = 'WOMAN_LABEL';
export const MAN_LABEL = 'MAN_LABEL';
export const COUPLE_LABEL = 'COUPLE_LABEL';
export const TRANSGENDER_LABEL = 'TRANSGENDER_LABEL';
export const SEXUAL_ORIENTATION_FIELD_TITLE = 'SEXUAL_ORIENTATION_FIELD_TITLE';
export const HETEROSEXUAL_LABEL = 'HETEROSEXUAL_LABEL';
export const HOMOSEXUAL_LABEL = 'HOMOSEXUAL_LABEL';
export const BISEXUAL_LABEL = 'BISEXUAL_LABEL';
export const SELECT_ANOTHER_IMAGE = 'SELECT_ANOTHER_IMAGE';
export const NO_POSTS_MESSAGE = 'NO_POSTS_MESSAGE';
export const SETTING_MODAL_TITLE = 'SETTING_MODAL_TITLE';
export const CLOSE = 'CLOSE';
export const EDIT_BASIC_INFORMATION = 'EDIT_BASIC_INFORMATION';
export const EDIT_ADDITIONAL_INFORMATION = 'EDIT_ADDITIONAL_INFORMATION';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const HIDE_PROFILE = 'HIDE_PROFILE';
export const SHOW_PROFILE = 'SHOW_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SAVE = 'SAVE';
export const EDIT_PROFILE_SUCCESSFUL_MESSAGE = 'EDIT_PROFILE_SUCCESSFUL_MESSAGE';
export const ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION';
export const VIEW_MORE = 'VIEW_MORE';
export const VIEW_LESS = 'VIEW_LESS';
export const REGISTER_MODAL_REGISTRATION_TITLE = 'REGISTER_MODAL_REGISTRATION_TITLE';
export const REGISTER_MODAL_REGISTRATION_TEXT = 'REGISTER_MODAL_REGISTRATION_TEXT';
export const REGISTER_MODAL_CREATE_PROFILE_TITLE = 'REGISTER_MODAL_CREATE_PROFILE_TITLE';
export const REGISTER_MODAL_CREATE_PROFILE_TEXT = 'REGISTER_MODAL_CREATE_PROFILE_TEXT';
export const COOKIES_CONSENT_MODAL_TITLE = 'COOKIES_CONSENT_MODAL_TITLE';
export const COOKIES_CONSENT_MODAL_TEXT = 'COOKIES_CONSENT_MODAL_TEXT';
export const COOKIES_PREFERENCES_MODAL_TITLE = 'COOKIES_PREFERENCES_MODAL_TITLE';
export const AGE_CONSENT_MODAL_TITLE = 'AGE_CONSENT_MODAL_TITLE';
export const AGE_CONSENT_MODAL_TEXT = 'AGE_CONSENT_MODAL_TEXT';
export const EXIT = 'EXIT';
export const LEGAL_NOTICE = 'LEGAL_NOTICE';
export const TERMS_OF_CONTRACT = 'TERMS_OF_CONTRACT';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const COOKIE_POLICY = 'COOKIE_POLICY';
export const REPORT_MODAL_TITLE = 'REPORT_MODAL_TITLE';
export const REPORT_MODAL_TEXT_P1 = 'REPORT_MODAL_TEXT_P1';
export const REPORT_MODAL_TEXT_P2 = 'REPORT_MODAL_TEXT_P2';
export const REPORT_MODAL_TEXT_P3 = 'REPORT_MODAL_TEXT_P3';
export const REPORT_MODAL_TEXT_P4 = 'REPORT_MODAL_TEXT_P4';
export const REPORT_MODAL_DESCRIPTION_TEXT = 'REPORT_MODAL_DESCRIPTION_TEXT';
export const SEXUAL_OFFENSES_LABEL = 'SEXUAL_OFFENSES_LABEL';
export const OTHER_LABEL = 'OTHER_LABEL';
export const REPORT_SUCCESSFUL_MESSAGE = 'REPORT_SUCCESSFUL_MESSAGE';
export const BLOG = 'BLOG';
export const SHARE_TITLE = 'SHARE_TITLE';
export const SHARE_MESSAGE = 'SHARE_MESSAGE';
export const COPY_TO_CLIPBOARD_MESSAGE = 'COPY_TO_CLIPBOARD_MESSAGE';
export const SHARE = 'SHARE';
export const ASPECT_ORIGINAL = 'ASPECT_ORIGINAL';
export const ASPECT_1 = 'ASPECT_1';
export const ASPECT_2 = 'ASPECT_2';
export const ASPECT_3 = 'ASPECT_3';
export const DELETE_ACCOUNT_INFO = 'DELETE_ACCOUNT_INFO';
export const DELETE_ACCOUNT_CONFIRMATION_TEXT = 'DELETE_ACCOUNT_CONFIRMATION_TEXT';
export const DELETE_ACCOUNT_CONFIRMATION_MESSAGE_INPUT = 'DELETE_ACCOUNT_CONFIRMATION_MESSAGE_INPUT';
export const DELETE_ACCOUNT_ERROR_MESSAGE = 'DELETE_ACCOUNT_ERROR_MESSAGE';
export const DELETE_ACCOUNT_SUCCESSFUL_MESSAGE = 'DELETE_ACCOUNT_SUCCESSFUL_MESSAGE';
export const HIDE_PROFILE_INFO = 'HIDE_PROFILE_INFO';
export const HIDE_PROFILE_SUCCESSFUL_MESSAGE = 'HIDE_PROFILE_SUCCESSFUL_MESSAGE';
export const SHOW_PROFILE_SUCCESSFUL_MESSAGE = 'SHOW_PROFILE_SUCCESSFUL_MESSAGE';
export const LATER = 'LATER';
export const COMPLETE_PROFILE = 'COMPLETE_PROFILE';
export const COMPLETE_PROFILE_TITLE = 'COMPLETE_PROFILE_TITLE';
export const COMPLETE_PROFILE_INSTRUCTIONS = 'COMPLETE_PROFILE_INSTRUCTIONS';
export const SAVE_PROFILE_TITLE = 'SAVE_PROFILE_TITLE';
export const SAVE_PROFILE_INSTRUCTIONS = 'SAVE_PROFILE_INSTRUCTIONS';
export const CREATE_POST_MODAL_TITLE = 'CREATE_POST_MODAL_TITLE';
export const CREATE_POST_MODAL_INSTRUCTIONS = 'CREATE_POST_MODAL_INSTRUCTIONS';
export const ENTER_A_VALID_PASSWORD_ERROR = 'ENTER_A_VALID_PASSWORD_ERROR';
export const ENTER_A_VALID_USERNAME_ERROR = 'ENTER_A_VALID_USERNAME_ERROR';
export const ENTER_A_VALID_PROFILE_NAME_ERROR = 'ENTER_A_VALID_PROFILE_NAME_ERROR';
export const YOUR_PROFILE_IS_DISABLED = 'YOUR_PROFILE_IS_DISABLED';
export const SUGGESTIONS_MODAL_TITLE = 'SUGGESTIONS_MODAL_TITLE';
export const SUGGESTIONS_MODAL_INSTRUCTIONS = 'SUGGESTIONS_MODAL_INSTRUCTIONS';
export const SUGGESTIONS_MODAL_CONTACT_INSTRUCTIONS = 'SUGGESTIONS_MODAL_CONTACT_INSTRUCTIONS';
export const SUGGESTIONS_MODAL_DESCRIPTION_TEXT = 'SUGGESTIONS_MODAL_DESCRIPTION_TEXT';
export const SUGGESTIONS_SUCCESSFUL_MESSAGE = 'SUGGESTIONS_SUCCESSFUL_MESSAGE';
export const EMAIL_CONTACT_SUBJECT = 'EMAIL_CONTACT_SUBJECT';
export const EMAIL_CONTACT_TEXT = 'EMAIL_CONTACT_TEXT';
export const WHATSHAPP_CONTACT_TEXT = 'WHATSHAPP_CONTACT_TEXT';
export const PHONE_MODAL_CONTACT_TEXT = 'PHONE_MODAL_CONTACT_TEXT';
export const EMAIL_MODAL_CONTACT_TEXT = 'EMAIL_MODAL_CONTACT_TEXT';
export const TELEGRAM_MODAL_CONTACT_TEXT = 'TELEGRAM_MODAL_CONTACT_TEXT';
export const WHATSHAPP_MODAL_CONTACT_TEXT = 'WHATSHAPP_MODAL_CONTACT_TEXT';
export const CALL = 'CALL';
export const CONTACT_BY_EMAIL = 'CONTACT_BY_EMAIL';
export const TELEGRAM_USER = 'TELEGRAM_USER';
export const CONTACT_BY_TELEGRAM = 'CONTACT_BY_TELEGRAM';
export const CONTACT_BY_WHATSHAPP = 'CONTACT_BY_WHATSHAPP';
export const WHATSHAPP_NUMBER = 'WHATSHAPP_NUMBER';
export const FILTER_GENDER_LABEL = 'FILTER_GENDER_LABEL';
export const FILTER_SERVICES_LABEL = 'FILTER_SERVICES_LABEL';
export const AGE_RANGE = 'AGE_RANGE';
export const MINIMUM = 'MINIMUM';
export const MAXIMUM = 'MAXIMUM';
export const ADVANCED_SEARCH = 'ADVANCED_SEARCH';
export const CONTACTS_METHODS = 'CONTACTS_METHODS';
export const WHATSHAPP_FILTER_QUESTION = 'WHATSHAPP_FILTER_QUESTION';
export const TELEGRAM_FILTER_QUESTION = 'TELEGRAM_FILTER_QUESTION';
export const PHONE_FILTER_QUESTION = 'PHONE_FILTER_QUESTION';
export const EMAIL_FILTER_QUESTION = 'EMAIL_FILTER_QUESTION';
export const EMAIL_TEXT_INFO = 'EMAIL_TEXT_INFO';
export const SELECT_MAX_NO_OPTIONS = 'SELECT_MAX_NO_OPTIONS';
export const SERVICES_MAX_LIMIT = 'SERVICES_MAX_LIMIT';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_EMAIL_INFO = 'EDIT_EMAIL_INFO';
export const EDIT_EMAIL_FORM_TITLE = 'EDIT_EMAIL_FORM_TITLE';
export const EDIT_EMAIL_INSTRUCTIONS = 'EDIT_EMAIL_INSTRUCTIONS';
export const VALIDATE_EMAIL_CHANGE = 'VALIDATE_EMAIL_CHANGE';
export const VALIDATE_EMAIL_CHANGE_SUCCESSFUL_MESSAGE = 'VALIDATE_EMAIL_CHANGE_SUCCESSFUL_MESSAGE';
export const BASIC_INFO_PROFILE_ERROR = 'BASIC_INFO_PROFILE_ERROR';
export const TELEGRAM_USERNAME_INFO_INPUT = 'TELEGRAM_USERNAME_INFO_INPUT';
export const ENTER_A_VALID_TELEGRAM_USERNAME_ERROR = 'ENTER_A_VALID_TELEGRAM_USERNAME_ERROR';
export const EXTERNAL_LINK = 'EXTERNAL_LINK';
export const EXTERNAL_LINK_INFO_INPUT = 'EXTERNAL_LINK_INFO_INPUT';
export const ENTER_A_VALID_EXTERNAL_LINK_ERROR = 'ENTER_A_VALID_EXTERNAL_LINK_ERROR';
export const MY_SOCIAL_NETWORKS = 'MY_SOCIAL_NETWORKS';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const ZOOM = 'ZOOM';
export const ROTATION = 'ROTATION';
export const CONTACT_METHOD_ERROR = 'CONTACT_METHOD_ERROR';
export const LOADING_IMAGE = 'LOADING_IMAGE';
export const PROCESSING_IMAGE_INFO = 'PROCESSING_IMAGE_INFO';
export const CONTENT_SALE_LABEL = 'CONTENT_SALE_LABEL';
export const SEXTING_LABEL = 'SEXTING_LABEL';
export const VIDEO_CALLS_LABEL = 'VIDEO_CALLS_LABEL';
export const ADULT_ACTOR_ACTRESS_LABEL = 'ADULT_ACTOR_ACTRESS_LABEL';
export const STRIPTEASE_LABEL = 'STRIPTEASE_LABEL';
export const DICK_RATE_LABEL = 'DICK_RATE_LABEL';
export const VOYEURISM_LABEL = 'VOYEURISM_LABEL';
export const VIRTUAL_PARTNER_LABEL = 'VIRTUAL_PARTNER_LABEL';
export const DOMINATION_LABEL = 'DOMINATION_LABEL';
export const FINANCIAL_DOMINATION_LABEL = 'FINANCIAL_DOMINATION_LABEL';
export const VIRTUAL_DOMINATRIX_LABEL = 'VIRTUAL_DOMINATRIX_LABEL';
export const FETISHES_GENERAL_LABEL = 'FETISHES_GENERAL_LABEL';
export const FOOT_FETISH_LABEL = 'FOOT_FETISH_LABEL';
export const CONTENT_EDITING_PRODUCTION_LABEL = 'CONTENT_EDITING_PRODUCTION_LABEL';
export const MARKETING_PROMOTION_LABEL = 'MARKETING_PROMOTION_LABEL';
export const SEXUAL_HEALTH_WELLNESS_LABEL = 'SEXUAL_HEALTH_WELLNESS_LABEL';
export const EROTIC_MODEL_LABEL = 'EROTIC_MODEL_LABEL';
export const SEXUAL_EVENTS_ORGANIZER_LABEL = 'SEXUAL_EVENTS_ORGANIZER_LABEL';
export const SEXUAL_WORKSHOP_ORGANIZER_LABEL = 'SEXUAL_WORKSHOP_ORGANIZER_LABEL';
export const COUPLES_THERAPY_SEXUALITY_LABEL = 'COUPLES_THERAPY_SEXUALITY_LABEL';
export const SEXUAL_EDUCATOR_LABEL = 'SEXUAL_EDUCATOR_LABEL';
export const EROTIC_PHOTOGRAPHER_LABEL = 'EROTIC_PHOTOGRAPHER_LABEL';
export const CONTENT_SALE_DESCRIPTION = 'CONTENT_SALE_DESCRIPTION';
export const SEXTING_DESCRIPTION = 'SEXTING_DESCRIPTION';
export const VIDEO_CALLS_DESCRIPTION = 'VIDEO_CALLS_DESCRIPTION';
export const ADULT_ACTOR_ACTRESS_DESCRIPTION = 'ADULT_ACTOR_ACTRESS_DESCRIPTION';
export const STRIPTEASE_DESCRIPTION = 'STRIPTEASE_DESCRIPTION';
export const DICK_RATE_DESCRIPTION = 'DICK_RATE_DESCRIPTION';
export const VOYEURISM_DESCRIPTION = 'VOYEURISM_DESCRIPTION';
export const VIRTUAL_PARTNER_DESCRIPTION = 'VIRTUAL_PARTNER_DESCRIPTION';
export const DOMINATION_DESCRIPTION = 'DOMINATION_DESCRIPTION';
export const FINANCIAL_DOMINATION_DESCRIPTION = 'FINANCIAL_DOMINATION_DESCRIPTION';
export const VIRTUAL_DOMINATRIX_DESCRIPTION = 'VIRTUAL_DOMINATRIX_DESCRIPTION';
export const FETISHES_GENERAL_DESCRIPTION = 'FETISHES_GENERAL_DESCRIPTION';
export const FOOT_FETISH_DESCRIPTION = 'FOOT_FETISH_DESCRIPTION';
export const CONTENT_EDITING_PRODUCTION_DESCRIPTION = 'CONTENT_EDITING_PRODUCTION_DESCRIPTION';
export const MARKETING_PROMOTION_DESCRIPTION = 'MARKETING_PROMOTION_DESCRIPTION';
export const SEXUAL_HEALTH_WELLNESS_DESCRIPTION = 'SEXUAL_HEALTH_WELLNESS_DESCRIPTION';
export const EROTIC_MODEL_DESCRIPTION = 'EROTIC_MODEL_DESCRIPTION';
export const SEXUAL_EVENTS_ORGANIZER_DESCRIPTION = 'SEXUAL_EVENTS_ORGANIZER_DESCRIPTION';
export const SEXUAL_WORKSHOP_ORGANIZER_DESCRIPTION = 'SEXUAL_WORKSHOP_ORGANIZER_DESCRIPTION';
export const COUPLES_THERAPY_SEXUALITY_DESCRIPTION = 'COUPLES_THERAPY_SEXUALITY_DESCRIPTION';
export const SEXUAL_EDUCATOR_DESCRIPTION = 'SEXUAL_EDUCATOR_DESCRIPTION';
export const EROTIC_PHOTOGRAPHER_DESCRIPTION = 'EROTIC_PHOTOGRAPHER_DESCRIPTION';
export const HIGHLIGHT_YOUR_PROFILE = 'HIGHLIGHT_YOUR_PROFILE';
export const HIGHLIGHT_YOUR_PROFILE_OPTION_INFO = 'HIGHLIGHT_YOUR_PROFILE_OPTION_INFO';
export const HIGHLIGHT_YOUR_PROFILE_PRICE = 'HIGHLIGHT_YOUR_PROFILE_PRICE';
export const HIGHLIGHT_YOUR_PROFILE_OPTIONS_VALUES_INSTRUCTIONS = 'HIGHLIGHT_YOUR_PROFILE_OPTIONS_VALUES_INSTRUCTIONS';
export const HIGHLIGHT_YOUR_PROFILE_CARD_INSTRUCTIONS = 'HIGHLIGHT_YOUR_PROFILE_CARD_INSTRUCTIONS';
export const MAKE_A_PURCHASE = 'MAKE_A_PURCHASE';
export const SUMMARY_OF_YOUR_PURCHASE = 'SUMMARY_OF_YOUR_PURCHASE';
export const TOTAL_SUMMARY_OF_YOUR_PURCHASE = 'TOTAL_SUMMARY_OF_YOUR_PURCHASE';
export const DATES_SUMMARY = 'DATES_SUMMARY';
export const PAYMENT = 'PAYMENT';
export const PAY_WITH_CREDIT_CARD = 'PAY_WITH_CREDIT_CARD';
export const FORBIDDEN_ACCESS_UNDER_18 = 'FORBIDDEN_ACCESS_UNDER_18';
export const RENEWAL_REMINDER_TITLE = 'RENEWAL_REMINDER_TITLE';
export const RENEWAL_REMINDER_INFO = 'RENEWAL_REMINDER_INFO';
export const PAYMENT_SUMMARY_DATE_INFO = 'PAYMENT_SUMMARY_DATE_INFO';
export const HIGHLIGHT = 'HIGHLIGHT';
export const EDIT_PHONE = 'EDIT_PHONE';
export const EDIT_PHONE_INFO = 'EDIT_PHONE_INFO';
export const EDIT_PHONE_FORM_TITLE = 'EDIT_PHONE_FORM_TITLE';
export const VALIDATE_PHONE_MODAL_INSTRUCTIONS = 'VALIDATE_PHONE_MODAL_INSTRUCTIONS';
export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const VERIFICATION_CODE = 'VERIFICATION_CODE';
export const AUTH_VALIDATE_SMS = 'AUTH_VALIDATE_SMS';
export const AUTH_INVALID_SMS_TOKEN = 'AUTH_INVALID_SMS_TOKEN';
export const ENTER_A_VALID_PHONE_ERROR = 'ENTER_A_VALID_PHONE_ERROR';
export const VALIDATE_PHONE_INFO = 'VALIDATE_PHONE_INFO';
export const VALIDATE_PHONE_SUCCESSFUL_MESSAGE = 'VALIDATE_PHONE_SUCCESSFUL_MESSAGE';
export const GLOBAL_HIGHLIGHTED_INFO = 'GLOBAL_HIGHLIGHTED_INFO';
export const LOCAL_HIGHLIGHTED_INFO = 'LOCAL_HIGHLIGHTED_INFO';
export const MORE_INFO = 'MORE_INFO';
export const HIDE_INFO = 'HIDE_INFO';
export const HIGHLIGHT_MORE_INFO_TITLE = 'HIGHLIGHT_MORE_INFO_TITLE';
export const HIGHLIGHT_MORE_INFO = 'HIGHLIGHT_MORE_INFO';
export const INCOMPATIBLE = 'INCOMPATIBLE';
export const SUCCESSFUL_PAYMENT = 'SUCCESSFUL_PAYMENT';
export const SUCCESSFUL_PAYMENT_TITLE = 'SUCCESSFUL_PAYMENT_TITLE';
export const SUCCESSFUL_PAYMENT_INFO = 'SUCCESSFUL_PAYMENT_INFO';
export const FAILED_PAYMENT = 'FAILED_PAYMENT';
export const FAILED_PAYMENT_TITLE = 'FAILED_PAYMENT_TITLE';
export const FAILED_PAYMENT_INFO = 'FAILED_PAYMENT_INFO';
export const DENY = 'DENY';
export const VIEW_PREFERENCES = 'VIEW_PREFERENCES';
export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const TECHNICAL_COOKIES_DESCRIPTION = 'TECHNICAL_COOKIES_DESCRIPTION';
export const ANALYTICS_COOKIES_DESCRIPTION = 'ANALYTICS_COOKIES_DESCRIPTION';
export const COOKIES_PREFERENCES = 'COOKIES_PREFERENCES';
export const CLICK_HERE = 'CLICK_HERE';
export const LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE = 'LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE';
export const TERMS_OF_CONTRACT_ACCEPTANCE = 'TERMS_OF_CONTRACT_ACCEPTANCE';
export const SEXUAL_DATA_ACCEPTANCE = 'SEXUAL_DATA_ACCEPTANCE';
export const REPORT_DECLARATION_ACCEPTANCE = 'REPORT_DECLARATION_ACCEPTANCE';
export const HELP = 'HELP';
export const WHAT_IS_SEXON = 'WHAT_IS_SEXON';
export const HOW_TO_CREATE_ACCOUNT_PROFILE = 'HOW_TO_CREATE_ACCOUNT_PROFILE';
export const HOW_TO_CREATE_POSTS = 'HOW_TO_CREATE_POSTS';
export const HOW_TO_EDIT_PROFILE = 'HOW_TO_EDIT_PROFILE';
export const PROFILE_VISIBILITY = 'PROFILE_VISIBILITY';
export const HOW_TO_BOOST_PROFILE = 'HOW_TO_BOOST_PROFILE';
export const POST_TIPS = 'POST_TIPS';
export const HOW_TO_CHANGE_RECOVER_PASSWORD = 'HOW_TO_CHANGE_RECOVER_PASSWORD';
export const HOW_TO_HIDE_PROFILE = 'HOW_TO_HIDE_PROFILE';
export const HOW_TO_DELETE_PROFILE = 'HOW_TO_DELETE_PROFILE';
export const HELP_SECTION_MODAL_TITLE = 'HELP_SECTION_MODAL_TITLE';
export const HELP_CENTER = 'HELP_CENTER';
export const PARENTAL_CONTROL = 'PARENTAL_CONTROL';
export const HOW_TO_DELETE_POST = 'HOW_TO_DELETE_POST';
export const FULL_NAME = 'FULL_NAME';
export const COMMERCIAL_CONSENT = 'COMMERCIAL_CONSENT';
export const FILTER_CATEGORY_FIELD_TITLE = 'FILTER_CATEGORY_FIELD_TITLE';
export const PROFILE_CATEGORY_FIELD_TITLE = 'PROFILE_CATEGORY_FIELD_TITLE';
export const PROFILE_CATEGORY_FIELD_PLACEHOLDER = 'PROFILE_CATEGORY_FIELD_PLACEHOLDER';
export const HETEROSEXUAL_MEN_LABEL = 'HETEROSEXUAL_MEN_LABEL';
export const HETEROSEXUAL_WOMEN_LABEL = 'HETEROSEXUAL_WOMEN_LABEL';
export const HOMESEXUAL_MEN_LABEL = 'HOMESEXUAL_MEN_LABEL';
export const HOMESEXUAL_WOMEN_LABEL = 'HOMESEXUAL_WOMEN_LABEL';
export const FOR_HETEROSEXUAL_MEN_LABEL = 'FOR_HETEROSEXUAL_MEN_LABEL';
export const FOR_HETEROSEXUAL_WOMEN_LABEL = 'FOR_HETEROSEXUAL_WOMEN_LABEL';
export const FOR_HOMESEXUAL_MEN_LABEL = 'FOR_HOMESEXUAL_MEN_LABEL';
export const FOR_HOMESEXUAL_WOMEN_LABEL = 'FOR_HOMESEXUAL_WOMEN_LABEL';
export const CURRENCY_SYMBOL_ERROR = 'CURRENCY_SYMBOL_ERROR';
export const IMAGE_REQUIRED_ERROR = 'IMAGE_REQUIRED_ERROR';
export const PROFILE_HIGHLIGHTED = 'PROFILE_HIGHLIGHTED';
export const INFO_POST_INSTRUCTIONS = 'INFO_POST_INSTRUCTIONS';
export const HIGHLIGHTED = 'HIGHLIGHTED';
export const CONFIRMATION_LOGOUT_MODAL_TITLE = 'CONFIRMATION_LOGOUT_MODAL_TITLE';
export const CONFIRMATION_LOGOUT_MODAL_ADVICE = 'CONFIRMATION_LOGOUT_MODAL_ADVICE';
