export interface IPost {
    id: string
    image: string
    likes: number
    publishDate: Date
    smallImage: string
    description: string
    status: EPostStatus
}

export enum EPostStatus {
    READY= 'READY',
    PROCESSING = 'PROCESSING'
}
