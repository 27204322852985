import IAuthRepository from 'app/module/auth/domain/interface/AuthRepository';
import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { AuthActions } from 'app/common/presentation/redux/auth/AuthSlice';
import { IAuth,tokenIsValid } from 'app/module/auth/domain/entity/Auth';

interface IGetAuthUseCaseProps {
    storageRepository: IStorageRepository
    authRepository: IAuthRepository
}

export default async (props: IGetAuthUseCaseProps): Promise<IAuth> => {
    try {
        const auth = await props.storageRepository.getAuth();

        if(!tokenIsValid(auth.token)) {
            const authRefreshed = await props.authRepository.refreshToken(auth);
            await props.storageRepository.setAuth(authRefreshed);
            ReduxStore.dispatch(AuthActions.setAuth(authRefreshed));
            return authRefreshed;
        }

        ReduxStore.dispatch(AuthActions.setAuth(auth));
        return auth;
    } catch (error) {
        return Promise.reject(error);
    }
};
