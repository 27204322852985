import { UseFormRegisterReturn,UseFormReturn } from 'react-hook-form';
import {
    ECookiesPreferencesFormDataName,
    ICookiesPreferencesFormData
} from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/CookiesPreferencesFormData';

interface ICookiesPreferencesFormControllerResponse {
    technicalRegister: UseFormRegisterReturn<ECookiesPreferencesFormDataName>
    analyticsRegister: UseFormRegisterReturn<ECookiesPreferencesFormDataName>
}

export const useCookiesPreferencesFormController = (
    UseCookiesPreferencesModalForm: UseFormReturn<ICookiesPreferencesFormData>,
): ICookiesPreferencesFormControllerResponse => {
    const technicalRegister = UseCookiesPreferencesModalForm.register(ECookiesPreferencesFormDataName.TECHNICAL);
    const analyticsRegister = UseCookiesPreferencesModalForm.register(ECookiesPreferencesFormDataName.ANALYTICS);

    return {
        technicalRegister,
        analyticsRegister
    };
};
