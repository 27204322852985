import axios,{ AxiosInstance } from 'axios';
import { IFeedResponse } from 'app/common/data/remote/sexonApi/dto/response/FeedResponse';
import { IGetFeedRequest } from 'app/common/data/remote/sexonApi/dto/request/GetFeedRequest';
import IProvinceResponse from 'app/common/data/remote/sexonApi/dto/response/ProvinceResponse';
import ICityResponse from 'app/common/data/remote/sexonApi/dto/response/CityResponse';
import { IGetCitiesRequest } from 'app/common/data/remote/sexonApi/dto/request/GetCitiesRequest';
import { IPostLoginRequest } from 'app/common/data/remote/sexonApi/dto/request/PostLoginRequest';
import { IPostLoginResponse } from 'app/common/data/remote/sexonApi/dto/response/PostLoginResponse';
import { IPostRefreshTokenRequest } from 'app/common/data/remote/sexonApi/dto/request/PostRefreshTokenRequest';
import { IPostRefreshTokenResponse } from 'app/common/data/remote/sexonApi/dto/response/PostRefreshTokenResponse';
import { IPostRegisterRequest } from 'app/common/data/remote/sexonApi/dto/request/PostRegisterRequest';
import { IProfileResponse } from 'app/common/data/remote/sexonApi/dto/response/ProfileResponse';
import { ICheckUserNameRequest } from 'app/common/data/remote/sexonApi/dto/request/CheckUserNameRequest';
import INationalityResponse from 'app/common/data/remote/sexonApi/dto/response/NationalityResponse';
import { ICreateProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/CreateProfileRequest';
import { IUploadedImageResponse } from 'app/common/data/remote/sexonApi/dto/response/UploadedImageResponse';
import IErrorResponse from 'app/common/data/remote/sexonApi/dto/response/ErrorResponse';
import { ICreatePostRequest } from 'app/common/data/remote/sexonApi/dto/request/CreatePostRequest';
import { IRecoverPasswordRequest } from 'app/common/data/remote/sexonApi/dto/request/RecoverPasswordRequest';
import { IResetPasswordRequest } from 'app/common/data/remote/sexonApi/dto/request/ResetPasswordRequest';
import { IPostResponse } from 'app/common/data/remote/sexonApi/dto/response/PostResponse';
import { IGetAccountVerifyResponse } from 'app/common/data/remote/sexonApi/dto/response/GetAccountVerifyResponse';
import { IEditProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/EditProfileRequest';
import { IPutProfileCitiesRequest } from 'app/common/data/remote/sexonApi/dto/request/PutProfileCitiesRequest';
import { IReportProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/ReportProfileRequest';
import { ISendSuggestionsRequest } from 'app/common/data/remote/sexonApi/dto/request/SendSuggestionsRequest';
import { IApiTokenDecoded } from 'app/common/data/remote/sexonApi/dto/ApiTokenDecoded';
import { jwtDecode } from 'jwt-decode';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { AuthActions } from 'app/common/presentation/redux/auth/AuthSlice';
import { IAuth,tokenIsValid } from 'app/module/auth/domain/entity/Auth';
import logOutUserCase from 'app/common/domain/useCase/LogOutUseCase';
import { EAppRoute } from 'app/common/presentation/routing/AppRoute';
import { IEditEmailRequest } from 'app/common/data/remote/sexonApi/dto/request/EditEmailRequest';
import { IValidateEmailChangeRequest } from 'app/common/data/remote/sexonApi/dto/request/ValidateEmailChangeRequest';
import { IPaymentOptionResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentOptionResponse';
import { IPaymentSummaryResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentSummaryResponse';
import { IChangePhoneRequest } from 'app/common/data/remote/sexonApi/dto/request/ChangePhoneRequest';
import { IVerifySmsRequest } from 'app/common/data/remote/sexonApi/dto/request/VerifySmsRequest';
import { IResendSmsRequest } from 'app/common/data/remote/sexonApi/dto/request/ResendSmsRequest';
import { IDeleteAccountRequest } from 'app/common/data/remote/sexonApi/dto/request/DeleteAccountRequest';
import { IDeletePostRequest } from 'app/common/data/remote/sexonApi/dto/request/DeletePostRequest';
import { IGetCurrentProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/GetCurrentProfileRequest';
import { IHideProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/HideProfileRequest';
import { IShowProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/ShowProfileRequest';
import { IVerifyAccountRequest } from 'app/common/data/remote/sexonApi/dto/request/VerifyAccountRequest';
import { IGetProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/GetProfileRequest';
import { IUploadImageRequest } from 'app/common/data/remote/sexonApi/dto/request/UploadImageRequest';
import { IGetPaymentOptionsRequest } from 'app/common/data/remote/sexonApi/dto/request/GetPaymentOptionsRequest';
import { IGetPaymentSummaryRequest } from 'app/common/data/remote/sexonApi/dto/request/GetPaymentSummaryRequest';
import { IGetPostsRequest } from 'app/common/data/remote/sexonApi/dto/request/GetPostsRequest';
import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { EErrorCode } from 'app/common/domain/entity/Error';

export interface ISexonApiClient {
    acceptAgeConsent: () => Promise<void>
    getProvinces: () => Promise<IProvinceResponse[]>
    getNationalities: () => Promise<INationalityResponse[]>
    editEmail: (request: IEditEmailRequest) => Promise<void>
    resendSms: (request: IResendSmsRequest) => Promise<void>
    verifySms: (request: IVerifySmsRequest) => Promise<void>
    deletePost: (request: IDeletePostRequest) => Promise<void>
    register: (request: IPostRegisterRequest) => Promise<void>
    createPost: (request: ICreatePostRequest) => Promise<void>
    hideProfile: (request: IHideProfileRequest) => Promise<void>
    showProfile: (request: IShowProfileRequest) => Promise<void>
    changePhone: (request: IChangePhoneRequest) => Promise<void>
    editProfile: (request: IEditProfileRequest) => Promise<void>
    getFeed: (request: IGetFeedRequest) => Promise<IFeedResponse>
    deleteAccount: (request: IDeleteAccountRequest) => Promise<void>
    checkUserName: (request: ICheckUserNameRequest) => Promise<void>
    resetPassword: (request: IResetPasswordRequest) => Promise<void>
    createProfile: (request: ICreateProfileRequest) => Promise<void>
    reportProfile: (request: IReportProfileRequest) => Promise<void>
    getPosts: (request: IGetPostsRequest) => Promise<IPostResponse[]>
    login: (request: IPostLoginRequest) => Promise<IPostLoginResponse>
    getCities: (request: IGetCitiesRequest) => Promise<ICityResponse[]>
    recoverPassword: (request: IRecoverPasswordRequest) => Promise<void>
    sendSuggestions: (request: ISendSuggestionsRequest) => Promise<void>
    addProfileCities: (request: IPutProfileCitiesRequest) => Promise<void>
    getProfile: (request: IGetProfileRequest) => Promise<IProfileResponse>
    validateEmailChange: (request: IValidateEmailChangeRequest) => Promise<void>
    uploadImage: (request: IUploadImageRequest) => Promise<IUploadedImageResponse>
    getCurrentProfile: (request: IGetCurrentProfileRequest) => Promise<IProfileResponse>
    verifyAccount: (request: IVerifyAccountRequest) => Promise<IGetAccountVerifyResponse>
    refreshToken: (request: IPostRefreshTokenRequest) => Promise<IPostRefreshTokenResponse>
    getPaymentSummary: (request: IGetPaymentSummaryRequest) => Promise<IPaymentSummaryResponse>
    getPaymentOptions: (request: IGetPaymentOptionsRequest) => Promise<IPaymentOptionResponse[]>
}

export class AxiosSexonApiClient implements ISexonApiClient {

    private _axiosInstance: AxiosInstance;

    constructor(
        private _storageRepository: IStorageRepository
    ) {
        this._axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
        });

        // Add a request interceptor
        this._axiosInstance.interceptors.request.use(async (config) => {
            // TODO (refactor)
            if(config.headers.Authorization){
                const configToken = config.headers.Authorization.toString();
                const currentToken = configToken.split('Bearer ')[1];
                const tokenDecoded: IApiTokenDecoded = jwtDecode(currentToken);
                const reduxData = ReduxStore.getState();
                const refreshToken = reduxData.auth.auth.refreshToken;

                const auth: IAuth = {
                    token: {
                        value: currentToken,
                        expirationDate: tokenDecoded.exp,
                        emittedDate: tokenDecoded.iat,
                        id: tokenDecoded.id,
                    },
                    // eslint-disable-next-line unicorn/no-unused-properties
                    refreshToken
                };

                if(!tokenIsValid(auth.token)){
                    const response =  await this.refreshToken({ token: refreshToken });
                    config.headers.Authorization = `Bearer ${response.token}`;
                    const authRefreshed: IAuth = {
                        token: {
                            value: response.token,
                            expirationDate: tokenDecoded.exp,
                            emittedDate: tokenDecoded.iat,
                            id: tokenDecoded.id,
                        },
                        refreshToken: response.refresh_token,
                    };
                    this._storageRepository.setAuth(authRefreshed);
                    ReduxStore.dispatch(AuthActions.setAuth(authRefreshed));
                }
            }
            return config;
        }, (error) => {
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        this._axiosInstance.interceptors.response.use((response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response.data;
        }, async (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.data.message === 'Unauthorized') {
                logOutUserCase({
                    storageRepository: this._storageRepository
                }).then(
                    ()=> {
                        window.location.pathname = EAppRoute.SIGN_IN;
                    }
                );
            }

            const errorResponse: IErrorResponse = error.response.data;

            // Check for file to large, we have to do this because back-end can not change it
            if(errorResponse.statusCode === 413){
                return Promise.reject(EErrorCode.FILE_TOO_LARGE);
            }
            return Promise.reject(errorResponse.errorCode);
        });
    }

    public async getFeed(request: IGetFeedRequest): Promise<IFeedResponse> {
        const endpoint = 'post/feed';
        return this._axiosInstance.get(
            endpoint,
            {
                params: request,
                paramsSerializer: {
                    indexes: null
                }
            });
    }

    public async getNationalities(): Promise<INationalityResponse[]> {
        const endpoint = 'nationalities';
        return this._axiosInstance.get(endpoint);
    }

    public async getProvinces(): Promise<IProvinceResponse[]> {
        const endpoint = 'province';
        return this._axiosInstance.get(endpoint);
    }

    public async getCities(request: IGetCitiesRequest): Promise<ICityResponse[]> {
        const endpoint = 'city';
        return this._axiosInstance.get(
            endpoint,
            {
                params: request
            }
        );
    }

    public async login(request: IPostLoginRequest): Promise<IPostLoginResponse> {
        const endpoint = 'auth/login';
        return this._axiosInstance.post(
            endpoint,
            request
        );
    }

    public async refreshToken(request: IPostRefreshTokenRequest): Promise<IPostRefreshTokenResponse> {
        const endpoint = 'auth/refresh-token';
        return this._axiosInstance.post(
            endpoint,
            request
        );
    }

    public async register(request: IPostRegisterRequest): Promise<void> {
        const endpoint = 'auth/register';
        return this._axiosInstance.post(
            endpoint,
            request
        );
    }

    public async getProfile(request: IGetProfileRequest): Promise<IProfileResponse> {
        const endpoint = `profile/${request.userName}`;
        return this._axiosInstance.get(
            endpoint,
            request.token ? this._tokenHeaderConfiguration(request.token) : undefined);
    }

    public async getCurrentProfile(request: IGetCurrentProfileRequest): Promise<IProfileResponse> {
        const endpoint = 'profile/current';
        return this._axiosInstance.get(endpoint,
            this._tokenHeaderConfiguration(request.token));
    }

    public async checkUserName(request: ICheckUserNameRequest): Promise<void> {
        const endpoint = 'profile/username-check';
        return this._axiosInstance.get(endpoint,
            {
                params: request
            },
        );
    }

    public async createProfile(request: ICreateProfileRequest): Promise<void> {
        const endpoint = 'profile';
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async uploadImage(request: IUploadImageRequest): Promise<IUploadedImageResponse> {
        const endpoint = 'upload/image';
        return await this._axiosInstance.post(
            endpoint,
            request.file,
            {
                headers: {
                    Authorization: `Bearer ${request.token}`,
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data',
                },
            }
        );
    }

    public async createPost(request: ICreatePostRequest): Promise<void> {
        const endpoint = 'post';
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async deletePost(request: IDeletePostRequest): Promise<void> {
        const endpoint = `post/${request.id}`;
        return await this._axiosInstance.delete(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async getPosts(request: IGetPostsRequest): Promise<IPostResponse[]> {
        const endpoint = `post/profile/${request.userName}`;
        return this._axiosInstance.get(
            endpoint,
            {
                params: request.payload,
                headers: request.token ? {
                    Authorization: `Bearer ${request.token}`,
                } : undefined
            },
        );
    }

    public async verifyAccount(request: IVerifyAccountRequest): Promise<IGetAccountVerifyResponse> {
        const endpoint = `auth/verify-email/${request.token}`;
        return this._axiosInstance.get(endpoint);
    }

    public async recoverPassword(request: IRecoverPasswordRequest): Promise<void> {
        const endpoint = 'auth/recover-password';
        return await this._axiosInstance.post(
            endpoint,
            request,
        );
    }

    public async resetPassword(request: IResetPasswordRequest): Promise<void> {
        const endpoint = 'auth/reset-password';
        return await this._axiosInstance.post(
            endpoint,
            request,
        );
    }

    public async editProfile(request: IEditProfileRequest): Promise<void> {
        const endpoint = `profile/${request.profileId}`;
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async addProfileCities(request: IPutProfileCitiesRequest): Promise<void> {
        const endpoint = `profile-cities/${request.profileId}`;
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async reportProfile(request: IReportProfileRequest): Promise<void> {
        const endpoint = `profile/${request.profileId}/report`;
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
        );
    }

    public async deleteAccount(request: IDeleteAccountRequest): Promise<void> {
        const endpoint = 'auth/delete-request';
        return await this._axiosInstance.delete(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async hideProfile(request: IHideProfileRequest): Promise<void> {
        const endpoint = `profile/${request.profileId}/disable`;
        return await this._axiosInstance.put(
            endpoint,
            undefined,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async showProfile(request: IShowProfileRequest): Promise<void> {
        const endpoint = `profile/${request.profileId}/enable`;
        return await this._axiosInstance.put(
            endpoint,
            undefined,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async sendSuggestions(request: ISendSuggestionsRequest): Promise<void> {
        const endpoint = 'suggestion';
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async editEmail(request: IEditEmailRequest): Promise<void> {
        const endpoint = 'auth/change-email';
        return await this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async validateEmailChange(request: IValidateEmailChangeRequest): Promise<void> {
        const endpoint = 'auth/validate-email-change';
        return this._axiosInstance.post(
            endpoint,
            request,
        );
    }

    public async getPaymentOptions(request: IGetPaymentOptionsRequest): Promise<IPaymentOptionResponse[]> {
        const endpoint = 'payment-options';
        return this._axiosInstance.get(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async getPaymentSummary(request: IGetPaymentSummaryRequest): Promise<IPaymentSummaryResponse> {
        const endpoint = `payment-summary/${request.subscriptionOptionId}`;
        return this._axiosInstance.get(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async changePhone(request: IChangePhoneRequest): Promise<void> {
        const endpoint = 'auth/change-phone';
        return this._axiosInstance.put(
            endpoint,
            request.payload,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async verifySms(request: IVerifySmsRequest): Promise<void> {
        const endpoint = `auth/verify-sms/${request.smsToken}`;
        return this._axiosInstance.get(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async resendSms(request: IResendSmsRequest): Promise<void> {
        const endpoint = 'auth/resend-sms';
        return this._axiosInstance.get(
            endpoint,
            this._tokenHeaderConfiguration(request.token),
        );
    }

    public async acceptAgeConsent(): Promise<void> {
        const endpoint = 'age-consent';
        return this._axiosInstance.put(
            endpoint,
        );
    }

    private _tokenHeaderConfiguration (token: string): {
        headers: { Authorization: string }
    } {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }
}

