import React from 'react';
import { ACCEPT,COOKIES_CONSENT_MODAL_TEXT,COOKIES_CONSENT_MODAL_TITLE,DENY,VIEW_PREFERENCES } from 'translations/tr';
import './CookiesConsentModal.scss';
import { useTranslation } from 'react-i18next';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { useCookiesConsentConsentReduxState } from 'app/common/presentation/redux/cookiesConsent/hook/useCookiesConsentConsentReduxState';
import { useCookiesConsentModalController } from 'app/common/presentation/component/cookiesConsentModal/useCookiesConsentModalController';
import { useShowCookiesPreferencesConsentReduxState } from 'app/common/presentation/redux/cookiesPreferences/hook/useShowCookiesPreferencesConsentReduxState';
import { SexonModal } from '../modal/SexonModal';
import { SexonButton } from '../button/SexonButton';

export const CookiesConsentModal = (): JSX.Element => {
    const { t } = useTranslation();
    const cookiesConsent = useCookiesConsentConsentReduxState();
    const showPreferencesModal = useShowCookiesPreferencesConsentReduxState();
    const controller = useCookiesConsentModalController();
    const cookiesPolicyUrl = process.env.REACT_APP_COOKIES_POLICY_URL;

    return (
        <SexonModal
            show={ !cookiesConsent.cookiesAccepted && !showPreferencesModal }
            classname="component-cookies-consent-modal"
            headerTitle={ t(COOKIES_CONSENT_MODAL_TITLE) }
            body={ (
                <div className="cookies-consent-modal-body-container">
                    <p
                        dangerouslySetInnerHTML={ { __html: t(COOKIES_CONSENT_MODAL_TEXT, { cookiesPolicyUrl }).toString() } }
                    />
                </div>
            ) }

            footer={(
                <div className="cookies-consent-modal-footer-container">
                    <SexonButton
                        type="button"
                        title={ t(VIEW_PREFERENCES).toUpperCase()}
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        className="cookies-consent-modal-footer-preferences-button"
                        onClick={controller.openPreferencesModal}
                    />
                    <div className="cookies-consent-modal-footer-actions-buttons-container">
                        <SexonButton
                            type="button"
                            title={ t(DENY).toUpperCase()}
                            onClick={controller.denyCookies}
                            styling={ESexonButtonStyle.FILLED}
                            className="cookies-consent-modal-footer-deny-button"
                        />
                        <SexonButton
                            type="button"
                            title={ t(ACCEPT).toUpperCase()}
                            styling={ESexonButtonStyle.FILLED}
                            onClick={controller.acceptCookies}
                            className="cookies-consent-modal-footer-accept-button"
                        />
                    </div>
                </div>
            )}
        />
    );
};
