export interface IPaymentOptionResponse {
    id: string
    icon: string
    title: string
    subtitle: string
    advantages: string[]
    compatibleIds: string[]
    values: IPaymentOptionValueResponse[]
}

export interface IPaymentOptionValueResponse {
    id: string
    title: string
    price: number
    discount?: number
}

export enum EPaymentOptionTypeResponse {
    GLOBAL = 1,
    LOCAL = 10
}
