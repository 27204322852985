import IProvinceRepository from 'app/module/profile/domain/interface/ProvinceRepository';
import IProvince from 'app/module/profile/domain/entity/Province';

interface IGetProvincesUseCaseProps {
    provinceRepository: IProvinceRepository
}

export default async (props: IGetProvincesUseCaseProps): Promise<IProvince[]> => {
    try {
        return await props.provinceRepository.findAll();
    } catch (error) {
        return Promise.reject(error);
    }
};
