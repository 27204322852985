import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'suggestionsModal',
    initialState,
    reducers: {
        handleShowSuggestionsModal: (state) => {
            state.showModal = true;
        },
        handleHideSuggestionsModal: (state) => {
            state.showModal = false;
        },
    },
});

export const SuggestionsModalReducer = slice.reducer;
export const SuggestionsModalActions = slice.actions;
