import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { IsFistTimeActions } from 'app/common/presentation/redux/isFistTime/IsFirstTimeSlice';

interface IHandleIsFirstTimeUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: IHandleIsFirstTimeUseCaseProps): Promise<void> => {
    try {
        const isFirstTime = await props.storageRepository.getIsFirstTime();
        if(isFirstTime){
            await props.storageRepository.setIsFirstTime(false);
            ReduxStore.dispatch(IsFistTimeActions.setIsFirstTime(true));
        }

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};
