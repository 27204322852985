import IProfileRepository from 'app/module/profile/domain/interface/ProfileRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { CurrentProfileActions } from 'app/common/presentation/redux/currentProfile/CurrentProfileSlice';
import { FeedActions } from 'app/common/presentation/redux/feed/FeedSlice';
import { FeedFiltersActions } from 'app/common/presentation/redux/feed/FeedFiltersSlice';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { EErrorCode } from 'app/common/domain/entity/Error';
import { PhoneIsVerifiedActions } from 'app/common/presentation/redux/phoneIsVerified/PhoneIsVerifySlice';
import { IProfile } from 'app/module/profile/domain/entity/Profile';

interface IGetCurrentProfileUseCaseProps {
    auth: IAuth
    profileRepository: IProfileRepository
}

export default async (props: IGetCurrentProfileUseCaseProps): Promise<IProfile> => {
    try {
        const profile = await props.profileRepository.findCurrentProfile(
            props.auth
        );
        await ReduxStore.dispatch(CurrentProfileActions.setCurrentProfile(profile));
        await ReduxStore.dispatch(FeedActions.reset());
        await ReduxStore.dispatch(FeedFiltersActions.reset());
        return profile;
    } catch (error) {
        if(error === EErrorCode.AUTH_VALIDATE_SMS){
            ReduxStore.dispatch(PhoneIsVerifiedActions.setPhoneIsVerified(false));
        }
        return Promise.reject(error);
    }
};
