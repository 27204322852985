import { ICreatePostRequest } from 'app/common/data/remote/sexonApi/dto/request/CreatePostRequest';
import { IPost } from 'app/module/profile/domain/entity/Post';
import { IAuth } from 'app/module/auth/domain/entity/Auth';


export class CreatePostDataMapper {

    public static fromDomain(post: IPost, auth: IAuth): ICreatePostRequest {
        return {
            payload: {
                id: post.id,
                description: post.description,
                image: post.image,
            },
            token: auth.token.value
        };
    }
}
