import { useAuth } from 'app/common/presentation/hook/useAuth';
import { useCurrentProfile } from 'app/common/presentation/hook/useCurrentProfile';

interface IIsAuthedWithoutProfileTemplateProps {
    children: JSX.Element
}
export const IsAuthedWithoutProfile = (props: IIsAuthedWithoutProfileTemplateProps): JSX.Element |null => {
    const UseAuth = useAuth();
    const UseCurrentProfile = useCurrentProfile();
    return UseAuth.isAuthed && !UseCurrentProfile.profileIsCreated ? props.children : null;
};
