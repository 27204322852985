import { Amount } from 'app/common/domain/valueObject/Amount';
import { IPaymentSummaryResponse } from 'app/common/data/remote/sexonApi/dto/response/PaymentSummaryResponse';
import { IPaymentSummary } from 'app/module/payment/domain/entity/PaymentSummary';

export class PaymentSummaryDataMapper {

    public static fromResponse(response: IPaymentSummaryResponse): IPaymentSummary {
        const centsToEuros = 100;

        return {
            items: response.items.map((item)=> {
                return {
                    description: item.description,
                    price: new Amount(item.price / centsToEuros)
                };
            }),
            paymentUrl: response.paymentUrl,
            until: new Date(response.until),
            total: new Amount(response.total / centsToEuros)
        };
    }
}
