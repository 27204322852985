import i18next from 'i18next';
import { OTHER_LABEL,SEXUAL_OFFENSES_LABEL } from 'translations/tr';

export interface IReportReason {
    value: EReportReason
}

export enum EReportReason {
    SEXUAL_OFFENSES = 'Sexual offenses against minors',
    OTHER = 'Other'
}


export const translateReportReason = (value: EReportReason): string => {
    switch(value){
        case EReportReason.SEXUAL_OFFENSES:
            return i18next.t(SEXUAL_OFFENSES_LABEL);
        case EReportReason.OTHER:
        default:
            return i18next.t(OTHER_LABEL);
    }
};
