import React,{ ReactElement } from 'react';
import './App.scss';
import '../translations';
import { AppRouting } from 'app/common/presentation/routing/AppRouting';
import { BrowserRouter } from 'react-router-dom';
import { AgeConsentModal } from 'app/common/presentation/component/ageConsentModal/AgeConsentModal';
import { CookiesConsentModal } from 'app/common/presentation/component/cookiesConsentModal/CookiesConsentModal';
import { CookiesPreferencesModal } from 'app/common/presentation/component/cookiesPreferencesModal/CookiesPreferencesModal';
import { useAnalyticsController } from 'app/common/presentation/hook/useAnalyticsController';
import { isbot } from 'isbot';
import { PopUpManager } from 'app/common/presentation/component/popUpManager/PopUpManager';
import { useInitialDataLoad } from './common/presentation/hook/useInitialDataLoad';
import { InitialLoadingSpinner } from './common/presentation/component/spinner/initialLoadingSpinner/InitialLoadingSpinner';

export const App = (): JSX.Element=> {
    const UseInitialDataLoad = useInitialDataLoad();
    useAnalyticsController();

    const renderContent = (): ReactElement => {
        if (!UseInitialDataLoad.hasAgeConsent && !isbot(navigator.userAgent)) {
            return <AgeConsentModal />;
        }

        if (!UseInitialDataLoad.cookiesConsent.cookiesAccepted && !isbot(navigator.userAgent)) {
            return (
                <>
                    <CookiesConsentModal />
                    <CookiesPreferencesModal />
                </>
            );
        }

        if (UseInitialDataLoad.isLoading) {
            return <InitialLoadingSpinner />;
        }

        return (
            <BrowserRouter>
                <>
                    <AppRouting />
                    <PopUpManager />
                </>
            </BrowserRouter>
        );
    };

    return (
        renderContent()
    );
};
