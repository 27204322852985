import { createSlice,PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    lastPostIndex: {
        value: 0
    }
};


const slice = createSlice({
    name: 'lastPostIndex',
    initialState,
    reducers: {
        setFeedLastPostIndex: (state, action: PayloadAction<number>) => {
            state.lastPostIndex.value = action.payload;
        },
        reset: (state) => {
            state.lastPostIndex = initialState.lastPostIndex;
        },
    },
});

export const FeedLastPostIndexReducer = slice.reducer;
export const FeedLastPostIndexActions = slice.actions;
