import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import ICookies from 'app/common/domain/entity/Cookies';
import { CookiesConsentActions } from 'app/common/presentation/redux/cookiesConsent/CookiesConsentSlice';
import { ICookiesPreferencesFormData } from 'app/common/presentation/component/cookiesPreferencesModal/preferencesForm/CookiesPreferencesFormData';

interface ISetCookiesConsentUseCaseProps {
    cookiesPreferencesFormData: ICookiesPreferencesFormData
    storageRepository: IStorageRepository
}

export default async (props: ISetCookiesConsentUseCaseProps): Promise<void> => {
    try {
        const cookies: ICookies = {
            cookiesAccepted: true,
            preferences: {
                technical: props.cookiesPreferencesFormData.technical,
                analytics: props.cookiesPreferencesFormData.analytics
            }
        };

        return  await props.storageRepository.setCookiesConsent(cookies).then((): void =>{
            ReduxStore.dispatch(CookiesConsentActions.setConsent(cookies));
        }
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
