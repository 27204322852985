import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SuggestionsIcon } from 'assets/icon/sexon/svg/icons';
import { SUGGESTIONS_MODAL_DESCRIPTION_TEXT,THIS_FIELD_IS_REQUIRED_ERROR } from 'translations/tr';
import { SexonTextArea } from 'app/common/presentation/component/textArea/SexonTextArea';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { ESuggestionsFormDataName,ISuggestionsFormData } from 'app/common/presentation/component/popUpManager/suggestionModal/form/SuggestionsFormData';

interface IReportModalFormProps {
    UseSuggestionsModalForm: UseFormReturn<ISuggestionsFormData>
}
export const SuggestionForm = (props: IReportModalFormProps): JSX.Element => {
    const { t } = useTranslation();
    const suggestionsRegister = props.UseSuggestionsModalForm.register(ESuggestionsFormDataName.SUGGESTIONS,
        {
            required: t(THIS_FIELD_IS_REQUIRED_ERROR).toString()
        });
    const suggestionsError = props.UseSuggestionsModalForm.formState.errors.suggestions;
    const watchSuggestions = props.UseSuggestionsModalForm.watch(ESuggestionsFormDataName.SUGGESTIONS);

    return (
        <form className="component-suggestion-modal-form">
            <SexonTextArea
                id="suggestions"
                maxLength={1000}
                icon={<SuggestionsIcon />}
                register={suggestionsRegister}
                watchRegister={watchSuggestions}
                placeHolder={t(SUGGESTIONS_MODAL_DESCRIPTION_TEXT).toString()}
            />
            <SexonInputErrorMessage showError={!!suggestionsError} message={suggestionsError?.message || ''} />
        </form>
    );
};
