import i18next from 'i18next';
import { BIZUM_LABEL,CARD_LABEL,CASH_LABEL,CRYPTOCURRENCIES_LABEL,PAYPAL_LABEL } from 'translations/tr';

export interface IPaymentMethod {
    value: EPaymentMethod
}

export enum EPaymentMethod {
    CASH = 'CASH',
    BIZUM = 'BIZUM',
    CARD = 'CARD',
    PAYPAL = 'PAYPAL',
    CRYPTOCURRENCIES = 'CRYPTOCURRENCIES'
}

export const translatePaymentMethod = (value: EPaymentMethod): string => {
    switch( value ){
        case EPaymentMethod.CASH:
            return i18next.t(CASH_LABEL);
        case EPaymentMethod.BIZUM:
            return i18next.t(BIZUM_LABEL);
        case EPaymentMethod.CARD:
            return i18next.t(CARD_LABEL);
        case EPaymentMethod.CRYPTOCURRENCIES:
            return i18next.t(CRYPTOCURRENCIES_LABEL);
        case EPaymentMethod.PAYPAL:
        default:
            return i18next.t(PAYPAL_LABEL);
    }
};

export const translatePaymentMethods = (values: EPaymentMethod[]): string => {
    let translation = '';

    if (values.length === 1) {
        translation = translatePaymentMethod(values[0]);
    }else {
        values.forEach((value, index) => {
            if(index === values.length || index === 0){
                translation += translatePaymentMethod(value);
            } else {
                translation += ` - ${translatePaymentMethod(value)} `;
            }
        });
    }

    return translation;
};
