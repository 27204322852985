import i18next from 'i18next';
import {
    FOR_HETEROSEXUAL_MEN_LABEL,
    FOR_HETEROSEXUAL_WOMEN_LABEL,
    FOR_HOMESEXUAL_MEN_LABEL,
    FOR_HOMESEXUAL_WOMEN_LABEL,
    HETEROSEXUAL_MEN_LABEL,
    HETEROSEXUAL_WOMEN_LABEL,
    HOMESEXUAL_MEN_LABEL,
    HOMESEXUAL_WOMEN_LABEL
} from 'translations/tr';

export interface ICategory {
    value: ECategory
}

export enum ECategory {
    HETEROSEXUAL_MEN= 'HETEROSEXUAL_MEN',
    HETEROSEXUAL_WOMEN = 'HETEROSEXUAL_WOMEN',
    HOMOSEXUAL_MEN = 'HOMOSEXUAL_MEN',
    HOMOSEXUAL_WOMEN = 'HOMOSEXUAL_WOMEN'
}

export const translateCategoryProfile = (value: ECategory): string => {
    switch( value ){
        case ECategory.HETEROSEXUAL_MEN:
            return i18next.t(FOR_HETEROSEXUAL_MEN_LABEL);
        case ECategory.HETEROSEXUAL_WOMEN:
            return i18next.t(FOR_HETEROSEXUAL_WOMEN_LABEL);
        case ECategory.HOMOSEXUAL_MEN:
            return i18next.t(FOR_HOMESEXUAL_MEN_LABEL);
        case ECategory.HOMOSEXUAL_WOMEN:
        default:
            return i18next.t(FOR_HOMESEXUAL_WOMEN_LABEL);
    }
};

export const translateCategoryFilter = (value: ECategory): string => {
    switch( value ){
        case ECategory.HETEROSEXUAL_MEN:
            return i18next.t(HETEROSEXUAL_WOMEN_LABEL);
        case ECategory.HETEROSEXUAL_WOMEN:
            return i18next.t(HETEROSEXUAL_MEN_LABEL);
        case ECategory.HOMOSEXUAL_MEN:
            return i18next.t(HOMESEXUAL_MEN_LABEL);
        case ECategory.HOMOSEXUAL_WOMEN:
        default:
            return i18next.t(HOMESEXUAL_WOMEN_LABEL);
    }
};
