import { createSlice } from '@reduxjs/toolkit';
import { EHeight,IHeight } from 'app/module/profile/domain/valueObject/Height';

const initialState = {
    heights: [
        { value: EHeight.LESS_THAN_160 },
        { value: EHeight.BETWEEN_160_AND_170 },
        { value: EHeight.MORE_THAN_170 },
    ] as IHeight[]
};

const slice = createSlice({
    name: 'heights',
    initialState,
    reducers: {},
});

export const HeightsReducer = slice.reducer;
export const HeightsActions = slice.actions;
