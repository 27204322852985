import React from 'react';
import {
    HELP_CENTER,
    HELP_SECTION_MODAL_TITLE,
    HOW_TO_BOOST_PROFILE,
    HOW_TO_CHANGE_RECOVER_PASSWORD,
    HOW_TO_CREATE_ACCOUNT_PROFILE,
    HOW_TO_CREATE_POSTS,HOW_TO_DELETE_POST,
    HOW_TO_DELETE_PROFILE,
    HOW_TO_EDIT_PROFILE,
    HOW_TO_HIDE_PROFILE,PARENTAL_CONTROL,
    POST_TIPS,
    PROFILE_VISIBILITY,
    WHAT_IS_SEXON
} from 'translations/tr';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useShowHelpSectionModalReduxState } from 'app/common/presentation/redux/helpSectionModal/hook/useShowHelpSectionModalReduxState';
import { ShowHelpSectionModalActions } from 'app/common/presentation/redux/helpSectionModal/ShowHelpSectionModalSlice';
import './HelpSectionModal.scss';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { SexonModal } from 'app/common/presentation/component/modal/SexonModal';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';

export const HelpSectionModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const showModal = useShowHelpSectionModalReduxState();

    const closeModal = (): void => {
        dispatch(ShowHelpSectionModalActions.handleHideHelpSectionModal());
    };

    const openPaymentUrl = (url: string): void => {
        window.open(url, '_blank');
    };

    const helpItems = [
        { title: WHAT_IS_SEXON, url: process.env.REACT_APP_WHAT_IS_SEXON_URL },
        { title: HOW_TO_CREATE_ACCOUNT_PROFILE, url: process.env.REACT_APP_HOW_TO_CREATE_ACCOUNT_PROFILE_URL },
        { title: HOW_TO_CREATE_POSTS, url: process.env.REACT_APP_HOW_TO_CREATE_POSTS_URL },
        { title: HOW_TO_EDIT_PROFILE, url: process.env.REACT_APP_HOW_TO_EDIT_PROFILE_URL },
        { title: PROFILE_VISIBILITY, url: process.env.REACT_APP_PROFILE_VISIBILITY_URL },
        { title: HOW_TO_BOOST_PROFILE, url: process.env.REACT_APP_HOW_TO_BOOST_PROFILE_URL },
        { title: POST_TIPS, url: process.env.REACT_APP_POST_TIPS_URL },
        { title: HOW_TO_CHANGE_RECOVER_PASSWORD, url: process.env.REACT_APP_HOW_TO_CHANGE_RECOVER_PASSWORD_URL },
        { title: HOW_TO_DELETE_POST, url: process.env.REACT_APP_HOW_TO_DELETE_POST_URL },
        { title: HOW_TO_HIDE_PROFILE, url: process.env.REACT_APP_HOW_TO_HIDE_PROFILE_URL },
        { title: HOW_TO_DELETE_PROFILE, url: process.env.REACT_APP_HOW_TO_DELETE_PROFILE_URL },
        { title: PARENTAL_CONTROL, url: process.env.REACT_APP_PARENTAL_CONTROL_URL },
        { title: HELP_CENTER, url: process.env.REACT_APP_HELP_CENTER_URL },
    ];

    return (
        <SexonModal
            show={ showModal }
            onClose={closeModal}
            classname="component-help-section-modal"
            headerTitle={ t(HELP_SECTION_MODAL_TITLE) }
            body={ (
                <div className="help-section-modal-body-container">
                    { helpItems.map((item) => (
                        <SexonButton
                            type="button"
                            key={ item.title }
                            styling={ ESexonButtonStyle.BORDER }
                            title={ t(item.title).toUpperCase() }
                            onClick={ () => openPaymentUrl(item.url || '') }
                        />
                    )) }
                </div>
            ) }
        />
    );
};
