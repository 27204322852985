import { createSlice } from '@reduxjs/toolkit';
import { ESexualOrientation,ISexualOrientation } from 'app/module/profile/domain/valueObject/SexualOrientation';

const initialState = {
    orientations: [
        { value: ESexualOrientation.HETEROSEXUAL },
        { value: ESexualOrientation.HOMOSEXUAL },
        { value: ESexualOrientation.BISEXUAL },
    ] as ISexualOrientation[]
};

const slice = createSlice({
    name: 'orientations',
    initialState,
    reducers: {},
});

export const OrientationsReducer = slice.reducer;
export const OrientationsActions = slice.actions;
