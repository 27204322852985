import React from 'react';
import './SexonToggleSwitchButton.scss';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';

interface ISexonToggleSwitchButtonProps {
    title: string
    icon?: JSX.Element
    disabled?: boolean
    register?: UseFormRegisterReturn
}
export const SexonToggleSwitchButton = (props: ISexonToggleSwitchButtonProps): JSX.Element => {
    return (
        <div className={cx({
            'is-disabled': props.disabled,
            'component-toggle-switch': true,
        })}>
            <div className="toggle-switch-icon-container">
                {props.icon}
            </div>

            <p className="toggle-switch-name">{props.title}</p>

            <label
                className="switch-button"
                htmlFor={`"toggleSwitch"-${props.title}`}
                aria-hidden
            >
                <input
                    id={`"toggleSwitch"-${props.title}`}
                    type="checkbox"
                    { ...props.register }
                />
                <span className="slider" />
            </label>
        </div>
    );
};
