import { EHeight,IHeight } from 'app/module/profile/domain/valueObject/Height';
import { EApiHeight } from 'app/common/data/remote/sexonApi/dto/ApiHeight';

export class HeightDataMapper {

    public static fromDomain(height: IHeight): EApiHeight {
        switch( height.value ){
            case EHeight.LESS_THAN_160:
                return EApiHeight.UNDER_60;
            case EHeight.BETWEEN_160_AND_170:
                return EApiHeight.BETWEEN_60_70;
            case EHeight.MORE_THAN_170:
            default:
                return EApiHeight.OVER_70;
        }
    }

    public static fromResponse(height: EApiHeight): IHeight {
        switch( height ){
            case EApiHeight.UNDER_60:
                return {
                    value: EHeight.LESS_THAN_160,
                };
            case EApiHeight.BETWEEN_60_70:
                return {
                    value: EHeight.BETWEEN_160_AND_170,
                };
            case EApiHeight.OVER_70:
            default:
                return {
                    value: EHeight.MORE_THAN_170,
                };
        }
    }

    public static arrayFromDomain(heights: IHeight[]): EApiHeight[] {
        const heightsResponse: EApiHeight[] = [];

        heights.forEach((height) => {
            heightsResponse.push(this.fromDomain(height));
        });

        return heightsResponse;
    }
}
