import React from 'react';
import './SexonChexbox.scss';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';

interface ISexonCheckboxProps{
    id: string
    label: string
    showError?: boolean
    isRequired: boolean
    errorMessage?: string
    register: UseFormRegisterReturn
}

export const SexonCheckbox = (props: ISexonCheckboxProps): JSX.Element => {
    return (
        <div className="component-checkbox">
            <div className="input-label-container">
                <input
                    id={ props.id }
                    type="checkbox"
                    { ...props.register }
                    className={ cx({
                        'input-checkbox': true,
                        'is-required': props.isRequired,
                    }) }

                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label
                    htmlFor={ props.id }
                    className="input-checkbox-label"
                >
                    <p
                        dangerouslySetInnerHTML={
                            { __html: props.label }
                        }
                    />
                </label>
            </div>

            <SexonInputErrorMessage
                showError={ !!props.showError }
                message={ props.errorMessage || '' }
            />
        </div>
    );
};
