import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'createPostModal',
    initialState,
    reducers: {
        handleShowCreatePostModal: (state) => {
            state.showModal = true;
        },
        handleHideCreatePostModal: (state) => {
            state.showModal = false;
        },
    },
});

export const CreatePostModalReducer = slice.reducer;
export const CreatePostModalActions = slice.actions;
