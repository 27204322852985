import { useAuth } from 'app/common/presentation/hook/useAuth';
import { useCurrentProfile } from 'app/common/presentation/hook/useCurrentProfile';

interface IIsAuthedWithProfileTemplateProps {
    children: JSX.Element
    replacement?: JSX.Element
}
export const IsAuthedWithProfile = (props: IIsAuthedWithProfileTemplateProps): JSX.Element | null => {
    const UseAuth = useAuth();
    const UseCurrentProfile = useCurrentProfile();
    return UseAuth.isAuthed && UseCurrentProfile.profileIsCreated ? props.children : props.replacement || null;
};
