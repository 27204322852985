import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useCookiesConsentConsentReduxState } from 'app/common/presentation/redux/cookiesConsent/hook/useCookiesConsentConsentReduxState';
import Cookies from 'js-cookie';

export const useAnalyticsController = (): void => {
    const cookiesConsent = useCookiesConsentConsentReduxState();

    const trackingId = process.env.REACT_APP_ANALYTICS_ID;
    const env = process.env.REACT_APP_ENV;

    useEffect(() => {
        if (cookiesConsent.preferences.analytics && trackingId && env === 'prod') {
            if (!ReactGA.isInitialized) {
                ReactGA.initialize(trackingId);
                ReactGA.send({ send_page_view: false });
            }
            // @ts-ignore
            window[`ga-disable-${trackingId}`] = false;
            ReactGA.gtag('consent', 'default', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
            });
        } else {
            // @ts-ignore
            window[`ga-disable-${trackingId}`] = true;
            ReactGA.gtag('consent', 'default', {
                ad_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                analytics_storage: 'denied',
            });
            if(trackingId){
                Cookies.remove(`_ga_${trackingId.split('-')[1]}`, { path: '/', domain: 'sexon.love' });
                Cookies.remove('_ga', { path: '/', domain: 'sexon.love' });
                Cookies.remove('_gid', { path: '/', domain: 'sexon.love' });
            }
        }
    }, [cookiesConsent.preferences.analytics]);
};
