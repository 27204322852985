import IAuthRepository from 'app/module/auth/domain/interface/AuthRepository';
import { ICredential } from 'app/module/auth/domain/entity/Credential';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { Email } from 'app/common/domain/valueObject/Email';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { IApiTokenDecoded } from 'app/common/data/remote/sexonApi/dto/ApiTokenDecoded';
import { jwtDecode } from 'jwt-decode';


export class ApiAuthRepository implements IAuthRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ){}

    public async loginUser(
        credential: ICredential
    ): Promise<IAuth> {
        try {
            const userData = await this._sexonApiClient.login(
                {
                    email: credential.email.value,
                    password: credential.password
                }
            );
            const userDataDecoded: IApiTokenDecoded = jwtDecode(userData.token);
            return {
                token: {
                    value: userData.token,
                    expirationDate: userDataDecoded.exp,
                    emittedDate: userDataDecoded.iat,
                    id: userDataDecoded.id,
                },
                refreshToken: userData.refresh_token,
            };
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async refreshToken(auth: IAuth): Promise<IAuth> {
        try {
            const refreshData = await this._sexonApiClient.refreshToken(
                {
                    token: auth.refreshToken,
                }
            );
            const userDataDecoded: IApiTokenDecoded = jwtDecode(refreshData.token);
            return {
                token: {
                    value: refreshData.token,
                    expirationDate: userDataDecoded.exp,
                    emittedDate: userDataDecoded.iat,
                    id: userDataDecoded.id,
                },
                refreshToken: refreshData.refresh_token,
            };
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async registerUser(
        credential: ICredential,
        authId: string
    ): Promise<void> {
        try {
            return await this._sexonApiClient.register(
                {
                    id: authId,
                    email: credential.email.value,
                    password: credential.password
                }
            );
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async verifyAccount(
        token: string,
    ): Promise<IAuth> {
        try {
            const data = await this._sexonApiClient.verifyAccount({
                token
            });
            const userDataDecoded: IApiTokenDecoded = jwtDecode(data.token);
            return {
                token: {
                    value: data.token,
                    expirationDate: userDataDecoded.exp,
                    emittedDate: userDataDecoded.iat,
                    id: userDataDecoded.id,
                },
                refreshToken: data.refresh_token,


            };
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async recoverPassword(
        email: Email,
    ): Promise<void> {
        try {
            return await this._sexonApiClient.recoverPassword(
                { email: email.value },
            );
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async resetPassword(
        token: string,
        newPassword: string,
    ): Promise<void> {
        try {
            return await this._sexonApiClient.resetPassword(
                {
                    token,
                    password: newPassword
                },
            );
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async deleteAccount(
        auth: IAuth,
    ): Promise<void> {
        try {
            return await this._sexonApiClient.deleteAccount({
                token: auth.token.value
            });
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async editEmail(
        auth: IAuth,
        email: Email,
    ): Promise<void> {
        try {
            return await this._sexonApiClient.editEmail({
                payload: {
                    email: email.value,
                },
                token: auth.token.value,
            });
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async validateEmailChange(
        token: string,
    ): Promise<void> {
        try {
            return  await this._sexonApiClient.validateEmailChange(
                {
                    token
                },
            );
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async changePhone(
        auth: IAuth,
        phone: string,
    ): Promise<void> {
        try {
            return  await this._sexonApiClient.changePhone({
                payload: {
                    phone
                },
                token: auth.token.value
            }
            );
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async verifySms(
        auth: IAuth,
        token: number,
    ): Promise<void> {
        try {
            return  await this._sexonApiClient.verifySms({
                smsToken: token,
                token: auth.token.value,
            });
        } catch (error){
            return Promise.reject(error);
        }
    };

    public async resendSms(
        auth: IAuth,
    ): Promise<void> {
        try {
            return  await this._sexonApiClient.resendSms({
                token: auth.token.value,
            });
        } catch (error){
            return Promise.reject(error);
        }
    };

}
