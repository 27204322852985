import { EApiHairColor } from 'app/common/data/remote/sexonApi/dto/ApiHairColor';
import { EHairColor,IHairColor } from 'app/module/profile/domain/valueObject/HairColor';

export class HairColorDataMapper {

    public static fromDomain(hairColor: IHairColor): EApiHairColor {
        switch( hairColor.value ){
            case EHairColor.BROWN_HAIRED:
                return EApiHairColor.BROWN;
            case EHairColor.BLONDE:
                return EApiHairColor.BLONDE;
            case EHairColor.BRUNETTE:
                return EApiHairColor.DARK;
            case EHairColor.REDHEAD:
            default:
                return EApiHairColor.RED;
        }
    }

    public static fromResponse(hairColor: EApiHairColor): IHairColor {
        switch( hairColor ){
            case EApiHairColor.BROWN:
                return {
                    value: EHairColor.BROWN_HAIRED
                };
            case EApiHairColor.BLONDE:
                return {
                    value: EHairColor.BLONDE
                };
            case EApiHairColor.DARK:
                return {
                    value: EHairColor.BRUNETTE
                };
            case EApiHairColor.RED:
            default:
                return {
                    value: EHairColor.REDHEAD
                };
        }
    }

    public static arrayFromDomain(hairColors: IHairColor[]): EApiHairColor[] {
        const hairColorsResponse: EApiHairColor[] = [];

        hairColors.forEach((hairColor) => {
            hairColorsResponse.push(this.fromDomain(hairColor));
        });

        return hairColorsResponse;
    }
}
