import { EApiSchedule } from 'app/common/data/remote/sexonApi/dto/ApiSchedule';
import { ESchedule,ISchedule } from 'app/module/profile/domain/valueObject/Schedule';

export class ScheduleDataMapper {

    public static fromDomain(option: ISchedule): EApiSchedule {
        switch( option.value ){
            case ESchedule.FULL_DAY:
                return EApiSchedule.TWENTY_FOUR_HOURS;
            case ESchedule.MORNING_AND_AFTERNOON:
                return EApiSchedule.MORNING_AND_AFTERNOON;
            case ESchedule.AFTERNOON_AND_EVENING:
                return EApiSchedule.AFTERNOON_AND_NIGHT;
            case ESchedule.ONLY_MORNING:
                return EApiSchedule.MORNING_ONLY;
            case ESchedule.ONLY_AFTERNOON:
                return EApiSchedule.AFTERNOON_ONLY;
            case ESchedule.ONLY_EVENING:
            default:
                return EApiSchedule.NIGHT_ONLY;
        }
    }

    public static fromResponse(option: EApiSchedule): ISchedule {
        switch( option ){
            case EApiSchedule.TWENTY_FOUR_HOURS:
                return {
                    value: ESchedule.FULL_DAY
                };
            case EApiSchedule.MORNING_AND_AFTERNOON:
                return {
                    value: ESchedule.MORNING_AND_AFTERNOON
                };
            case EApiSchedule.AFTERNOON_AND_NIGHT:
                return {
                    value: ESchedule.AFTERNOON_AND_EVENING
                };
            case EApiSchedule.MORNING_ONLY:
                return {
                    value: ESchedule.ONLY_MORNING
                };
            case EApiSchedule.AFTERNOON_ONLY:
                return {
                    value: ESchedule.ONLY_AFTERNOON
                };
            case EApiSchedule.NIGHT_ONLY:
            default:
                return {
                    value: ESchedule.ONLY_EVENING
                };
        }
    }

    public static arrayFromDomain(schedules: ISchedule[]): EApiSchedule[] {
        const schedulesResponse: EApiSchedule[] = [];

        schedules.forEach((schedule) => {
            schedulesResponse.push(this.fromDomain(schedule));
        });

        return schedulesResponse;
    }
}
