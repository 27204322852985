import { EService,IService,translateServiceDescription } from 'app/module/profile/domain/valueObject/Service';
import { EApiService } from 'app/common/data/remote/sexonApi/dto/ApiService';

export class ServiceDataMapper {

    public static fromDomain(service: EService): EApiService {
        switch (Number(service)) {
            case EService.CONTENT_SALE:
                return EApiService.CONTENT_SALE;
            case EService.SEXTING:
                return EApiService.SEXTING;
            case EService.VIDEO_CALLS:
                return EApiService.VIDEO_CALLS;
            case EService.ADULT_ACTOR_ACTRESS:
                return EApiService.ADULT_ACTOR_ACTRESS;
            case EService.STRIPTEASE:
                return EApiService.STRIPTEASE;
            case EService.DICK_RATE:
                return EApiService.DICK_RATE;
            case EService.VOYEURISM:
                return EApiService.VOYEURISM;
            case EService.VIRTUAL_PARTNER:
                return EApiService.VIRTUAL_PARTNER;
            case EService.DOMINATION:
                return EApiService.DOMINATION;
            case EService.FINANCIAL_DOMINATION:
                return EApiService.FINANCIAL_DOMINATION;
            case EService.VIRTUAL_DOMINATRIX:
                return EApiService.VIRTUAL_DOMINATRIX;
            case EService.FETISHES_GENERAL:
                return EApiService.FETISHES_GENERAL;
            case EService.FOOT_FETISH:
                return EApiService.FOOT_FETISH;
            case EService.CONTENT_EDITING_PRODUCTION:
                return EApiService.CONTENT_EDITING_PRODUCTION;
            case EService.MARKETING_PROMOTION:
                return EApiService.MARKETING_PROMOTION;
            case EService.SEXUAL_HEALTH_WELLNESS:
                return EApiService.SEXUAL_HEALTH_WELLNESS;
            case EService.EROTIC_MODEL:
                return EApiService.EROTIC_MODEL;
            case EService.SEXUAL_EVENTS_ORGANIZER:
                return EApiService.SEXUAL_EVENTS_ORGANIZER;
            case EService.SEXUAL_WORKSHOP_ORGANIZER:
                return EApiService.SEXUAL_WORKSHOP_ORGANIZER;
            case EService.COUPLES_THERAPY_SEXUALITY:
                return EApiService.COUPLES_THERAPY_SEXUALITY;
            case EService.SEXUAL_EDUCATOR:
                return EApiService.SEXUAL_EDUCATOR;
            case EService.EROTIC_PHOTOGRAPHER:
            default:
                return EApiService.EROTIC_PHOTOGRAPHER;
        }
    }

    public static arrayFromDomain(services: IService[]): EApiService[] {
        const servicesResponse: EApiService[] = [];

        services.forEach((service) => {
            servicesResponse.push(this.fromDomain(service.value));
        });

        return servicesResponse;
    }

    public static fromResponse(serviceValue: EApiService): EService {
        switch (serviceValue) {
            case EApiService.CONTENT_SALE:
                return EService.CONTENT_SALE;
            case EApiService.SEXTING:
                return EService.SEXTING;
            case EApiService.VIDEO_CALLS:
                return EService.VIDEO_CALLS;
            case EApiService.ADULT_ACTOR_ACTRESS:
                return EService.ADULT_ACTOR_ACTRESS;
            case EApiService.STRIPTEASE:
                return EService.STRIPTEASE;
            case EApiService.DICK_RATE:
                return EService.DICK_RATE;
            case EApiService.VOYEURISM:
                return EService.VOYEURISM;
            case EApiService.VIRTUAL_PARTNER:
                return EService.VIRTUAL_PARTNER;
            case EApiService.DOMINATION:
                return EService.DOMINATION;
            case EApiService.FINANCIAL_DOMINATION:
                return EService.FINANCIAL_DOMINATION;
            case EApiService.VIRTUAL_DOMINATRIX:
                return EService.VIRTUAL_DOMINATRIX;
            case EApiService.FETISHES_GENERAL:
                return EService.FETISHES_GENERAL;
            case EApiService.FOOT_FETISH:
                return EService.FOOT_FETISH;
            case EApiService.CONTENT_EDITING_PRODUCTION:
                return EService.CONTENT_EDITING_PRODUCTION;
            case EApiService.MARKETING_PROMOTION:
                return EService.MARKETING_PROMOTION;
            case EApiService.SEXUAL_HEALTH_WELLNESS:
                return EService.SEXUAL_HEALTH_WELLNESS;
            case EApiService.EROTIC_MODEL:
                return EService.EROTIC_MODEL;
            case EApiService.SEXUAL_EVENTS_ORGANIZER:
                return EService.SEXUAL_EVENTS_ORGANIZER;
            case EApiService.SEXUAL_WORKSHOP_ORGANIZER:
                return EService.SEXUAL_WORKSHOP_ORGANIZER;
            case EApiService.COUPLES_THERAPY_SEXUALITY:
                return EService.COUPLES_THERAPY_SEXUALITY;
            case EApiService.SEXUAL_EDUCATOR:
                return EService.SEXUAL_EDUCATOR;
            case EApiService.EROTIC_PHOTOGRAPHER:
            default:
                return EService.EROTIC_PHOTOGRAPHER;
        }
    }

    public static arrayFromResponse(servicesResponse: EApiService[]): IService[] {
        const services: IService[] = [];

        servicesResponse.forEach((serviceResponse) => {
            services.push({
                value: this.fromResponse(serviceResponse),
                description: translateServiceDescription(this.fromResponse(serviceResponse))
            });
        });

        return services;
    }
}
