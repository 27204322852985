import React,{ useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { InfoIcon } from 'assets/icon/sexon/svg/icons';
import { ToastInfoActions } from 'app/common/presentation/redux/toast/ToastInfoSlice';
import { ESexonToastType,SexonToast } from '../../toast/SexonToast';
import { useToastInputReduxState } from '../../../redux/toast/hook/useToastInfoReduxState';

export const InfoToast = (): JSX.Element | null => {
    const [isClosing, setIsClosing] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const info = useToastInputReduxState();
    const dispatch = useDispatch();

    const delayClose = (): void => {
        setIsClosing(true);
        setTimeout((): void => {
            dispatch(ToastInfoActions.setInfo(''));
            setOpenModal(false);
            setIsClosing(false);
        }, 300);
    };

    useEffect(() => {
        if(info !== ''){
            setOpenModal(true);
        } else {
            delayClose();
        }
    }, [info]);

    return openModal ? (
        <SexonToast
            message={info}
            icon={<InfoIcon />}
            onClose={delayClose}
            isClosing={isClosing}
            type={ESexonToastType.INFO}
            className="component-info-toast"
        />
    ) : null;
};
