import ICityRepository from 'app/module/profile/domain/interface/CityRepository';
import IProvince from 'app/module/profile/domain/entity/Province';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import ICity from 'app/module/profile/domain/entity/City';
import { GetCitiesDataMapper } from 'app/module/profile/data/repository/api/city/dataMapper/GetCitiesDataMapper';


export class ApiCityRepository implements ICityRepository{

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async findAll(
        province: IProvince,
        page?: number,
        limit?: number,
        search?: string,
    ): Promise<ICity[]> {
        try {
            const citiesData = await this._sexonApiClient.getCities(
                {
                    provinceId: province.id,
                    page,
                    limit,
                    search,
                }
            );

            return GetCitiesDataMapper.fromResponse(citiesData);
        } catch (error){
            return Promise.reject(error);
        }
    }
}
