import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false
};

const slice = createSlice({
    name: 'fullScreenSpinnerLoading',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const FullScreenSpinnerLoadingReducer = slice.reducer;
export const FullScreenSpinnerLoadingActions = slice.actions;
