import { createSlice,PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isFirstTime: {
        value: false,
    }
};

const slice = createSlice({
    name: 'isFirstTime',
    initialState,
    reducers: {
        setIsFirstTime: (state, action: PayloadAction<boolean>) => {
            state.isFirstTime.value = action.payload;
        },
    },
});

export const IsFistTimeReducer = slice.reducer;
export const IsFistTimeActions = slice.actions;
