import React from 'react';
import './SexonInputErrorMessage.scss';

interface ISexonInputErrorMessageProps {
    message: string
    showError: boolean
}

export const SexonInputErrorMessage = (props: ISexonInputErrorMessageProps): JSX.Element | null => {
    return props.showError ? (
        <div className="component-input-error">
            <p
                dangerouslySetInnerHTML={ { __html: props.message } }
            />
        </div>
    ) : null;
};
