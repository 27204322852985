import IProfileRepository from 'app/module/profile/domain/interface/ProfileRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { IProfile } from 'app/module/profile/domain/entity/Profile';

interface IShowProfileUseCaseProps {
    auth: IAuth
    profile: IProfile
    profileRepository: IProfileRepository
}

export default async (props: IShowProfileUseCaseProps): Promise<void> => {
    try {
        return await props.profileRepository.showProfile(
            props.profile,
            props.auth
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
