import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './SexonInput.scss';
import { InfoIcon } from 'assets/icon/sexon/svg/icons';
import cx from 'classnames';
import setToastInfoUserCase from 'app/common/domain/useCase/SetToastInfoUseCase';
import { SexonButton } from '../button/SexonButton';

interface ISexonInputProps {
    value?: any
    step?: number
    onClick?: any
    infoText?: string
    checked?: boolean
    icon?: JSX.Element
    minLength?: number
    maxLength?: number
    className?: string
    disabled?: boolean
    isRequired?: boolean
    placeHolder?: string
    defaultValue?: string
    autocomplete?: string
    watchRegister?: string
    id: string | undefined
    defaultChecked?: boolean
    min?: string | number | undefined
    max?: string | number | undefined
    register?: UseFormRegisterReturn
    type: React.HTMLInputTypeAttribute
}

export const SexonInput = (props: ISexonInputProps): React.ReactElement => {
    const inputClassNames = cx({
        'custom-input-basic': true,
        'no-icon': !props.icon
    });

    const showInfo = (): void => {
        if(props.infoText){
            setToastInfoUserCase(props.infoText);
        }
    };

    return (
        <div className="component-input">
            <div
                onClick={props.onClick}
                className={props.disabled ? 'basic-input-wrapper is-disabled' : 'basic-input-wrapper'}
            >
                {props.icon ? (
                    <label
                        className={
                            props.isRequired ?
                                'basic-input-icon-container is-required' :
                                'basic-input-icon-container'
                        }
                        htmlFor={props.id}
                    >
                        {props.icon}
                    </label>
                ) : null}
                <input
                    id={props.id}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    type={props.type}
                    {...props.register}
                    value={props.value}
                    onClick={props.onClick}
                    checked={props.checked}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    className={inputClassNames}
                    defaultValue={props.defaultValue}
                    autoComplete={props.autocomplete}
                    defaultChecked={props.defaultChecked}
                    placeholder={
                        props.type === 'date' || props.type === 'time' ?
                            `${props.placeHolder} \u00A0`
                            : props.placeHolder}
                />

                {props.infoText ? (
                    <SexonButton
                        type='button'
                        onClick={showInfo}
                        title={<InfoIcon />}
                        className="info-input-button"
                    />
                ) : null}
            </div>
            {props.maxLength ? (
                <span className="basic-input-characters-container">
                    {`${props.watchRegister ? props.watchRegister.length : 0} / ${props.maxLength}`}
                </span>
            ) : null}
        </div>
    );
};
