import { createSlice } from '@reduxjs/toolkit';
import { ECategory,ICategory } from 'app/module/profile/domain/valueObject/Category';

const initialState = {
    categories: [
        { value: ECategory.HETEROSEXUAL_MEN },
        { value: ECategory.HETEROSEXUAL_WOMEN },
        { value: ECategory.HOMOSEXUAL_WOMEN },
        { value: ECategory.HOMOSEXUAL_MEN },
    ] as ICategory[]
};

const slice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
});

export const CategoriesReducer = slice.reducer;
export const CategoriesActions = slice.actions;
