import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { AuthActions } from 'app/common/presentation/redux/auth/AuthSlice';
import { FeedFiltersActions } from 'app/common/presentation/redux/feed/FeedFiltersSlice';
import { FeedActions } from 'app/common/presentation/redux/feed/FeedSlice';
import { CurrentProfileActions } from 'app/common/presentation/redux/currentProfile/CurrentProfileSlice';
import { FeedLastPostIndexActions } from 'app/common/presentation/redux/feed/FeedLastPostIndexSlice';
import { ShowSettingsModalActions } from 'app/common/presentation/redux/settingsModal/ShowSettingsModalSlice';
import { PhoneIsVerifiedActions } from 'app/common/presentation/redux/phoneIsVerified/PhoneIsVerifySlice';

interface ILogOutUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: ILogOutUseCaseProps): Promise<void> => {
    try {
        await props.storageRepository.logOut();
        ReduxStore.dispatch(AuthActions.reset());
        ReduxStore.dispatch(FeedActions.reset());
        ReduxStore.dispatch(FeedFiltersActions.reset());
        ReduxStore.dispatch(CurrentProfileActions.reset());
        ReduxStore.dispatch(FeedLastPostIndexActions.reset());
        ReduxStore.dispatch(ShowSettingsModalActions.reset());
        ReduxStore.dispatch(PhoneIsVerifiedActions.setPhoneIsVerified(true));

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};
