import i18next from 'i18next';
import {
    AFTERNOON_AND_EVENING_LABEL,
    FULL_DAY_LABEL,
    MORNING_AND_AFTERNOON_LABEL,
    ONLY_AFTERNOON_LABEL,
    ONLY_EVENING_LABEL,
    ONLY_MORNING_LABEL
} from 'translations/tr';

export interface ISchedule {
    value: ESchedule
}

export enum ESchedule {
    FULL_DAY = 'FULL_DAY',
    MORNING_AND_AFTERNOON = 'MORNING_AND_AFTERNOON',
    AFTERNOON_AND_EVENING = 'AFTERNOON_AND_EVENING',
    ONLY_MORNING = 'ONLY_MORNING',
    ONLY_AFTERNOON = 'ONLY_AFTERNOON',
    ONLY_EVENING = 'ONLY_EVENING',
}

export const translateSchedule = (value: ESchedule): string => {
    switch( value ){
        case ESchedule.FULL_DAY:
            return i18next.t(FULL_DAY_LABEL);
        case ESchedule.MORNING_AND_AFTERNOON:
            return i18next.t(MORNING_AND_AFTERNOON_LABEL);
        case ESchedule.AFTERNOON_AND_EVENING:
            return i18next.t(AFTERNOON_AND_EVENING_LABEL);
        case ESchedule.ONLY_MORNING:
            return i18next.t(ONLY_MORNING_LABEL);
        case ESchedule.ONLY_AFTERNOON:
            return i18next.t(ONLY_AFTERNOON_LABEL);
        case ESchedule.ONLY_EVENING:
        default:
            return i18next.t(ONLY_EVENING_LABEL);
    }
};
