import React,{ useContext } from 'react';
import {
    CANCEL,
    SEND,
    SUGGESTIONS_MODAL_CONTACT_INSTRUCTIONS,
    SUGGESTIONS_MODAL_INSTRUCTIONS,
    SUGGESTIONS_MODAL_TITLE,
    SUGGESTIONS_SUCCESSFUL_MESSAGE
} from 'translations/tr';
import { useTranslation } from 'react-i18next';
import './SuggestionModal.scss';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { SuggestionsModalActions } from 'app/common/presentation/redux/suggestionsModal/SuggestionsModalSlice';
import setFullScreenSpinnerLoadingUserCase from 'app/common/domain/useCase/SetFullScreenSpinnerLoadingUseCase';
import sendSuggestionsUserCase from 'app/module/suggestion/domain/useCase/SendSuggestionsUseCase';
import setToastSuccessfulUserCase from 'app/common/domain/useCase/SetToastSuccessfulUseCase';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { translateApiError } from 'app/common/domain/entity/Error';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { ISuggestionsFormData } from 'app/common/presentation/component/popUpManager/suggestionModal/form/SuggestionsFormData';
import { SexonModal } from 'app/common/presentation/component/modal/SexonModal';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { useAuthReduxState } from '../../../redux/auth/hook/useAuthReduxState';
import { useSuggestionsModalReduxState } from '../../../redux/suggestionsModal/hook/useSuggestionsModalReduxState';
import { SuggestionForm } from './form/SuggestionForm';

export const SuggestionModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const auth = useAuthReduxState();
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
    const showModal = useSuggestionsModalReduxState();
    const dic = useContext(DependencyInjectionContext);
    const UseSuggestionsModalForm = useForm<ISuggestionsFormData>(
        {
            defaultValues: {
                suggestions: '',
            },
            shouldFocusError: true
        }
    );

    const handleCloseModal = (): void => {
        UseSuggestionsModalForm.reset();
        dispatch(SuggestionsModalActions.handleHideSuggestionsModal());
    };

    const onSubmit = UseSuggestionsModalForm.handleSubmit((data)=> {
        setFullScreenSpinnerLoadingUserCase(true);
        sendSuggestionsUserCase({
            auth,
            suggestionsFormData: data,
            suggestionRepository: dic.suggestionRepository
        }).then(()=> {
            setToastSuccessfulUserCase(t(SUGGESTIONS_SUCCESSFUL_MESSAGE));
        }).catch((error)=>{
            setToastErrorUserCase(translateApiError(error));
        }).finally(()=> {
            setFullScreenSpinnerLoadingUserCase(false);
            handleCloseModal();
        });
    });

    return (
        <SexonModal
            show={ showModal }
            onClose={handleCloseModal}
            classname="component-suggestion-modal"
            headerTitle={ t(SUGGESTIONS_MODAL_TITLE) }
            body={
                <div className="suggestion-modal-body-container">
                    <p dangerouslySetInnerHTML={{ __html: t(SUGGESTIONS_MODAL_INSTRUCTIONS).toString() }} />
                    <SuggestionForm
                        UseSuggestionsModalForm={UseSuggestionsModalForm}
                    />
                    <p dangerouslySetInnerHTML={{ __html: t(SUGGESTIONS_MODAL_CONTACT_INSTRUCTIONS, { supportEmail }).toString() }} />
                </div>
            }
            footer={ (
                <div className="suggestion-modal-footer-container">
                    <SexonButton
                        title={ (
                            <span>
                                { t(CANCEL) }
                            </span>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        onClick={handleCloseModal}
                    />
                    <SexonButton
                        title={ (
                            <span>
                                { t(SEND) }
                            </span>
                        ) }
                        type="button"
                        styling={ESexonButtonStyle.FILLED}
                        onClick={onSubmit}
                    />
                </div>
            ) }
        />
    );
};
