import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ELanguage } from 'app/common/domain/valueObject/Language';

interface ISetLanguageUseCaseProps {
    lang: ELanguage
    storageRepository: IStorageRepository
}

export default async (props: ISetLanguageUseCaseProps): Promise<void> => {
    try {
        return await props.storageRepository.setLanguage({
            value: props.lang
        });
    } catch (error) {
        return Promise.reject(error);
    }
};
