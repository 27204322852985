import { IProfile } from 'app/module/profile/domain/entity/Profile';
import { useCurrentProfileReduxState } from '../redux/currentProfile/hook/useCurrentProfileReduxState';

interface IUseCurrentProfileResponse {
    profileIsCreated: boolean
    currentProfile: IProfile
}
export const useCurrentProfile = (): IUseCurrentProfileResponse => {
    const currentProfile = useCurrentProfileReduxState();

    return {
        profileIsCreated: currentProfile.id !== '',
        currentProfile
    };
};
