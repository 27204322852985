import React,{ lazy,Suspense,useContext,useEffect } from 'react';
import { Navigate,Route,Routes } from 'react-router-dom';
import { EAppRoute } from 'app/common/presentation/routing/AppRoute';
import { useLocation } from 'react-router';
import trackPageViewUseCase from 'app/common/domain/useCase/TrackPageViewUseCase';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { BasicHelmet } from 'app/common/presentation/component/helmet/basicHelmet/BasicHelmet';
import { NoIndexHelmet } from 'app/common/presentation/component/helmet/authHelmet/NoIndexHelmet';
import { useAuth } from '../hook/useAuth';
import { useCurrentProfile } from '../hook/useCurrentProfile';
import { LazyFallbackSpinner } from '../component/spinner/lazyFallbackSpinner/LazyFallbackSpinner';

export const AppRouting = (): JSX.Element => {
    const { pathname } = useLocation();
    const UseAuth = useAuth();
    const dic = useContext(DependencyInjectionContext);
    const UseCurrentProfile = useCurrentProfile();

    const LoginScreen = lazy(()=> import('../screen/login/LoginScreen'));
    const SignUpScreen = lazy(()=> import('../screen/signUp/SignUpScreen'));
    const LayoutTemplate = lazy(()=> import('../template/layout/LayoutTemplate'));
    const CreateProfileScreen = lazy(()=> import('../screen/createProfile/CreateProfileScreen'));
    const VerifyAccountScreen = lazy(()=> import('../screen/verifyAccount/VerifyAccountScreen'));
    const ResetPasswordScreen = lazy(()=> import('../screen/resetPassword/ResetPasswordScreen'));
    const EditProfileScreen = lazy(()=> import('../screen/editProfile/EditProfileScreen'));
    const DeleteAccountScreen = lazy(()=> import('../screen/deleteAccount/DeleteAccountScreen'));
    const HideProfileScreen = lazy(()=> import('../screen/hideProfile/HideProfileScreen'));
    const EditEmailScreen = lazy(()=> import('../screen/editEmail/EditEmailScreen'));
    const EditPhoneScreen = lazy(()=> import('../screen/editPhone/EditPhoneScreen'));
    const ValidateEmailChangeScreen = lazy(()=> import('../screen/validateEmailChange/ValidateEmailChangeScreen'));
    const HighlightProfileScreen = lazy(()=> import('../screen/highlightProfile/HighlightProfileScreen'));
    const PaymentOkScreen = lazy(()=> import('../screen/paymentOk/PaymentOkScreen'));
    const PaymentKoScreen = lazy(()=> import('../screen/paymentKo/PaymentKoScreen'));

    useEffect(() => {
        trackPageViewUseCase({
            page: pathname,
            analyticsRepository: dic.analyticsRepository
        });
    },[pathname]);

    return (
        <Routes>
            <Route
                path={EAppRoute.SIGN_IN}
                element={
                    UseAuth.isAuthed ? (
                        <Navigate
                            to={{
                                pathname: EAppRoute.HOME
                            }}
                        />
                    ) : (
                        <Suspense fallback={<LazyFallbackSpinner />}>
                            <LoginScreen />
                        </Suspense>
                    )
                }
            />
            <Route
                path={EAppRoute.SIGN_UP}
                element={
                    UseAuth.isAuthed ? (
                        <Navigate
                            to={{
                                pathname: EAppRoute.HOME
                            }}
                        />
                    ) : (
                        <Suspense fallback={<LazyFallbackSpinner />}>
                            <SignUpScreen />
                        </Suspense>
                    )
                }
            />
            <Route
                path={EAppRoute.CREATE_PROFILE}
                element={
                    UseAuth.isAuthed && !UseCurrentProfile.profileIsCreated ? (
                        <Suspense fallback={<LazyFallbackSpinner />}>
                            <BasicHelmet />
                            <NoIndexHelmet />
                            <CreateProfileScreen />
                        </Suspense>
                    ) : (
                        <Navigate
                            to={{
                                pathname: EAppRoute.HOME
                            }}
                        />
                    )
                }
            />
            <Route
                path={EAppRoute.EDIT_PROFILE}
                element={
                    UseCurrentProfile.profileIsCreated  ? (
                        <Suspense fallback={<LazyFallbackSpinner />}>
                            <BasicHelmet />
                            <NoIndexHelmet />
                            <EditProfileScreen />
                        </Suspense>
                    ) : (
                        <Navigate
                            to={{
                                pathname: EAppRoute.HOME
                            }}
                        />
                    )
                }
            />
            <Route
                path={EAppRoute.VERIFY_ACCOUNT}
                element={
                    <Suspense fallback={<LazyFallbackSpinner />}>
                        <BasicHelmet />
                        <NoIndexHelmet />
                        <VerifyAccountScreen />
                    </Suspense>
                }
            />
            <Route
                path={EAppRoute.VALIDATE_EMAIL_CHANGE}
                element={
                    <Suspense fallback={<LazyFallbackSpinner />}>
                        <BasicHelmet />
                        <NoIndexHelmet />
                        <ValidateEmailChangeScreen />
                    </Suspense>
                }
            />
            <Route
                path={EAppRoute.RESET_PASSWORD}
                element={(
                    <Suspense fallback={<LazyFallbackSpinner />}>
                        <BasicHelmet />
                        <NoIndexHelmet />
                        <ResetPasswordScreen />
                    </Suspense>
                )}
            />
            <Route
                path={EAppRoute.DELETE_ACCOUNT}
                element={
                    // TODO (Álvaro) This is only for now, change in the future for UseAuth.isAuthed
                    UseCurrentProfile.profileIsCreated ? (
                        <Suspense fallback={<LazyFallbackSpinner />}>
                            <BasicHelmet />
                            <NoIndexHelmet />
                            <DeleteAccountScreen />
                        </Suspense>
                    ) : (
                        <Navigate
                            to={{
                                pathname: EAppRoute.HOME
                            }}
                        />
                    )
                }
            />
            <Route
                path={EAppRoute.HIDE_PROFILE}
                element={
                    UseCurrentProfile.profileIsCreated
                    && UseCurrentProfile.currentProfile.isEnabled ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <HideProfileScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route
                path={EAppRoute.EDIT_EMAIL}
                element={
                    // TODO (Álvaro) This is only for now, change in the future for UseAuth.isAuthed
                    UseCurrentProfile.profileIsCreated ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <EditEmailScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route
                path={EAppRoute.EDIT_PHONE}
                element={
                    UseAuth.isAuthed ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <EditPhoneScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route
                path={EAppRoute.HIGHLIGHT_PROFILE}
                element={
                    UseCurrentProfile.profileIsCreated ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <HighlightProfileScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route
                path={EAppRoute.PAYMENT_OK}
                element={
                    UseCurrentProfile.profileIsCreated ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <PaymentOkScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route
                path={EAppRoute.PAYMENT_KO}
                element={
                    UseCurrentProfile.profileIsCreated ?
                        (
                            <Suspense fallback={<LazyFallbackSpinner />}>
                                <BasicHelmet />
                                <NoIndexHelmet />
                                <PaymentKoScreen />
                            </Suspense>
                        ) : (
                            <Navigate
                                to={{
                                    pathname: EAppRoute.HOME
                                }}
                            />
                        )
                }
            />
            <Route path='*' element={
                <Suspense fallback={<LazyFallbackSpinner />}>
                    <LayoutTemplate />
                </Suspense>
            }/>
        </Routes>
    );
};
