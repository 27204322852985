import { EApiCategory } from 'app/common/data/remote/sexonApi/dto/ApiCategory';
import { ECategory,ICategory } from 'app/module/profile/domain/valueObject/Category';

export class CategoryDataMapper {

    public static fromDomain(gender: ECategory): EApiCategory {
        switch( gender ){
            case ECategory.HETEROSEXUAL_MEN:
                return EApiCategory.HETEROSEXUAL_MEN;
            case ECategory.HETEROSEXUAL_WOMEN:
                return EApiCategory.HETEROSEXUAL_WOMEN;
            case ECategory.HOMOSEXUAL_MEN:
                return EApiCategory.HOMOSEXUAL_MEN;
            case ECategory.HOMOSEXUAL_WOMEN:
            default:
                return EApiCategory.HOMOSEXUAL_WOMEN;
        }
    }

    public static arrayFromDomain(categories: ICategory[]): EApiCategory[] {
        const categoriesResponse: EApiCategory[] = [];

        categories.forEach((service) => {
            categoriesResponse.push(this.fromDomain(service.value));
        });

        return categoriesResponse;
    }

    public static fromResponse(gender: EApiCategory): ICategory {
        switch( gender ){
            case EApiCategory.HETEROSEXUAL_MEN:
                return {
                    value: ECategory.HETEROSEXUAL_MEN
                };
            case EApiCategory.HETEROSEXUAL_WOMEN:
                return {
                    value: ECategory.HETEROSEXUAL_WOMEN
                };
            case EApiCategory.HOMOSEXUAL_MEN:
                return {
                    value: ECategory.HOMOSEXUAL_MEN
                };
            case EApiCategory.HOMOSEXUAL_WOMEN:
            default:
                return {
                    value: ECategory.HOMOSEXUAL_WOMEN
                };
        }
    }

    public static arrayFromResponse(categoriesResponse: EApiCategory[]): ICategory[] {
        const categories: ICategory[] = [];

        categoriesResponse.forEach((categoryResponse) => {
            categories.push(this.fromResponse(categoryResponse));
        });

        return categories;
    }
}
