import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { CookiesConsentActions } from 'app/common/presentation/redux/cookiesConsent/CookiesConsentSlice';

interface IGetCookiesConsentUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: IGetCookiesConsentUseCaseProps): Promise<void> => {
    try {
        const cookiesConsent = await props.storageRepository.getCookiesConsent();
        if(cookiesConsent){
            ReduxStore.dispatch(CookiesConsentActions.setConsent(cookiesConsent));
        }

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};
