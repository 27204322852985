import i18next from 'i18next';
import { GENERIC_API_ERROR } from 'translations/tr';

export enum EErrorCode {
    INVALID_ULID = 'INVALID_ULID',
    INVALID_TOKEN = 'INVALID_TOKEN',
    INVALID_EMAIL = 'INVALID_EMAIL',
    INVALID_PHONE = 'INVALID_PHONE',
    FILE_TOO_LARGE = 'FILE_TOO_LARGE',
    POST_NOT_FOUND = 'POST_NOT_FOUND',
    IMAGE_TOO_SMALL = 'IMAGE_TOO_SMALL',
    PROFILE_DISABLED = 'PROFILE_DISABLED',
    AUTH_VALIDATE_SMS = 'AUTH_VALIDATE_SMS',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
    AUTH_VALIDATE_EMAIL = 'AUTH_VALIDATE_EMAIL',
    POST_ALREADY_EXISTS = 'POST_ALREADY_EXISTS',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    UNSUPPORTED_MIME_TYPE = 'UNSUPPORTED_MIME_TYPE',
    REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
    PROFILE_ALREADY_EXISTS = 'PROFILE_ALREADY_EXISTS',
    PROFILE_USERNAME_TAKEN = 'PROFILE_USERNAME_TAKEN',
    AUTH_INVALID_SMS_TOKEN = 'AUTH_INVALID_SMS_TOKEN',
    UNEXPECTED_EMPTY_VALUE = 'UNEXPECTED_EMPTY_VALUE',
    REFRESH_TOKEN_NOT_FOUND = 'REFRESH_TOKEN_NOT_FOUND',
    AUTH_INVALID_CREDENTIALS = 'AUTH_INVALID_CREDENTIALS',
    EMPTY_PASSWORD_NOT_ALLOWED = 'EMPTY_PASSWORD_NOT_ALLOWED',
    AT_LEAST_ONE_POST_REQUIRED = 'AT_LEAST_ONE_POST_REQUIRED',
    POST_DOESNT_BELONG_TO_PROFILE = 'POST_DOESNT_BELONG_TO_PROFILE',
    PROFILE_DOESNT_BELONG_TO_USER = 'PROFILE_DOESNT_BELONG_TO_USER',
    SUBSCRIPTION_OPTION_NOT_FOUND = 'SUBSCRIPTION_OPTION_NOT_FOUND',
}
export default interface IError {
    errorCode: EErrorCode
    statusCode: number
}


export const translateApiError = (error: string): string => {
    const errorValue = EErrorCode[error as keyof typeof EErrorCode];

    if (errorValue !== undefined) {
        return i18next.t(errorValue);
    }
    return i18next.t(GENERIC_API_ERROR);
};
