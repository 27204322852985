import { EReportReason } from 'app/module/profile/domain/valueObject/ReportReason';
import { EApiReportReason } from 'app/common/data/remote/sexonApi/dto/ApiReportReason';

export class ReportReasonDataMapper {

    public static fromDomain(reason: EReportReason): EApiReportReason {
        switch( reason ){
            case EReportReason.SEXUAL_OFFENSES:
                return EApiReportReason.SEXUAL_OFFENSES;
            case EReportReason.OTHER:
            default:
                return EApiReportReason.OTHER;
        }
    }

    public static fromResponse(reason: EApiReportReason): EReportReason {
        switch( reason ){
            case EApiReportReason.SEXUAL_OFFENSES:
                return EReportReason.SEXUAL_OFFENSES;
            case EApiReportReason.OTHER:
            default:
                return EReportReason.OTHER;
        }
    }
}
