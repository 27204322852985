import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileIcon } from 'assets/icon/sexon/svg/icons';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FULL_NAME } from 'translations/tr';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { SexonInput } from 'app/common/presentation/component/input/SexonInput';

interface IUserNameFormFieldProps {
    showError: boolean
    errorMessage: string
    watchFullName: string
    register: UseFormRegisterReturn
}
export const FullNameFormField = (props: IUserNameFormFieldProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <SexonInput
                isRequired
                type="text"
                maxLength={40}
                autocomplete="off"
                id="fullNameFormField"
                icon={<ProfileIcon />}
                register={props.register}
                placeHolder={t(FULL_NAME)}
                watchRegister={props.watchFullName}
            />
            <SexonInputErrorMessage
                showError={props.showError}
                message={props.errorMessage}
            />
        </>
    );
};
