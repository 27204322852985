import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    toastErrorMessage: ''
};

const slice = createSlice({
    name: 'toastErrorMessage',
    initialState,
    reducers: {
        setToastErrorMessage: (state, action: PayloadAction<string>) => {
            state.toastErrorMessage = action.payload;
        },
    },
});

export const ToastErrorReducer = slice.reducer;
export const ToastErrorActions = slice.actions;
