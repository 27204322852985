import { createSlice } from '@reduxjs/toolkit';
import { ESchedule,ISchedule } from 'app/module/profile/domain/valueObject/Schedule';

const initialState = {
    schedules: [
        { value: ESchedule.FULL_DAY },
        { value: ESchedule.MORNING_AND_AFTERNOON },
        { value: ESchedule.AFTERNOON_AND_EVENING },
        { value: ESchedule.ONLY_MORNING },
        { value: ESchedule.ONLY_AFTERNOON },
        { value: ESchedule.ONLY_EVENING },
    ] as ISchedule[]
};

const slice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {},
});

export const SchedulesReducer = slice.reducer;
export const SchedulesActions = slice.actions;
