import IStorageRepository from 'app/common/domain/interface/StorageRepository';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { AgeConsentActions } from 'app/common/presentation/redux/ageConsent/AgeConsentSlice';

interface IGetAgeConsentUseCaseProps {
    storageRepository: IStorageRepository
}

export default async (props: IGetAgeConsentUseCaseProps): Promise<void> => {
    try {
        const hasConsent = await props.storageRepository.getAgeConsent();
        if(hasConsent){
            ReduxStore.dispatch(AgeConsentActions.setHasConsent(true));
        }

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};
