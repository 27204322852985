import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showSettingModal',
    initialState,
    reducers: {
        handleShowSettingModal: (state) => {
            state.showModal = true;
        },
        handleHideSettingModal: (state) => {
            state.showModal = false;
        },
        reset: (state) => {
            state.showModal = false;
        }
    },
});

export const ShowSettingsModalReducer = slice.reducer;
export const ShowSettingsModalActions = slice.actions;
