import { createSlice } from '@reduxjs/toolkit';
import { EHairColor,IHairColor } from 'app/module/profile/domain/valueObject/HairColor';

const initialState = {
    hairColors: [
        { value: EHairColor.BRUNETTE },
        { value: EHairColor.BROWN_HAIRED },
        { value: EHairColor.BLONDE },
        { value: EHairColor.REDHEAD },
    ] as IHairColor[]
};

const slice = createSlice({
    name: 'hairColors',
    initialState,
    reducers: {},
});

export const HairColorsReducer = slice.reducer;
export const HairColorsActions = slice.actions;
