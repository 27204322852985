import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import INationality from 'app/module/profile/domain/entity/Nationality';

const initialState = {
    nationalities: [] as INationality[]
};

const slice = createSlice({
    name: 'nationalities',
    initialState,
    reducers: {
        setNationalities: (state, action: PayloadAction<INationality[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.nationalities = action.payload;
        },
    },
});

export const NationalityReducer = slice.reducer;
export const NationalityActions = slice.actions;
