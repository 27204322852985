import React from 'react';
import './SexonSpinner.scss';

export const SexonSpinner = (): JSX.Element => {
    return (
        <div className="component-spinner">
            <div className="dot-item" />
        </div>
    );
};
