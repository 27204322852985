export enum EApiService {
    CONTENT_SALE = 'CONTENT_SALE',
    SEXTING = 'SEXTING',
    VIDEO_CALLS = 'VIDEO_CALLS',
    ADULT_ACTOR_ACTRESS = 'ADULT_ACTOR_ACTRESS',
    STRIPTEASE = 'STRIPTEASE',
    DICK_RATE = 'DICK_RATE',
    VOYEURISM = 'VOYEURISM',
    VIRTUAL_PARTNER = 'VIRTUAL_PARTNER',
    DOMINATION = 'DOMINATION',
    FINANCIAL_DOMINATION = 'FINANCIAL_DOMINATION',
    VIRTUAL_DOMINATRIX = 'VIRTUAL_DOMINATRIX',
    FETISHES_GENERAL = 'FETISHES_GENERAL',
    FOOT_FETISH = 'FOOT_FETISH',
    CONTENT_EDITING_PRODUCTION = 'CONTENT_EDITING_PRODUCTION',
    MARKETING_PROMOTION = 'MARKETING_PROMOTION',
    SEXUAL_HEALTH_WELLNESS = 'SEXUAL_HEALTH_WELLNESS',
    EROTIC_MODEL = 'EROTIC_MODEL',
    SEXUAL_EVENTS_ORGANIZER = 'SEXUAL_EVENTS_ORGANIZER',
    SEXUAL_WORKSHOP_ORGANIZER = 'SEXUAL_WORKSHOP_ORGANIZER',
    COUPLES_THERAPY_SEXUALITY = 'COUPLES_THERAPY_SEXUALITY',
    SEXUAL_EDUCATOR = 'SEXUAL_EDUCATOR',
    EROTIC_PHOTOGRAPHER = 'EROTIC_PHOTOGRAPHER',
}
