import React from 'react';
import './SexonButton.scss';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';

interface ISexonButtonProps {
    id?: string
    onClick?: any
    className?: string
    disabled?: boolean
    styling?: ESexonButtonStyle
    title: string | number | JSX.Element
    type: 'submit' | 'reset' | 'button'| undefined
}

export const SexonButton = (props: ISexonButtonProps): JSX.Element => {
    return (
        <button
            id={props.id}
            /* eslint-disable-next-line react/button-has-type */
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
            className={`component-button ${props.styling ?? ESexonButtonStyle.BASIC} ${props.className ?? ''}`}
        >
            {props.title}
        </button>
    );
};
