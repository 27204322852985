import i18next from 'i18next';
import { BLONDE_LABEL,BROWN_HAIRED_LABEL,BRUNETTE_LABEL,REDHEAD_LABEL } from 'translations/tr';

export interface IHairColor {
    value: EHairColor
}

export enum EHairColor {
    BRUNETTE = 'BRUNETTE',
    BROWN_HAIRED = 'BROWN_HAIRED',
    BLONDE = 'BLONDE',
    REDHEAD = 'REDHEAD',
}

export const translateHairColor = (value: EHairColor): string => {
    switch( value ){
        case EHairColor.BROWN_HAIRED:
            return i18next.t(BROWN_HAIRED_LABEL);
        case EHairColor.BLONDE:
            return i18next.t(BLONDE_LABEL);
        case EHairColor.BRUNETTE:
            return i18next.t(BRUNETTE_LABEL);
        case EHairColor.REDHEAD:
        default:
            return i18next.t(REDHEAD_LABEL);
    }
};
