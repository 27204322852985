import { EReportReason } from 'app/module/profile/domain/valueObject/ReportReason';

export interface IReportFormData {
    description: string
    reason: EReportReason
    email: string | null
    fullName: string | null
    consentToGoodFaithAcceptance: boolean
    legalNoticeAndPrivacyAcceptance: boolean
}

export enum EReportFormDataName {
    EMAIL= 'email',
    REASON = 'reason',
    FULL_NAME = 'fullName',
    DESCRIPTION = 'description',
    CONSENT_TO_GOOD_FAITH_ACCEPTANCE= 'consentToGoodFaithAcceptance',
    LEGAL_NOTICE_AND_PRIVACY_ACCEPTANCE = 'legalNoticeAndPrivacyAcceptance',
}
