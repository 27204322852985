import { IAuth } from 'app/module/auth/domain/entity/Auth';
import IPaymentSummaryRepository from 'app/module/payment/domain/interface/PaymentSummaryRepository';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { IPaymentSummary } from 'app/module/payment/domain/entity/PaymentSummary';
import { PaymentSummaryDataMapper } from 'app/module/payment/data/repository/api/dataMapper/PaymentSummaryDataMapper';


export class ApiPaymentSummaryRepository implements IPaymentSummaryRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ){}

    public async find(
        auth: IAuth,
        subscriptionOptionId: string
    ): Promise<IPaymentSummary> {
        try {
            const response = await this._sexonApiClient.getPaymentSummary({
                token: auth.token.value,
                subscriptionOptionId
            });

            return PaymentSummaryDataMapper.fromResponse(response);
        } catch (error){
            return Promise.reject(error);
        }
    };

}
