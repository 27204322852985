import { createSlice,PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    ageConsent: {
        value: false,
    }
};

const slice = createSlice({
    name: 'ageConsent',
    initialState,
    reducers: {
        setHasConsent: (state, action: PayloadAction<boolean>) => {
            state.ageConsent.value = action.payload;
        },
    },
});

export const AgeConsentReducer = slice.reducer;
export const AgeConsentActions = slice.actions;
