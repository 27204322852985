import INationalityRepository from 'app/module/profile/domain/interface/NationalityRepository';
import INationality from 'app/module/profile/domain/entity/Nationality';

interface IGetNationalitiesUseCaseProps {
    nationalityRepository: INationalityRepository
}

export default async (props: IGetNationalitiesUseCaseProps): Promise<INationality[]> => {
    try {
        return await props.nationalityRepository.findAll();
    } catch (error) {
        return Promise.reject(error);
    }
};
