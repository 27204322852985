import { useAuthReduxState } from '../redux/auth/hook/useAuthReduxState';

interface IUseAuthResponse {
    isAuthed: boolean
}
export const useAuth = (): IUseAuthResponse => {
    const auth = useAuthReduxState();

    return {
        isAuthed: auth.token.value !== ''
    };
};
