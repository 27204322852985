import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    info: ''
};

const slice = createSlice({
    name: 'inputInfo',
    initialState,
    reducers: {
        setInfo: (state, action: PayloadAction<string>) => {
            state.info = action.payload;
        },
    },
});

export const ToastInfoReducer = slice.reducer;
export const ToastInfoActions = slice.actions;
