export interface IAuth {
    token: IToken
    refreshToken: string
}

export interface IToken {
    value: string
    expirationDate: number
    emittedDate: number
    id: string
}


export const  tokenIsValid = (token: IToken): boolean => {
    const expirationDate =  new Date(token.expirationDate * 1000);
    const actualDate = new Date();

    return expirationDate > actualDate;
};
