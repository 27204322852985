import { ICreateProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/CreateProfileRequest';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { IProfile } from 'app/module/profile/domain/entity/Profile';
import { DaysOfWeekDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/DayOfWeekDataMapper';
import { GenderDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/GenderDataMapper';
import { HairColorDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HairColorDataMapper';
import { HeightDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/HeightDataMapper';
import { PaymentMethodDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/PaymentMethodDataMapper';
import { ScheduleDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ScheduleDataMapper';
import { ServiceDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ServiceDataMapper';
import { SexualOrientationDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/SexualOrientationDataMapper';
import { CategoryDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/CategoryDataMapper';

export class CreateProfileDataMapper {

    public static   fromDomain(data: IProfile, auth: IAuth): ICreateProfileRequest {
        return {
            payload: {
                id: data.id,
                image: data.image,
                name: data.name,
                email: data.email ? data.email.value : null,
                username: data.userName,
                commercialConsent: data.commercialConsent,
                birthDate: data.birthDate.toString(),
                emailContact: data.canEmailContact,
                description: data.description,
                hasWhatsapp: data.hasWhatsapp,
                hasTelegram: data.hasTelegram,
                externalLink: data.externalLink,
                phoneContact: data.canPhoneContact,
                phone: data.phone.toString(),
                minPrice: data.price,
                slogan: data.slogan,
                category: CategoryDataMapper.arrayFromDomain(data.category),
                nationality: data.nationality ? data.nationality.id : null,
                services: ServiceDataMapper.arrayFromDomain(data.services),
                daysOfWeek: DaysOfWeekDataMapper.arrayFromDomain(data.availability),
                height: data.height ? HeightDataMapper.fromDomain(data.height) : null,
                telegramUsername: data.telegramUsername ? data.telegramUsername : null,
                paymentMethods: PaymentMethodDataMapper.arrayFromDomain(data.paymentMethods),
                timeOfDay: data.schedule ? ScheduleDataMapper.fromDomain(data.schedule) : null,
                gender: data.gender ? GenderDataMapper.fromDomain(data.gender.value) : null,
                hairColor: data.hairColor ? HairColorDataMapper.fromDomain(data.hairColor) : null,
                sexualOrientation: data.sexualOrientation ?
                    SexualOrientationDataMapper.fromDomain(data.sexualOrientation.value) :
                    null,
            },
            token: auth.token.value
        };
    }
}
