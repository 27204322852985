import React from 'react';
import { EMAIL_LABEL } from 'translations/tr';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { useTranslation } from 'react-i18next';
import { SexonInput } from 'app/common/presentation/component/input/SexonInput';
import { UseFormRegisterReturn } from 'react-hook-form';

interface IEmailFormFieldProps {
    infoText?: string
    disabled?: boolean
    icon?: JSX.Element
    showError?: boolean
    isRequired?: boolean
    errorMessage?: string
    register: UseFormRegisterReturn
}
export const EmailFormField = (props: IEmailFormFieldProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <SexonInput
                type="text"
                icon={props.icon}
                autocomplete="off"
                id="emailFormField"
                infoText={props.infoText}
                disabled={props.disabled}
                register={props.register}
                isRequired={props.isRequired}
                placeHolder={t(EMAIL_LABEL).toString()}
            />
            <SexonInputErrorMessage
                message={props.errorMessage || ''}
                showError={props.showError || false}
            />
        </>
    );
};
