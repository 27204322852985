import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './SexonTextArea.scss';
import { InfoIcon } from 'assets/icon/sexon/svg/icons';
import setToastInfoUserCase from 'app/common/domain/useCase/SetToastInfoUseCase';
import { SexonButton } from '../button/SexonButton';

interface ISexonTextAreaProps {
    value?: any
    onClick?: any
    infoText?: string
    className?: string
    disabled?: boolean
    maxLength?: number
    icon?: JSX.Element
    minLength?: number
    placeHolder?: string
    isRequired?: boolean
    defaultValue?: string
    autocomplete?: string
    id: string | undefined
    watchRegister?: string
    register?: UseFormRegisterReturn
}

export const SexonTextArea = (props: ISexonTextAreaProps): React.ReactElement => {
    const showInfo = (): void => {
        if(props.infoText){
            setToastInfoUserCase(props.infoText);
        }
    };
    return (
        <div className="component-textarea">
            <div
                className={
                    props.infoText ?
                        'basic-text-area-wrapper has-info-input' :
                        'basic-text-area-wrapper'
                }
                onClick={props.onClick}
            >
                {props.icon ? (
                    <label
                        className={
                            props.isRequired ?
                                'basic-text-area-icon-container is-required' :
                                'basic-text-area-icon-container'
                        }
                        htmlFor={props.id}
                    >
                        {props.icon}
                    </label>
                ) : null}

                <textarea
                    id={props.id}
                    {...props.register}
                    value={props.value}
                    onClick={props.onClick}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    placeholder={props.placeHolder}
                    defaultValue={props.defaultValue}
                    autoComplete={props.autocomplete}
                    className="custom-text-area-basic"
                />

                {props.infoText ? (
                    <SexonButton
                        type='button'
                        onClick={showInfo}
                        title={<InfoIcon />}
                        className="textarea-info-button"
                    />
                ) : null}
            </div>
            {props.maxLength ? (
                <span className="basic-text-area-characters-container">
                    {`${props.watchRegister ? props.watchRegister.length : 0} / ${props.maxLength}`}
                </span>
            ) : null}
        </div>
    );
};
