import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { ELanguage } from 'app/common/domain/valueObject/Language';
import setLanguageUserCase from 'app/common/domain/useCase/SetLanguageUseCase';
import getLanguageUseCase from 'app/common/domain/useCase/GetLanguageUseCase';
import { CookiesStorageRepository } from 'app/common/data/repository/storage/CookiesStorageRepository';
import translationsEn from './languages/translations.en.json';
import translationsEs from './languages/translations.es.json';

const storageRepository = new CookiesStorageRepository();
let language: string;
const actualLanguage = await getLanguageUseCase({
    storageRepository
}) || '';
const availableLanguages = [ELanguage.SPANISH, ELanguage.ENGLISH];

const actualLanguageIsValid = (): boolean => {
    return actualLanguage.value !== null && !!actualLanguage &&
        availableLanguages.includes(actualLanguage.value);
};

const setDefaultLanguage = (): string => {
    if (actualLanguageIsValid()) {
        language = actualLanguage.value;
    } else if (document.cookie.includes(ELanguage.SPANISH) || navigator.language.includes(ELanguage.SPANISH)) {
        language = ELanguage.SPANISH;
    } else if (document.cookie.includes(ELanguage.ENGLISH) || navigator.language.includes(ELanguage.ENGLISH)) {
        language = ELanguage.ENGLISH;
    } else {
        language = ELanguage.SPANISH;
    }
    document.cookie = navigator.language;
    setLanguageUserCase({
        storageRepository,
        lang: language as ELanguage
    } );
    return language;
};

i18next
    .use(initReactI18next)
    .init({
        lng: setDefaultLanguage(),
        fallbackLng: navigator.language,
        resources: {
            en: {
                translation: translationsEn,
            },
            es: {
                translation: translationsEs,
            },
        },
    })
    .then((): null => null);

export default i18next;
