import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

export const BasicHelmet = (): ReactElement => {
    return (
        <Helmet>
            <meta property="og:title" content="Sexon: Plataforma para Creadores de Contenido Erótico - Sexon.love"/>
            {/* eslint-disable-next-line max-len */}
            <meta name="description" content="Sexon, plataforma donde los creadores de contenido erótico pueden promocionar sus servicios de forma autónoma, sin intermediarios."/>
            {/* eslint-disable-next-line max-len */}
            <meta property="og:description" content="Sexon, plataforma donde los creadores de contenido erótico pueden promocionar sus servicios de forma autónoma, sin intermediarios."/>
        </Helmet>
    );
};
